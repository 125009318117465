import type { YachtLimited } from '../../../domain';
import { styled, css } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useMemo } from 'react';
import { Intersector } from '../../components';
import { YachtCard } from '../../yacht';
import { useYachtSearch } from '../yacht-search-context';
import { NoResultsMessage } from './NoResultsMessage';


type YachtSearchResultsBatchProps = {
	batch: YachtLimited[];
};
const YachtSearchResultsBatch = ({ batch: yachts }: YachtSearchResultsBatchProps) => useMemo(() => (
	<>
		{ yachts.map(yacht => <YachtCard yacht={yacht} key={yacht.id} preservePlaceSearchParam/>)}
	</>
), [ yachts ]);


const YachtSearchResultsRoot = styled('div')(({ theme }) => css`
	display: flex;
	flex-direction: column;
	height: 100%;

	.results-grid {
		display: grid;
		grid-template-columns: 1fr;
		width: 100%;
		gap: var(--page-pad-y);

		${theme.breakpoints.up('sm')} {
			grid-template-columns: repeat(2, 1fr);
		}
		${theme.breakpoints.up('md')} {
			grid-template-columns: repeat(3, 1fr);
		}
		${theme.breakpoints.up('lg')} {
			grid-template-columns: repeat(3, 1fr);
		}

		${theme.breakpoints.up('xl')} {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.loading-indicator {
		margin: auto;
		padding: 50px  0;
	}
`);


export const YachtSearchResults = () => {
	const { results, loading, fetchMore } = useYachtSearch();

	const resultsComponents = useMemo(() => (
		results.map((batch) => (
			<YachtSearchResultsBatch batch={batch} key={batch.map(y => y.id).join('-')} />
		))
	), [results]);

	if(!loading && !results.length) {
		return <NoResultsMessage/>;
	}

	return (
		<YachtSearchResultsRoot>
			<div className="results-grid">
				{ resultsComponents }
			</div>
			{ loading && (
				<div className="loading-indicator">
					<CircularProgress size={64}/>
				</div>
			)}
			<Intersector onIntersectionBegin={fetchMore}/>
		</YachtSearchResultsRoot>
	);
};
