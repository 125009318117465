import type { Ref } from 'react';
import { useRef, useState, useEffect } from 'react';
import { PAGE_ID } from '.';


export type StickyStateHook<T extends HTMLElement> = [ ref: Ref<T>, stuck: boolean ];
export const useStickyState = <T extends HTMLElement>(enable: boolean): StickyStateHook<T> => {
	const el = useRef<T>(null);
	const [stuck, setStuck] = useState(false);

	useEffect(() => {
		if(!enable || !el.current) {
			return;
		}

		const stickyElement = el.current;
		const handleScroll = () => {
			const top = stickyElement.getBoundingClientRect().y;
			setStuck(top < 1);
		};

		document.getElementById(PAGE_ID)?.addEventListener('scroll', handleScroll);
		return () => document.getElementById(PAGE_ID)?.removeEventListener('scroll', handleScroll);
	}, [el, enable]);

	return [ el, stuck ];
};
