import type { FAQPageJsonLdProps } from 'next-seo';
import type { FAQSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import { css, styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { FAQPageJsonLd } from 'next-seo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { cleanId } from '../util';
import { SliceTitle } from './components';
import { useMarkdown } from './use-markdown';


const FaqQuestion = styled(Typography)`font-size: 1.2rem !important;`;

const Slice_FaqRoot = styled('section')(({ theme }) => css`
	.faq + .faq {
		margin-top: ${theme.spacing(2)};
	}
`);

type FaqAccordionProps = {
	question: string;
	answer: string;
	index: number;
};
const FaqAccordion = ({ question, answer, index }: FaqAccordionProps) => {
	const content = useMarkdown(answer);

	const id = useMemo(() => (
		cleanId(question.toLowerCase().split(' ').join('-'))
	), [question]);

	const [ expanded, setExpanded ] = useState(index === 0);
	const handleChange = useCallback((ev: unknown, newExpanded: boolean) => {
		setExpanded(newExpanded);
	}, []);

	useEffect(() => {
		const handleHashChange = () => {
			const hash = window.location.hash.replace('#', '');
			if(hash === id) {
				setExpanded(true);
			}
		};

		handleHashChange();
		window.addEventListener('hashchange', handleHashChange);
		return () => { window.removeEventListener('hashchange', handleHashChange); };
	}, [id]);

	if(!question.trim().length || !answer.trim().length) {
		return null;
	}

	return (
		<div className="faq">
			<Accordion
				id={id}
				expanded={expanded}
				onChange={handleChange}
			>
				<AccordionSummary expandIcon={<ExpandIcon />}>
					<FaqQuestion variant="h3">{question}</FaqQuestion>
				</AccordionSummary>
				<AccordionDetails>
					{ content }
				</AccordionDetails>
			</Accordion>
		</div>
	);
};


export const Slice_Faq = ({ slice: { title, faqs } }: SliceProps<FAQSlice>) => {
	const fawPageJsonLdProps = useMemo<FAQPageJsonLdProps>(() => ({
		mainEntity: faqs.map(faq => ({
			questionName: faq.title,
			acceptedAnswerText: faq.markdown,
		})),
	}), [faqs]);

	return (
		<Slice_FaqRoot>
			<FAQPageJsonLd {...fawPageJsonLdProps}/>
			<SliceTitle title={title}/>
			{ faqs.map((faq, index) => (
				<FaqAccordion
					question={faq.title}
					answer={faq.markdown}
					index={index}
					key={faq.title}
				/>
			)) }
		</Slice_FaqRoot>
	);
};
