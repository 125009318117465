import type { PropsWithChildren } from 'react';
import type { Image } from '../../domain';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { getImageUrl } from '../util';
import { CardButton } from './CardButton';
import { InternalLink } from './InternalLink';


const LinkCardButton = styled(CardButton)(({ theme }) => css`
	position: relative;

	width: 100%;
	height: 0;
	padding-bottom: 80%;

	${theme.breakpoints.up('sm')} {
		padding-bottom: 60%;
	}
	
	background-position: center;
	background-size: cover;
	overflow: hidden;
	
	> * {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
	}
	
	.link-card-image {
		object-position: center;
		object-fit: cover;
		border-radius: inherit;

		will-change: transform;
		
		transform: scale(var(--card-button-focus-scaling, 1));
		transition: transform var(--card-button-focus-duration, 0);
	}
	
	.link-card-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;

		padding: var(--page-pad-y);
		border-radius: inherit;
	
		background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 5%, rgba(0,0,0,0) 40%);
	}
`);

export type LinkCardProps = {
	uri: string;
	image: string | Image;
	imageWidth?: number;
	contentClassName?: string;
};
export const LinkCard = ({ uri, image, imageWidth = 500, contentClassName, children }: PropsWithChildren<LinkCardProps>) => {
	return (
		<LinkCardButton href={uri} LinkComponent={InternalLink}>
			<img
				className="link-card-image"
				src={getImageUrl(image, imageWidth)}
				loading="lazy"
				aria-hidden
				alt=""
			/>
			<div className={clsx('link-card-content', contentClassName)}>
				{ children }
			</div>
		</LinkCardButton>
	);
};
