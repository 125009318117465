import type { ClassNameProp } from '@charterindex/armoury-www';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


export const EnquiryFormMarketingCheckbox = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'marketing', { immediate: true });
	useSubject(controller, 'submitting');

	const { t } = useTranslation();

	return (
		<FormControlLabel
			className={className}
			label={ t('common:enquiry.marketing.label') }
			control={
				<Checkbox
					checked={controller.model.marketing}
					onChange={ev => controller.model.marketing = ev.target.checked}
					disabled={ controller.submitting }
				/>
			}
		/>
	);
};
