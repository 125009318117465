import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export const useSuggestDrawerMode = () => {
	const theme = useTheme();
	const smallWidth = useMediaQuery(theme.breakpoints.down('sm'));
	const smallHeight = useMediaQuery(`@media (max-height:${theme.breakpoints.values.sm}px)`);

	return smallWidth || smallHeight;
};
