import type { ClassNameProp, Query } from '../../../util';
import SearchIcon from '@mui/icons-material/Search';
import ButtonBase from '@mui/material/ButtonBase';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { InternalLink } from '../../../components';
import { SearchFormControl } from './SearchFormControl';
import { useYachtSearchFormQuery } from './search-form-query-context';


export const SearchFormButtonRoot = styled(SearchFormControl)(({ theme }) => css`
	display: grid;
	padding: 0;
	background-color: transparent;

	--button-size: calc(var(--search-form-diameter) - 10px);

	&:hover {
		--border-color: ${theme.palette.background.paper};
	}
	
	.MuiButtonBase-root {
		background-color: ${theme.palette.primary.main};
		color: ${theme.palette.primary.contrastText};
		border-radius: var(--button-size);

		width: var(--button-size);
		min-width: var(--button-size);
		min-height: var(--button-size);
		height: var(--button-size);
		max-height: var(--button-size);

		margin-left: auto;
		margin-right: 5px;

		transition: border-color 200ms;
	}
`);

export type SearchFormButtonProps = ClassNameProp;
export const SearchFormButton = ({ className }: SearchFormButtonProps) => {
	const { t } = useTranslation();
	const { place, builder, guestsSleeping } = useYachtSearchFormQuery();

	const query = useMemo<Query>(() => ({
		place,
		builder,
		guestsSleeping,
	}), [builder, guestsSleeping, place]);

	return (
		<SearchFormButtonRoot className={className}>
			<ButtonBase
				LinkComponent={p => <InternalLink {...p} query={query}/>}
				href="/search/yachts"
				focusRipple
				title={t('common:search')}
			>
				<SearchIcon/>
			</ButtonBase>
		</SearchFormButtonRoot>
	);
};
