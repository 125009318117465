import type { Components } from '@mui/material/styles';
import { css } from '@mui/material/styles';


export const MuiBackdrop: Components['MuiBackdrop'] = {
	styleOverrides: {
		root: css`
			backdrop-filter: blur(5px);
		`,
		invisible: css`
			backdrop-filter: none;
		`,
	},
};
