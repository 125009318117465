import type { Image } from '../../domain';


const IMAGES_CDN_URL =  'https://images.charterindex.com';
const IMAGES_CDN_OPTIONS =  '.jpg?auto=format,compress&q=50&lossless=true';

export const getImageUrl = (image: string | Image, imageWidth?: number): string => {
	let imgUrl = typeof(image) === 'string' ? image : image.id;

	if(!imgUrl.startsWith('https')) {
		imgUrl = IMAGES_CDN_URL + '/' + imgUrl;
	}

	return imgUrl + IMAGES_CDN_OPTIONS + (imageWidth ? '&w=' + imageWidth : '');
};
