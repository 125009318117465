import type { ChildrenProp } from '@charterindex/armoury-www';
import { NullContextError } from '@charterindex/armoury-www/dist/jsx';
import { createContext, useContext, useState } from 'react';
import { PageVisibilityController } from './page-visibility-controller';


const pageVisibilityControllerContext = createContext<PageVisibilityController | null>(null);

export const PageVisibilityControllerProvider = ({ children }: ChildrenProp) => {
	const [ controller ] = useState(() => new PageVisibilityController());

	return (
		<pageVisibilityControllerContext.Provider value={controller}>
			{ children }
		</pageVisibilityControllerContext.Provider>
	);
};

export const usePageVisibilityController = (): PageVisibilityController => {
	const ctx = useContext(pageVisibilityControllerContext);
	if(!ctx) {
		throw new NullContextError('pageVisibilityControllerContext', 'PageVisibilityControllerProvider');
	}

	return ctx;
};
