import type { SuggestState } from './suggest-context';
import { useEffect, useRef, useState } from 'react';
import { useApi } from '../../api';


const DEBOUNCE_DURATION = 250;
const LOADING_SUGGEST_STATE: SuggestState.Loading = { kind: 'loading', loading: true };

export const useSuggestState = (suggestQuery: string, defaultSuggest: boolean): SuggestState => {
	const api = useApi();
	const [ suggestState, setSuggestState ] = useState<SuggestState>(LOADING_SUGGEST_STATE);

	const debounceTimeout = useRef<any | undefined>(); // eslint-disable-line @typescript-eslint/no-explicit-any
	const abortController = useRef<AbortController | null>(null);

	// Fetch suggestions on suggestQuery change.
	const lastDispatchedQuery = useRef<string | undefined>();
	useEffect(() => {
		const query = defaultSuggest ? '' : suggestQuery;
		if(query === lastDispatchedQuery.current) {
			return;
		}
		lastDispatchedQuery.current = query;

		clearTimeout(debounceTimeout.current);
		debounceTimeout.current = undefined;

		abortController.current?.abort();
		abortController.current = null;

		setSuggestState(LOADING_SUGGEST_STATE);

		debounceTimeout.current = setTimeout(() => {
			abortController.current = new AbortController();
			api.suggest(query, null, abortController.current)
				.then(suggest => {
					setSuggestState({
						kind: 'results',
						loading: false,
						default: false,
						results: suggest?.data?.results
							.filter(result => (
								// Omit results for the current article.
								result.type !== 'article' || result.uri !== window.location.pathname
							)) ?? [],
					});
				});
		}, DEBOUNCE_DURATION);
	}, [api, defaultSuggest, suggestQuery]);

	// Abandon in-flight requests on unmount.
	useEffect(() => () => {
		clearTimeout(debounceTimeout.current);
		abortController.current?.abort();
	}, []);

	return suggestState;
};
