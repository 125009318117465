import type { ClassNameProp } from '@charterindex/armoury-www';
import Typography from '@mui/material/Typography';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { usePreferences } from '../../../preferences';
import { useEnquiryController } from '../../enquiry-controller-context';


export const EnquiryFormEstimate = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller, 'estimate');

	const { formatCurrency } = usePreferences();
	const { t } = useTranslation();

	if(!controller.estimate) {
		return null;
	}

	return (
		<div className={className}>
			<Typography variant="body2" lineHeight={1}>
				{
					controller.estimate.nights === 1
						? t('common:count.days', { count: 1 })
						: t('common:count.nights', { count: controller.estimate.nights })
				}
			</Typography>
			<Typography variant="h2" component="span" lineHeight={1}>
				{ formatCurrency(controller.estimate.cost) }
			</Typography>
		</div>
	);
};
