import type { TextFieldProps } from '@mui/material/TextField';
import type { ChangeEventHandler } from 'react';
import type { ClassNameProp } from '../../util';
import BuilderIcon from '@mui/icons-material/Build';
import ClearIcon from '@mui/icons-material/Clear';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ButtonBase from '@mui/material/ButtonBase';
import TextField from '@mui/material/TextField';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSuggest } from '../suggest-context';
import { SuggestPopup } from './SuggestPopup';


const SuggestInputRoot = styled(TextField)(({ theme }) => css`
	.MuiSvgIcon-root {
		margin-right: ${theme.spacing(1)};
	}

	.MuiSvgIcon-root.clear-icon {
		margin-right: 0;
	}
`);

type SuggestInputMode = 'search' | 'builder' | 'place' | 'worldwide';

export type SuggestInputProps = ClassNameProp & Pick<TextFieldProps, 'autoFocus' | 'label' | 'InputLabelProps' | 'variant' | 'placeholder'> & {
	id: string;
	builder: string | undefined;
	place: string | undefined;
	noOpen?: boolean;
	clearable?: boolean;
	showWorldwide?: boolean;
};
export const SuggestInput = ({ className, builder, place, noOpen, clearable, showWorldwide, ...textFieldProps }: SuggestInputProps) => {
	const { t } = useTranslation();

	const { suggestQuery, setSuggestQuery, clearSuggestion, openSuggest, suggestOpen, selectNextResult, selectPreviousResult, confirmSelectedResult, drawerMode } = useSuggest();
	const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
		setSuggestQuery(ev.target.value);
	}, [setSuggestQuery]);

	const [ hasFocus, setHasFocus ] = useState(false);

	const mode = useMemo<SuggestInputMode>(() => {
		if(hasFocus) {
			return 'search';
		}

		if(builder) {
			return 'builder';
		}

		if(place) {
			return 'place';
		}

		if(showWorldwide && !suggestQuery.length && !textFieldProps.placeholder) {
			return 'worldwide';
		}

		return 'search';
	}, [builder, hasFocus, place, showWorldwide, suggestQuery.length, textFieldProps.placeholder]);

	const inputRef = useRef<HTMLInputElement>();
	const inputProps = useMemo<TextFieldProps['InputProps']>(() => ({
		inputRef,
		autoCapitalize: 'off',
		autoComplete: 'off',
		spellCheck: false,
		startAdornment: (() => {
			switch(mode) {
				case 'builder':
					return <BuilderIcon fontSize="medium"/>;
				case 'place':
					return <LocationOnIcon fontSize="medium"/>;
				case 'search':
					return <SearchIcon fontSize="medium"/>;
				case 'worldwide':
					return <TravelExploreIcon fontSize="medium"/>;
			}
		})(),
		endAdornment: clearable && mode !== 'worldwide' ? (
			<ButtonBase
				focusRipple
				onClick={() => {
					clearSuggestion();
					setTimeout(() => {
						inputRef.current?.focus();
					});
				}}
				title={t('common:clear')}
			>
				<ClearIcon className="clear-icon"/>
			</ButtonBase>
		) : undefined,
		onFocus: () => {
			setHasFocus(true);
			if(noOpen) {
				return;
			}

			const timeout = setTimeout(() => {
				if(drawerMode) {
					if(document.activeElement && 'blur' in document.activeElement) {
						(document.activeElement as HTMLElement).blur();
					}
				}
				openSuggest();
			});
			return () => { clearTimeout(timeout); };
		},
		onBlur: () => setHasFocus(false),
		onKeyDown: ev => {
			switch(ev.key) {
				case 'ArrowDown':
					selectNextResult();
					return;
				case 'ArrowUp':
					selectPreviousResult();
					return;
				case 'Enter':
					confirmSelectedResult();
			}
		},
	}), [clearable, mode, t, clearSuggestion, noOpen, openSuggest, drawerMode, selectNextResult, selectPreviousResult, confirmSelectedResult]);

	const value = useMemo(() => {
		switch(mode) {
			case 'builder':
				return builder;
			case 'place':
				return place;
			case 'worldwide':
				return t('common:worldwide');
			case 'search':
				return suggestQuery;
		}
	}, [builder, mode, place, suggestQuery, t]);

	const rootRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<SuggestInputRoot
				ref={rootRef}
				className={className}
				value={value}
				onChange={handleChange}
				InputProps={inputProps}
				fullWidth
				{...textFieldProps}
			/>
			<SuggestPopup
				anchorEl={rootRef.current}
				open={hasFocus && suggestOpen}
			/>
		</>
	);
};
