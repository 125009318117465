import type { SuggestionResult } from '../../../domain';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import { CharterIcon } from '@charterindex/icons';
import BuilderIcon from '@mui/icons-material/Build';
import LocationIcon from '@mui/icons-material/LocationOn';
import ArticleIcon from '@mui/icons-material/MenuBook';
import { getImageUrl } from '../../util';


export type SuggestIconProps = { result: SuggestionResult } & Omit<SvgIconProps, 'result'>;
export const SuggestIcon = ({ result, ...props }: SuggestIconProps) => {
	if(result.image) {
		return <img src={getImageUrl(result.image, 128)} alt=""/>;
	}

	switch(result.type) {
		case 'article': return <ArticleIcon {...props}/>;
		case 'builder': return <BuilderIcon {...props}/>;
		case 'location': return <LocationIcon {...props}/>;
		case 'yacht': return (
			<CharterIcon iconName={result.category.id === 'POWER' ? 'motorYacht' : 'sailingYacht'}/>
		);
	}
};
