import type { ClassNameProp } from '@charterindex/armoury-www';
import { DateRange as DateRangeIcon } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


export const EnquiryFormSeasonField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'season');
	useSubject(controller, 'submitting');

	const { t } = useTranslation();

	return (
		<TextField
			className={className}
			label={t('common:enquiry.season.label')}
			select
			value={controller.model.season}
			onChange={ev => controller.model.season = ev.target.value}
			size="small"
			InputProps={{
				startAdornment: <DateRangeIcon/>,
			}}
			disabled={controller.submitting}
		>
			{ controller.seasonOptions.map(s => (
				<MenuItem value={s.value} key={s.value}>
					{ t(s.translationKey) } {s.year}
				</MenuItem>
			))}
		</TextField>
	);
};
