import { css, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { TextOverImage } from './TextOverImage';


const AgentLinksRoot = styled('div')(({ theme }) => css`
	display: flex;
	gap: ${theme.spacing(2)};

	.agent-button {
		background: rgba(0,0,0, 0.1);
		color: white;
		border-color: white;
		
		font-size: .9rem;
		padding: ${theme.spacing(1)};
	}

	${theme.breakpoints.down('sm')} {
		display: none;
	}
`);

export const AgentLinks = () => {
	const { t } = useTranslation();

	const links = useMemo(() => [
		{
			label: t('common:agent-services'),
			href: 'https://agent.charterindex.com/',
		},
		{
			label: t('common:agent-login'),
			href: 'https://login.charterindex.com/charter',
		},
	], [ t ]);

	return (
		<AgentLinksRoot>
			{
				links.map(({ label, href }) => (
					<Button
						className="agent-button"
						variant="outlined"
						href={href}
						key={href}
					>
						<TextOverImage variant="body2">
							{ label }
						</TextOverImage>
					</Button>
				))
			}
		</AgentLinksRoot>
	);
};
