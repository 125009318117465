import type { YachtLayoutSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import PlusIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MinusIcon from '@mui/icons-material/Remove';
import { css, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import useTranslation from 'next-translate/useTranslation';
import NextImage from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import { Analytics } from '../analytics';
import { CardButton } from '../components/CardButton';
import { getImageUrl } from '../util';
import { SliceTitle } from './components';


const YachtLayoutButton = styled(CardButton)(({ theme }) => css`
	width: 100%;
	height: 0;
	padding-bottom: 60%;

	border: 1px solid ${theme.palette.grey[300]};

	border-radius: ${theme.shape.borderRadius}px;
	overflow: hidden;
	
	img {
		object-fit: contain;
	}
`);

const YachtLayoutDialog = styled(Dialog)(({ theme }) => css`
	.controls {
		position: absolute;
		right: ${theme.spacing(2)};
		bottom: ${theme.spacing(2)};

		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(1)};
	}

	.default-button {
		width: auto;
		height: auto;
		padding: 0;
		border: 0;
		background: transparent;
	}
`);

const YachtLayoutFab = styled(Fab)(({ theme }) => css`
	color: white;
	background-color: rgba(0, 0, 0, .3);
	box-shadow: none;

	&:hover {
		background-color: rgba(0, 0, 0, .5);
	}
	
	&.close {
		position: absolute;
		top: ${theme.spacing(2)};
		right: ${theme.spacing(2)};
	}
`);

type Transform = {
	scale: number;
	translation: { x: number, y: number }
};

export const Slice_YachtLayout = ({ slice: { layoutImage } }: SliceProps<YachtLayoutSlice>) => {
	const { t } = useTranslation();

	const router = useRouter();

	const [ defaultTransform, setDefaultTransform ] = useState<Transform | null>(null);
	useEffect(() => {
		if(!layoutImage) {
			return;
		}

		const scale = window.innerWidth / layoutImage.width;
		const x = Math.max(0, (window.innerWidth - layoutImage.width * scale) / 2);
		const y = Math.max(0, (window.innerHeight - layoutImage.height * scale) / 2);

		setDefaultTransform({
			translation: { x, y },
			scale,
		});
	}, [ layoutImage ]);

	const open = () => {
		Analytics.interaction({ target: 'Yacht Layout Modal', action: 'open' });

		const { query, pathname } = router;
		router.push({
			pathname,
			query,
			hash: 'plan',
		}).catch(() => { /**/ });
	};

	const close = () => {
		Analytics.interaction({ target: 'Yacht Layout Modal', action: 'close' });
		router.back();
	};

	const dialogOpen = useMemo(() => router.asPath.includes('#plan'), [router.asPath]);


	return (
		<section>
			<SliceTitle title={t('common:plan')}/>
			<YachtLayoutButton onClick={open}>
				<img alt={t('common:plan')} src={getImageUrl(layoutImage, 800)} loading="lazy"/>
			</YachtLayoutButton>
			{defaultTransform && (
				<YachtLayoutDialog
					fullScreen
					open={dialogOpen}
					onClose={close}
				>
					<MapInteractionCSS
						defaultValue={defaultTransform}
						maxScale={5}
						showControls
						controlsClass="controls"
						btnClass="default-button"
						plusBtnContents={
							<YachtLayoutFab
								aria-label={t('common:increase-size')}
								size="small"
							>
								<PlusIcon fontSize="inherit" />
							</YachtLayoutFab>
						}
						minusBtnContents={
							<YachtLayoutFab
								aria-label={t('common:decrease-size')}
								size="small"
							>
								<MinusIcon fontSize="inherit" />
							</YachtLayoutFab>
						}
					>
						<NextImage
							alt={t('common:plan')}
							src={`${layoutImage.id}.jpg`}
							width={layoutImage.width}
							height={layoutImage.height}
							layout="fixed"
						/>
					</MapInteractionCSS>
					<YachtLayoutFab
						className="close"
						onClick={close}
						aria-label={t('common:close')}
						size="small"
					>
						<CloseIcon fontSize="inherit" />
					</YachtLayoutFab>
				</YachtLayoutDialog>
			)}

		</section>
	);
};
