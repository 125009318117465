import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';


export const LifebuoyIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<g transform="translate(0 -288.53332)">
			<path d="m5.845 290.133c-2.345 0-4.245 1.901-4.245 4.247v2.153h.8v-2.153c0-1.916 1.529-3.447 3.445-3.447h2.155v-.8zm10.155 0v.8h2.153c1.916 0 3.446 1.531 3.446 3.447v2.153h.801v-2.153c0-2.346-1.901-4.247-4.247-4.247zm-14.4 14.4v2.154c0 2.346 1.9 4.245 4.245 4.245h2.155v-.8h-2.155c-1.916 0-3.445-1.529-3.445-3.445v-2.154zm20 0v2.154c0 1.916-1.53 3.445-3.446 3.445h-2.154v.8h2.153c2.346 0 4.247-1.899 4.247-4.245v-2.154z" />
			<path d="m11.6 288.533c-.879 0-1.6.721-1.6 1.6v5c0 .88.721 1.601 1.6 1.601h.8c.879 0 1.6-.721 1.6-1.601v-5c0-.879-.721-1.6-1.6-1.6zm0 .8h.8c.45 0 .8.35.8.8v5c0 .45-.35.801-.8.801h-.8c-.45 0-.8-.351-.8-.801v-5c0-.45.35-.8.8-.8z" />
			<path d="m1.692 301.922-.792.106c.663 4.923 4.494 8.824 9.403 9.576l.12-.792c-4.562-.699-8.115-4.316-8.731-8.89z" />
			<path d="m11.6 304.333c-.879 0-1.6.721-1.6 1.6v5c0 .88.721 1.601 1.6 1.601h.8c.879 0 1.6-.721 1.6-1.601v-5c0-.879-.721-1.6-1.6-1.6zm0 .8h.8c.45 0 .8.35.8.8v5c0 .45-.35.801-.8.801h-.8c-.45 0-.8-.351-.8-.801v-5c0-.45.35-.8.8-.8z" />
			<path d="m10.477 295.146c-1.821.515-3.26 1.915-3.823 3.722l.764.238c.483-1.55 1.715-2.749 3.277-3.19z" />
			<path d="m10.378 289.452c-4.949.724-8.822 4.647-9.481 9.606l.793.104c.613-4.608 4.204-8.247 8.803-8.921z" />
			<path d="m17.4 298.533c-.88 0-1.601.721-1.601 1.6v.801c0 .879.721 1.6 1.601 1.6h5c.879 0 1.6-.721 1.6-1.6v-.801c0-.879-.721-1.6-1.6-1.6zm0 .8h5c.45 0 .8.35.8.8v.801c0 .449-.35.8-.8.8h-5c-.45 0-.801-.351-.801-.8v-.801c.001-.45.351-.8.801-.8z" />
			<path d="m13.518 289.437-.108.794c4.592.628 8.211 4.213 8.882 8.798l.79-.117c-.723-4.934-4.624-8.8-9.564-9.475z" />
			<path d="m1.6 298.533c-.879 0-1.6.721-1.6 1.6v.801c0 .879.721 1.6 1.6 1.6h5c.879 0 1.6-.721 1.6-1.6v-.801c0-.879-.721-1.6-1.6-1.6zm0 .8h5c.45 0 .8.35.8.8v.801c0 .449-.35.8-.8.8h-5c-.45 0-.8-.351-.8-.8v-.801c0-.45.35-.8.8-.8z" />
			<path d="m22.3 301.969c-.635 4.558-4.185 8.152-8.734 8.846l.12.792c4.896-.746 8.724-4.622 9.406-9.526z" />
			<path d="m7.374 301.813-.771.214c.496 1.791 1.846 3.219 3.606 3.813l.256-.758c-1.509-.51-2.667-1.734-3.091-3.269z" />
			<path d="m16.592 301.927c-.475 1.565-1.713 2.78-3.287 3.225l.219.771c1.835-.519 3.28-1.938 3.834-3.763z" />
			<path d="m13.664 295.187-.237.764c1.513.472 2.695 1.657 3.161 3.172l.764-.234c-.543-1.767-1.923-3.154-3.688-3.702z" />
		</g>
	</SvgIcon>
);
