import type { ClassNameProp } from '@charterindex/armoury-www';
import { CharterIcon } from '@charterindex/icons';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


const OPTIONS = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

export const EnquiryFormGuestsField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'guests');
	useSubject(controller, 'submitting');


	const { t } = useTranslation();

	return (
		<TextField
			className={className}
			label={t('common:guests')}
			select
			value={controller.model.guests}
			onChange={ev => controller.model.guests = parseInt(ev.target.value, 10)}
			size="small"
			InputProps={{
				startAdornment: (
					<Box sx={{
						width: 24,
						height: 24,
						translate: -4,
					}}>
						<CharterIcon iconName="guests" size={24}/>
					</Box>
				) }}
			fullWidth
			disabled={controller.submitting}
		>
			{ OPTIONS.map(opt => (
				<MenuItem value={opt} key={opt}>
					{ opt === 10 ? '10+' : opt }
				</MenuItem>
			))}
		</TextField>

	);
};
