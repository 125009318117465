import type { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo } from 'react';
import { asCurrency, currencies } from '../../../domain';
import { VisuallyHiddenLabel } from '../../components';
import { usePreferences } from '../preferences-context';
import { PreferenceSelect } from './PreferenceSelect';


export type CurrencySelectProps = { id: string };
export const CurrencySelect = ({ id }: CurrencySelectProps) => {
	const { t } = useTranslation();
	const { currency, setCurrency } = usePreferences();

	const handleChange = useCallback((ev: SelectChangeEvent<unknown>) => {
		setCurrency(asCurrency(ev.target.value));
	}, [setCurrency]);

	const inputProps = useMemo(() => ({ id }), [id]);

	return (
		<>
			<VisuallyHiddenLabel htmlFor={id} label={t('common:currency')}/>
			<PreferenceSelect
				value={currency}
				onChange={handleChange}
				inputProps={inputProps}
			>
				{ currencies.map(currency => (
					<MenuItem key={currency} value={currency}>
						{ t(`common:${currency.toLowerCase()}`) }
					</MenuItem>
				))}
			</PreferenceSelect>
		</>
	);
};
