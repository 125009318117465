import type { SelectChangeEvent } from '@mui/material/Select';
import type { Theme } from '@mui/material/styles';
import type { Language } from '../../../domain';
import MenuItem from '@mui/material/MenuItem';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { asLanguage, languages } from '../../../domain';
import { Analytics } from '../../analytics';
import { VisuallyHiddenLabel } from '../../components';
import { LANGUAGE_FLAG_SVGS } from '../../static';
import { PreferenceSelect } from './PreferenceSelect';


const flagStyles = (theme: Theme) => css`
	display: flex;
	gap: ${theme.spacing(1)};

	.flag {
		width: ${theme.spacing(2)};
		height: ${theme.spacing(2)};

		border: 1px solid black;
		border-radius: 50%;

		background-position: center;
		background-size: ${theme.spacing(4, 2)};

		${languages.map(lang => css`
			&--${lang} {
				background-image: url('${LANGUAGE_FLAG_SVGS[lang]}');
			}
		`)}
	}
`;

const LanguageSelectSelect = styled(PreferenceSelect)(({ theme }) => css`
	${flagStyles(theme)};
	.flag {
		border-color: white;
	}
`);

const LanguageMenuItem = styled(MenuItem)(({ theme }) => css`
	${flagStyles(theme)}
`);

const LANGUAGE_LABELS: Readonly<Record<Language, string>> = {
	'de': 'common:german',
	'en': 'common:english-us',
	'en-GB': 'common:english-gb',
	'es': 'common:spanish',
	'fr': 'common:french',
	'it': 'common:italian',
	'ja': 'common:japanese',
	'pt': 'common:portuguese',
	'ru': 'common:russian',
	'zh': 'common:chinese-simplified',
};



export type LanguageSelectProps = {
	id: string;
	alternativeUris: Partial<Record<Language, string>>;
};
export const LanguageSelect = ({ id, alternativeUris = {} }: LanguageSelectProps) => {
	const { t, lang } = useTranslation();
	const router = useRouter();

	const handleChange = useCallback((ev: SelectChangeEvent<unknown>) => {
		const language = asLanguage(ev.target.value);

		if(language === lang) {
			return;
		}

		Analytics.changePreference({ preference: 'language', value: language });

		router.push({
			pathname: alternativeUris[language],
			query: router.query,
			hash: window.location.hash,
		}, undefined, { locale: language })
			.catch(() => { /**/ });
	}, [lang, router, alternativeUris]);

	const inputProps = useMemo(() => ({ id }), [id]);

	return (
		<>
			<VisuallyHiddenLabel htmlFor={id} label={t('common:language')}/>
			<LanguageSelectSelect
				value={lang}
				onChange={handleChange}
				inputProps={inputProps}
			>
				{languages.map(language => (
					<LanguageMenuItem value={language} lang={language} key={language}>
						<div className={`flag flag--${language}`} />
						{ t(LANGUAGE_LABELS[language]) }
					</LanguageMenuItem>
				))}
			</LanguageSelectSelect>
		</>
	);
};
