import type { SliderProps } from '@mui/material/Slider';
import type { ReactElement } from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';


export type CISliderValue = number | [ low: number, high: number ];

export type CISliderProps = {
	className?: string;
	min: number;
	max: number;
	initialValue?: CISliderValue;
	value: CISliderValue;
	onChange?: (newValue: CISliderValue) => void;
	step?: number;
	marks: number[];
	formatLabel?: (value: CISliderValue) => string;
	allowMaxToMin?: boolean;
	maxPlus?: boolean;
	color?: SliderProps['color'];
	labelId?: string;
};

const formatLabelDefault: CISliderProps['formatLabel'] = value => (
	Array.isArray(value) ? `${value[0]} - ${value[1]}` : value.toString()
);

const clampRange = (range: [ number, number ], marks: number[], allowMaxToMin: boolean): [ number, number ] => {
	if (!allowMaxToMin) {
		range[1] = Math.max(range[1], marks[1]);
	}

	return range;
};

export const CISlider = ({ className, min, max, value, onChange, step, marks, formatLabel = formatLabelDefault, allowMaxToMin, maxPlus, color = 'secondary', labelId }: CISliderProps): ReactElement => {
	/** Temporary slider state while being dragged. */
	const [transitionValue, setTransitionValue] = useState<CISliderValue | null>(null);

	const displayValue = useMemo<CISliderValue>(() => (
		transitionValue === null ? value : transitionValue
	), [transitionValue, value]);

	const [label, setLabel] = useState<string>(formatLabel(displayValue));

	const handleChange = useCallback((ev, newValue) => {
		setTransitionValue(newValue);
	}, []);

	const handleChangeCommitted = useCallback((ev, newValue) => {
		onChange?.(clampRange(newValue, marks, !!allowMaxToMin));
		setLabel(formatLabel(displayValue));
		setTransitionValue(null);
	}, [allowMaxToMin, displayValue, formatLabel, marks, onChange]);

	useEffect(() => {
		const plus = maxPlus && Array.isArray(displayValue) && displayValue[1] === max;

		let labelValue = displayValue;
		if(Array.isArray(displayValue) && displayValue[0] === displayValue[1]) {
			labelValue = displayValue[0];
		}
		setLabel(`${formatLabel(labelValue)}${plus ? '+' : ''}`);
	}, [displayValue, formatLabel, max, maxPlus]);

	const marksProp = useMemo(() => (
		marks ? marks.map(m => ({ value: m })) : undefined
	), [marks]);

	const computedClassName = useMemo(() => clsx('ci-slider', className), [ className ]);

	return (
		<div className={computedClassName}>
			<Typography variant="body1" align="center">
				{label}
			</Typography>
			<Slider
				min={min}
				max={max}
				value={transitionValue === null ? value : transitionValue}
				onChange={handleChange}
				onChangeCommitted={handleChangeCommitted}
				step={marks ? null : step}
				marks={marksProp}
				color={color}
				aria-labelledby={labelId}
			/>
		</div>
	);
};
