import type { SuggestionResult } from '../../../domain';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSuggest } from '../suggest-context';
import { SUGGEST_RESULT_SIZE } from './suggest-result-size';
import { SuggestIcon } from './SuggestIcon';



const SuggestResultRoot = styled('div')(({ theme }) => css`
	display: grid;
	grid-template-areas:
		'image description'
	;
	grid-template-columns: ${SUGGEST_RESULT_SIZE} 1fr;
	width: 100%;

	padding: ${theme.spacing(1)} var(--page-pad-x);
	${theme.breakpoints.up('md')} {
		padding: ${theme.spacing(1)};
	}
	gap: ${theme.spacing(0, 2)};

	cursor: pointer;
	
	&[aria-selected="true"] {
		background-color: ${theme.palette.grey[100]};

		.suggest-result__image {
			box-shadow: ${theme.shadows[2]};
		}
	}

	.suggest-result__image {
		grid-area: image;
		position: relative;

		display: flex;
		justify-content: center;
		align-items: center;
		height: ${SUGGEST_RESULT_SIZE};
		margin-bottom: auto;

		background-color: ${theme.palette.background.paper};
		
		border-radius: ${theme.shape.borderRadius}px;
		box-shadow: ${theme.shadows[1]};

		overflow: hidden;

		img {
			position: absolute;
			width: 100%;
			height: 100%;

			object-fit: cover;
			object-position: center;
		}
	}

	.icon--motor {
		transform: scale(1.3);
	}

	.suggest-result__description {
		grid-area: description;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		* {
			line-height: 1.3;
			font-size: 1rem;
		}
	}
`);

export type SuggestResultProps = {
	suggestion: SuggestionResult;
};

export const SuggestResult = ({ suggestion }: SuggestResultProps) => {
	const { t } = useTranslation();
	const { selectResult, selectedResult, confirmSelectedResult } = useSuggest();

	const description = useMemo(() => {
		switch(suggestion.type) {
			case 'yacht':
				return `${suggestion.yearLaunch} ${suggestion.builder.name}`;
			case 'article':
				return t('common:destination-article');
			default:
				return (
					suggestion.yachtCount === 1
						? t('common:view-number-yachts.one')
						: t('common:view-number-yachts.plural', { numberOfYachts: suggestion.yachtCount })
				);
		}
	}, [suggestion, t]);

	const handleMouseMove = useCallback(() => {
		selectResult({
			suggestion,
			source: 'hover',
		});
	}, [suggestion, selectResult]);

	const handleClick = useCallback(() => {
		confirmSelectedResult();
	}, [confirmSelectedResult]);

	const rootRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if(selectedResult.suggestion === suggestion && selectedResult.source === 'navigation') {
			rootRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [selectedResult, suggestion]);

	return (
		<SuggestResultRoot
			ref={rootRef}
			onClick={handleClick}
			onMouseMove={handleMouseMove}
			aria-selected={selectedResult.suggestion === suggestion}
		>
			<div className="suggest-result__image">
				<SuggestIcon
					result={suggestion}
					fontSize="large"
					color="secondary"
				/>
			</div>
			<div className="suggest-result__description">
				<Typography
					component="div"
					fontWeight="bolder"
					textAlign="start"
				>
					{ suggestion.name }
				</Typography>
				<Typography
					variant="body2"
					component="div"
					textAlign="start"
				>
					{ description }
				</Typography>
			</div>
		</SuggestResultRoot>
	);
};
