import { styled, css } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';


const NoResultsMessageTypography = styled(Typography)(({ theme }) => css`
	padding: ${theme.spacing(8, 8)};
`);

export const NoResultsMessage = () => {
	const { t } = useTranslation();

	return (
		<NoResultsMessageTypography variant="h3" align="center" >
			{ t('common:no-yachts-matching-your-criteria') }
		</NoResultsMessageTypography>
	);
};
