import type { HeaderLinks } from '../../../../domain';
import type { ClassNameProp } from '../../../util';
import { HeaderNavCollapsed } from './HeaderNavCollapsed';
import { HeaderNavInline } from './HeaderNavInline';


export type HeaderNavProps = ClassNameProp & {
	links: HeaderLinks
	overHero?: boolean;
};
export const HeaderNav = (props: HeaderNavProps) => {
	return (
		<>
			<HeaderNavInline {...props}/>
			<HeaderNavCollapsed {...props}/>
		</>
	);
};
