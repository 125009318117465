import type { ClassNameProp } from '@charterindex/armoury-www';
import type { ForwardedRef } from 'react';
import { CharterIcon } from '@charterindex/icons';
import Button from '@mui/material/Button';
import useTranslation from 'next-translate/useTranslation';
import { forwardRef } from 'react';
import { InternalLink } from '../../../components';
import { EnquireButton } from '../../../enquiry';
import { useYacht } from '../../yacht-context';


export const YachtEnquiryButton = forwardRef<HTMLAnchorElement, ClassNameProp>(({ className }, ref) => {
	const yacht = useYacht();
	const { t } = useTranslation();

	if(yacht.active) {
		return <EnquireButton className={className} ref={ref}/>;
	}

	return (
		<Button
			className={className}
			ref={ref as ForwardedRef<HTMLButtonElement>}
			color="primary"
			variant="contained"
			size="large"
			startIcon={<CharterIcon iconName="alternative" size={24}/>}
			href="/search/yachts"
			LinkComponent={InternalLink}
		>
			{t('common:alternative-yachts')}
		</Button>
	);
});
