import type { FC } from 'react';
import type { SuggestionResult } from '../../../domain';
import type { SelectedSuggestionResult, SuggestConfirmListener } from './use-suggestion-select';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useYachtSearchFilter } from '../../search';
import { SuggestDrawer } from '../components';
import { useSuggestDrawerMode } from './use-suggest-drawer-mode';
import { useSuggestOpen } from './use-suggest-open';
import { useSuggestState } from './use-suggest-state';
import { useSuggestionSelection } from './use-suggestion-select';


export namespace SuggestState {
	export type Loading = {
		kind: 'loading';
		loading: true;
	};
	export type Default = {
		kind: 'default';
		loading: false;
		default: true;
		popularDestinations: SuggestionResult.Location[];
	};
	export type Results = {
		kind: 'results';
		loading: false;
		default: false;
		results: SuggestionResult[];
	};
}
export type SuggestState = SuggestState.Loading | SuggestState.Default | SuggestState.Results;

export type SelectableSuggestionResult = SuggestionResult.Builder | SuggestionResult.Location;
export type SuggestSelectListener = (suggestion: SelectableSuggestionResult) => void;

export type SuggestContext = {
	suggestQuery: string;
	setSuggestQuery: (newQuery: string) => void;
	clearSuggestion: () => void;
	suggestState: SuggestState;
	selectResult: (selection: SelectedSuggestionResult) => void;
	selectedResult: SelectedSuggestionResult;
	selectNextResult: () => void;
	selectPreviousResult: () => void;
	confirmSelectedResult: () => void;
	onConfirmResult: (listener: SuggestConfirmListener) => () => void;
	openSuggest: () => void;
	closeSuggest: () => void;
	suggestOpen: boolean;
	defaultSuggest: boolean;
	drawerMode: boolean;
};

const suggestContext = createContext<SuggestContext | null>(null);

export type SuggestProviderProps = {
	alwaysUpdateFilter?: boolean;
};
export const SuggestProvider: FC<SuggestProviderProps> = ({ alwaysUpdateFilter, children }) => {
	const [ suggestQuery, setSuggestQuery ] = useState('');
	const initialQuery = useRef<string | undefined>(undefined);
	const { filter: { builder, place } } = useYachtSearchFilter();

	const defaultSuggest = (
		suggestQuery === ''
		|| suggestQuery === builder
		|| suggestQuery === place
	);


	useEffect(() => {
		if(builder || place) {
			const q = builder ?? place ?? '';
			setSuggestQuery(q);
			initialQuery.current ??= q;
		}
	}, [builder, place]);

	const suggestState = useSuggestState(suggestQuery, defaultSuggest);
	const suggestOpen = useSuggestOpen();
	const suggestionSelect = useSuggestionSelection(suggestState, alwaysUpdateFilter, suggestOpen.closeSuggest);

	const clearSuggestion = useCallback<SuggestContext['clearSuggestion']>(() => {
		setSuggestQuery('');
	}, []);

	const drawerMode = useSuggestDrawerMode();

	const value = useMemo<SuggestContext>(() => ({
		suggestQuery, setSuggestQuery,
		clearSuggestion,
		suggestState,
		...suggestionSelect,
		...suggestOpen,
		defaultSuggest,
		drawerMode,
	}), [clearSuggestion, drawerMode, defaultSuggest, suggestOpen, suggestQuery, suggestState, suggestionSelect]);

	return (
		<suggestContext.Provider value={value}>
			{ children }
			<SuggestDrawer open={drawerMode && suggestOpen.suggestOpen} onClose={suggestOpen.closeSuggest}/>
		</suggestContext.Provider>
	);
};


export const useSuggest = (): SuggestContext => {
	const ctx = useContext(suggestContext);
	if(!ctx) {
		throw new Error('suggestContext was null; missing <SuggestProvider>?');
	}

	return ctx;
};
