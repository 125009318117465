import type { SearchFormFilterProps } from './SearchFormControl';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { PAGE_ID } from '../../../util';
import { DateRangePickerFilter } from '../filters/DateRangeFilter';
import { SearchFormControl } from './SearchFormControl';


export const SearchFormDates = ({ id, className }: SearchFormFilterProps) => {
	const [ open, setOpen ] = useState(false);

	const openPicker = useCallback(() => setOpen(true), []);
	const closePicker = useCallback(() => setOpen(false), []);

	// Close picker on scroll.
	useEffect(() => {
		const scrollRoot = document.getElementById(PAGE_ID) ?? window;

		const handleScroll = () => {
			closePicker();
		};

		scrollRoot.addEventListener('scroll', handleScroll, { passive: true });
		return () => { scrollRoot.removeEventListener('scroll', handleScroll); };
	}, [closePicker]);

	return (
		<SearchFormControl
			className={clsx(className, { focus: open })}
			onClick={openPicker}
		>
			<DateRangePickerFilter
				id={id + 'dateFilter'}
				variant="standard"
				open={open}
				onOpen={openPicker}
				onClose={closePicker}
			/>
		</SearchFormControl>
	);
};
