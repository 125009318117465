import type { CharterIconName } from '@charterindex/icons';
import type { Enumeration, YachtFeaturesSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import { CharterIcon } from '@charterindex/icons';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { SliceTitle } from './components';


const YachtFeatureSectionRoot = styled('section')(({ theme }) => css`
max-width: ${theme.contentWidth};
	ul {
		display: grid;
		margin: 0;
		padding: 0;
		grid-template-columns: repeat(var(--columns, 1), 1fr);
		gap: ${theme.spacing(2, 1)};
		padding: ${theme.spacing(0, 2)};

		${theme.breakpoints.up(450)} {
			--columns: 2;
		}

		${theme.breakpoints.up('sm')} {
			--columns: 3;
			padding: 0;
		}

		${theme.breakpoints.up('md')} {
			--columns: 2;
		}

		${theme.breakpoints.up('lg')} {
			--columns: 3;
		}
	}

	li {
		display: flex;
		align-items: center;

		line-height: 1.1;

		> * + * {
			margin-inline-start: ${theme.spacing(2)};
		}
	}
`);

type YachtFeatureSectionProps = {
	titleKey: string;
	noIcons?: boolean;
	items: Enumeration[] | undefined;
};
const YachtFeatureSection = ({ titleKey, noIcons, items }: YachtFeatureSectionProps): JSX.Element | null => {
	const { t } = useTranslation();

	if(!items?.length) {
		return null;
	}

	return (
		<YachtFeatureSectionRoot>
			<SliceTitle title={t(titleKey)}></SliceTitle>
			<ul>
				{ items.map(({ id, name }) => (
					<Typography
						component="li"
						key={id}
					>
						{ !noIcons && <CharterIcon iconName={id as CharterIconName}/> }
						<span>{ name }</span>
					</Typography>
				))}
			</ul>
		</YachtFeatureSectionRoot>
	);
};

export const Slice_YachtFeatures = ({ slice: { amenities, diving, tenders, toys } }: SliceProps<YachtFeaturesSlice>) => {
	return (
		<>
			<YachtFeatureSection
				titleKey="common:amenities"
				items={amenities}
			/>
			<YachtFeatureSection
				titleKey="common:toys"
				items={toys}
			/>
			<YachtFeatureSection
				titleKey="common:diving"
				items={diving}
			/>
			<YachtFeatureSection
				titleKey="common:tenders"
				noIcons
				items={tenders}
			/>
		</>
	);
};
