import type { YachtLimited, YachtSearchSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useApi } from '../api';
import { YachtCardCarousel } from '../components';
import { SliceTitle } from './components';


const Slice_YachtSearchRoot = styled('section')`
	overflow: hidden;
`;

const LIMIT = 10;
const SORT = 'popularity';

export const Slice_YachtSearch = ({ slice }: SliceProps<YachtSearchSlice>) => {
	const api = useApi();

	const [ yachts, setYachts ] = useState<YachtLimited[] | null>(null);

	useEffect(() => {
		api.search({
			filter: slice.filter,
			sort: SORT,
			page: {
				offset: 0,
				limit: LIMIT,
			},
		}).then(r => {
			setYachts(r.data.yachts);
		});
	}, [api, slice.filter]);

	if(!yachts?.length) {
		return null;
	}

	return (
		<Slice_YachtSearchRoot>
			<SliceTitle slice={slice}/>
			{ slice.subtitle && (
				<Typography variant="h3">
					{ slice.subtitle }
				</Typography>
			)}
			<div className="yachtSearchSliceCarousel">
				<YachtCardCarousel
					yachts={yachts}
					name="yachtSearch"
					numCards={{
						sm: 2,
						md: 2,
						lg: 3,
						xl: 3,
					}}
				/>
			</div>
		</Slice_YachtSearchRoot>
	);
};
