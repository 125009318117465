import { useEffect, useRef } from 'react';


export type IntersectorProps = {
	onIntersectionBegin?: () => void;
	onIntersectionEnd?: () => void;
	margin?: string;
};

export const Intersector = ({ onIntersectionBegin, onIntersectionEnd, margin = '1500px' }: IntersectorProps) => {
	const el = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if(!el.current) {
			throw new Error('Intersector element was null.');
		}

		const observedElement = el.current;

		const observer = new IntersectionObserver(
			([ entry ]) => {
				if(!entry) {
					return;
				}

				if(entry.isIntersecting) {
					onIntersectionBegin?.();
				} else {
					onIntersectionEnd?.();
				}
			}, {
				root: document,
				rootMargin: margin,
			}
		);

		observer.observe(observedElement);
		return () => { observer.unobserve(observedElement); };
	}, [margin, onIntersectionBegin, onIntersectionEnd]);

	return <div ref={el}/>;
};
