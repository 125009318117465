import type { UriData } from '../../../domain';
import { NextSeo } from 'next-seo';
import { useMemo } from 'react';
import { Yacht404Message } from '../components/Yacht404Message';
import { useUriPageSeo } from '../uri-seo';
import { YachtSearchFilterProvider } from '../../search';
import { ArticlePage } from './ArticlePage';
import { NotFoundErrorPage } from './ErrorPage';
import { YachtPage } from './YachtPage';
import { YachtSearchPage } from './YachtSearchPage';


export type UriPageProps = {
	page: UriData | null;
};

/**
 * Page for an Article or Yacht with data fetched from Broadside-API's
 * `/uri` endpoint.
 */
export const UriPage = ({ page }: UriPageProps) => {
	const seo = useUriPageSeo(page);

	const component = useMemo(() => {
		if(!page) {
			return <NotFoundErrorPage/>;
		}

		if(!page.data) {
			switch(page.type) {
				case 'article':
					return <NotFoundErrorPage/>;
				case 'yacht':
					return (
						<YachtSearchPage extraTopContent={<Yacht404Message/>} />
					);
			}
		}

		switch(page.type) {
			case 'article':
				return <ArticlePage article={page.data} headerLinks={page.headerLinks} footerLinks={page.footerLinks}/>;
			case 'yacht':
				return <YachtPage yacht={page.data} headerLinks={page.headerLinks} footerLinks={page.footerLinks}/>;
		}
	}, [ page ]);

	return (
		<YachtSearchFilterProvider>
			<NextSeo {...seo}/>
			{component}
		</YachtSearchFilterProvider>

	);
};
UriPage.displayName = 'UriPage';
