import Head from 'next/head';
import { useMemo } from 'react';
import { omitNullish } from '../../util';


export type JsonLdProps = { value: Record<string, unknown> | null };

export const JsonLd = ({ value }: JsonLdProps) => {
	const innerHtml = useMemo(() => {
		if(!value) {
			return null;
		}

		value['@context'] = 'https://schema.org/';

		return { __html: JSON.stringify(omitNullish(value)) };
	}, [ value ]);

	if(!innerHtml) {
		return null;
	}

	return (
		<Head>
			<script type="application/ld+json" dangerouslySetInnerHTML={innerHtml}/>
		</Head>
	);
};
