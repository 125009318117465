import type { MouseEventHandler } from 'react';
import type { ClassNameProp } from '../../../util';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export type YachtSearchHeaderButtonProps = ClassNameProp & {
	icon: JSX.Element;
	text: string;
	href?: string;
	onClick?: MouseEventHandler<HTMLElement>;
};

export const YachtSearchHeaderButton = ({ className, icon, text, href, onClick }: YachtSearchHeaderButtonProps) => (
	<Button
		className={className}
		color="secondary"
		startIcon={icon}
		size="large"
		href={href}
		onClick={onClick}
	>
		<Typography component="span">
			{ text }
		</Typography>
	</Button>
);
