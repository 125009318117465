import type { ReactNode } from 'react';
import type { Range } from '../../../../domain';
import type { SearchFormFilterProps } from './SearchFormControl';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { css, styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { nearestIndexInArray } from '../../../../util';
import { GUESTS_MARKS, rangeMin } from '../../yacht-search-filter-util';
import { SearchFormControl } from './SearchFormControl';
import { useYachtSearchFormQuery } from './search-form-query-context';


const GuestsInput = styled(Input)(() => css`
	&, * {
		cursor: default;
		pointer-events: none;
	}
	input {
		text-align: center;
	}
`);

const GuestsButtonRoot = styled(ButtonBase)(() => css`
	border-radius: 50%;
	&, * {
		pointer-events: all;
		cursor: pointer;
	}
	&.Mui-disabled {
		opacity: .1;
		&, * {
			cursor: default;
		}
	}
`);
type GuestsButtonProps = {
	onClick: () => void;
	icon: ReactNode;
	disabled: boolean;
	title: string;
};
const GuestsButton = ({ onClick, icon, disabled, title }: GuestsButtonProps) => (
	<GuestsButtonRoot focusRipple onClick={onClick} disabled={disabled} title={title}>
		{ icon }
	</GuestsButtonRoot>
);

const getIndex = (guestsSleeping: Range<number> | undefined) => (
	nearestIndexInArray(rangeMin(guestsSleeping) ?? 2, GUESTS_MARKS)
);

export const SearchFormGuests = ({ id, className }: SearchFormFilterProps) => {
	const { t } = useTranslation();
	// Const { filter: { guestsSleeping }, updateFilter } = useYachtSearchFilter();
	const { guestsSleeping, setGuestsSleeping } = useYachtSearchFormQuery();

	const [ index, setIndex ] = useState(0);

	const displayValue = useMemo(() => {
		let value = GUESTS_MARKS[index].toString();
		if(index === GUESTS_MARKS.length - 1) {
			value += '+';
		}
		return value;
	}, [index]);

	// Set initial value.
	useEffect(() => {
		setIndex(getIndex(guestsSleeping));
	}, [guestsSleeping]);

	useEffect(() => {
		setGuestsSleeping(index === 0 ? undefined : GUESTS_MARKS[index]);
	}, [index, setGuestsSleeping]);

	const decrement = useCallback(() => {
		setIndex(prevIndex => Math.max(prevIndex - 1, 0));
	}, []);

	const increment = useCallback(() => {
		setIndex(prevIndex => Math.min(prevIndex + 1, GUESTS_MARKS.length));
	}, []);

	const inputId = id + 'GuestsInput';

	return (
		<SearchFormControl className={className}>
			<FormControl variant="standard">
				<InputLabel htmlFor={inputId} shrink>{t('common:guests')}</InputLabel>
				<GuestsInput
					id={inputId}
					inputProps={{ tabIndex: -1 }}
					value={displayValue}
					startAdornment={(
						<GuestsButton
							onClick={decrement}
							icon={<RemoveIcon/>}
							disabled={index === 0}
							title={t('common:add-guest')}
						/>
					)}
					endAdornment={(
						<GuestsButton
							onClick={increment}
							icon={<AddIcon/>}
							disabled={index === GUESTS_MARKS.length - 1}
							title={t('common:remove-guest')}
						/>
					)}
				/>
			</FormControl>
		</SearchFormControl>
	);
};
