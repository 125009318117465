import { visuallyHidden } from '@mui/utils';


export type VisuallyHiddenLabelProps = {
	htmlFor: string;
	label: string;
};

export const VisuallyHiddenLabel = ({ htmlFor, label }: VisuallyHiddenLabelProps) => (
	<label htmlFor={htmlFor} style={visuallyHidden}>
		{label}
	</label>
);
