import type { ClassNameProp } from '../../../util';
import TuneIcon from '@mui/icons-material/Tune';
import Badge from '@mui/material/Badge';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { useYachtSearchFilter } from '../../yacht-search-filter-context';
import { YachtSearchHeaderButton } from '../header/YachtSearchHeaderButton';


const EXCLUDE_FROM_COUNT = [ 'builder', 'place' ];

const FilterBadge = styled(Badge)(() => css`
	.filter-count {
		margin-bottom: 1px;
		font-weight: bold;
	}
`);

export const YachtSearchFilterButton = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();
	const { filter } = useYachtSearchFilter();

	const filterCount = useMemo(() => (
		Object.entries(filter).reduce((count, [ key, value ]) => {
			if(!value || EXCLUDE_FROM_COUNT.includes(key)) {
				return count;
			}
			return count + 1;
		}, 0)
	), [filter]);

	return (
		<YachtSearchHeaderButton
			className={className}
			icon={(
				<FilterBadge
					badgeContent={filterCount ? <div className="filter-count">{filterCount}</div> : 0}
					color="secondary"
				>
					<TuneIcon/>
				</FilterBadge>
			)}
			text={t('common:filters')}
			href="#filter"
		/>
	);
};
