import type { TypographyProps } from '@mui/material/Typography';
import type { Yacht, YachtLimited } from '../../../domain';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';


const YachtPremiumListingRoot = styled(Typography)(({ theme }) => css`
	padding: ${theme.spacing(0, 1)};
	border-radius: ${theme.spacing(.75)};

	background: ${theme.palette.secondary.main};
	color: ${theme.palette.secondary.contrastText};
	box-shadow: ${theme.shadows[1]};

	font-weight: 900 !important;
	text-transform: uppercase;
	text-shadow: none;
`);
export type YachtPremiumListingProps = Omit<TypographyProps, 'children'> & { yacht: YachtLimited | Yacht };
export const YachtPremiumListing = ({ yacht, ...props }: YachtPremiumListingProps) => {
	const { t } = useTranslation();

	if(!yacht.featured) {
		return null;
	}

	return (
		<YachtPremiumListingRoot variant="caption" {...props}>
			{ t('common:premium-listing') }
		</YachtPremiumListingRoot>
	);
};
