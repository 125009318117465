import { Fragment, useMemo } from 'react';


export type PreformattedTextProps = {
	text: string;
};
/** Maintain line breaks in `text`. */
export const PreformattedText = ({ text }: PreformattedTextProps) => {
	const lines = useMemo(() => text.split('\n'), [ text ]);
	return (
		<>
			{lines.map((line, index) => (
				<Fragment key={line + index}>
					{ line }<br/>
				</Fragment>
			))}
		</>
	);
};
