import type { EnquiryFormProps } from './enquiry-form';
import { useSubject } from 'ecce-react';
import { useEnquiryController } from '../enquiry-controller-context';
import { EnquiryForm } from './enquiry-form';
import { EnquiryComplete } from './EnquiryComplete';


export type EnquiryViewProps = EnquiryFormProps & {
	completeAction?: JSX.Element;
};
export const EnquiryView = ({ completeAction, id, ...formProps }: EnquiryViewProps) => {
	const controller = useEnquiryController();
	useSubject(controller, 'result');

	return controller.result?.ok
		? <EnquiryComplete action={completeAction} id={id}/>
		: <EnquiryForm {...formProps} id={id}/>;
};
