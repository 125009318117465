import type { CrewProfileSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import { css, styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import NextImage from 'next/legacy/image';
import { PreformattedText } from '../components';
import { SliceTitle } from './components';


const Slice_CrewProfileRoot = styled('section')(({ theme }) => css`
	.crew-profile__image {
		border-radius: ${theme.shape.borderRadius}px;
	}

	.crew-profile__profiles {
		border-radius: ${theme.shape.borderRadius}px;
		border: 1px solid ${theme.palette.grey[300]};
	}
`);

const CrewAvatar = styled(Avatar)(({ theme }) => css`
	float: right;
	width: 160px;
	height: 160px;
	margin: ${theme.spacing(0, 0, 2, 6)};
	shape-outside: circle();
`);

const CrewInfo = styled('div')(() => css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`);

const CrewDetails = styled('div')(({ theme }) => css`
	padding-top: ${theme.spacing(2)};
`);

export const Slice_CrewProfile = ({ slice: { image, profiles } }: SliceProps<CrewProfileSlice>) => {
	const { t } = useTranslation();

	if(!image && !profiles) {
		return null;
	}

	return (
		<Slice_CrewProfileRoot>
			<SliceTitle title={t('common:crew')}/>
			{image && (
				<NextImage
					className="crew-profile__image"
					alt={t('common:crew')}
					src={`${image}.jpg`}
					width={1920}
					height={1080}
					objectFit="contain"
				/>
			)}
			{profiles && (
				<div className="crew-profile__profiles">
					{ profiles.map(({ name, role, description, image }, index) => (
						<Accordion
							key={name}
							defaultExpanded={index === 0}
						>
							<AccordionSummary expandIcon={<ExpandIcon />}>
								<CrewInfo>
									<Typography variant="body1">{name}</Typography>
									<Typography variant="caption" lang="en">{role}</Typography>
								</CrewInfo>
							</AccordionSummary>
							<AccordionDetails>
								<CrewDetails>
									{image && (
										<CrewAvatar>
											<NextImage
												alt={name}
												src={`${image}.jpg`}
												layout="fill"
												objectFit="cover"
											/>
										</CrewAvatar>
									)}
									<Typography
										variant="body2"
										lang="en"
									>
										<PreformattedText text={description}/>
									</Typography>
								</CrewDetails>
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			)}
		</Slice_CrewProfileRoot>
	);
};
