import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';


const Yacht404MessageRoot = styled('div')(({ theme }) => css`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${theme.spacing(4)};

	span {
		font-weight: 500;
	}
`);

export const Yacht404Message = () => {
	const { t } = useTranslation();

	return (
		<Yacht404MessageRoot>
			<Typography variant="h2">
				{ t`common:error.yacht-not-found.title` }
			</Typography>
			<Typography component="span" variant="h3">
				{ t`common:error.yacht-not-found.description` }
			</Typography>
		</Yacht404MessageRoot>
	);
};
