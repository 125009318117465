import type { ClientYachtSearchFilter } from './yacht-search-filter-util';
import type { ChildrenProp } from '@charterindex/armoury-www';
import deepEqual from 'deep-equal';
import StaticRouter from 'next/router';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Analytics } from '../analytics';
import { Query } from '../util';
import { filterToQuery, queryToFilter } from './yacht-search-filter-url-query';


export type YachtSearchFilterContext = {
	filter: ClientYachtSearchFilter;
	updateFilter: (filter: Partial<ClientYachtSearchFilter>) => void;
	resetFilter: () => void;
};

const yachtSearchFilterContext = createContext<YachtSearchFilterContext | null>(null);

export type YachtSearchFilterProviderProps = ChildrenProp & {
	/**
	 * Initial value of the URL search params.
	 */
	initialSearch?: string | null | undefined;
};
export const YachtSearchFilterProvider = ({ initialSearch, children }: YachtSearchFilterProviderProps): JSX.Element => {
	const [ filter, setFilter ] = useState<ClientYachtSearchFilter>(queryToFilter(initialSearch));

	const updateFilter = useCallback<YachtSearchFilterContext['updateFilter']>(filterUpdate => {
		setFilter(previousFilter => {
			const newFilter = { ...previousFilter, ...filterUpdate };

			// Maintain reference equality for identical filters.
			if(deepEqual(previousFilter, newFilter)) {
				return previousFilter;
			}

			Object.entries(filterUpdate).forEach(([ key, value ]) => {
				Analytics.changeFilter({ filter: key as keyof ClientYachtSearchFilter | 'sort', value });
			});

			Query.shallowRoute(filterToQuery(newFilter));
			return newFilter;
		});
	}, []);

	const resetFilter = useCallback<YachtSearchFilterContext['resetFilter']>(() => {
		updateFilter({
			category: undefined,
			date: undefined,
			guestsSleeping: undefined,
			hull: undefined,
			lengthMeters: undefined,
			lowPrice: undefined,
			yearLaunch: undefined,
		});
	}, [updateFilter]);

	// Update filter to match URL when navigating to the search/yachts page.
	useEffect(() => {
		const handleRouteChange = (path: string, { shallow }: { shallow: boolean }) => {
			if(shallow) {
				return;
			}

			const url = new URL(`${window.location.protocol}/${window.location.host}${path}`);
			setFilter(queryToFilter(url.search));
		};

		StaticRouter.events.on('routeChangeStart', handleRouteChange);
		return () => StaticRouter.events.off('routeChangeStart', handleRouteChange);
	}, []);

	const value = useMemo<YachtSearchFilterContext>(() => ({
		filter,
		updateFilter,
		resetFilter,
	}), [filter, resetFilter, updateFilter]);

	return (
		<yachtSearchFilterContext.Provider value={value}>
			{ children }
		</yachtSearchFilterContext.Provider>
	);
};


export const useYachtSearchFilter = (): YachtSearchFilterContext => {
	const ctx = useContext(yachtSearchFilterContext);
	if(!ctx) {
		throw new Error('yachtSearchFilterContext was null; missing <YachtSearchFilterProvider>?');
	}

	return ctx;
};
