import type { ClassNameProp } from '@charterindex/armoury-www';
import type { MouseEventHandler } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { useEnquiryController } from '../../enquiry-controller-context';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';


export const EnquiryFormTermsMarketing = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'terms', { immediate: true });
	useSubject(controller, 'submitting');

	const [ modalOpen, setModalOpen ] = useState(false);
	const handleClick: MouseEventHandler<HTMLAnchorElement> = ev => {
		ev.preventDefault();

		if(!controller.submitting) {
			setModalOpen(true);
		}
	};

	const { t } = useTranslation();

	return (
		<>
			<FormControlLabel
				className={className}
				label={
					<>
						<Link
							color={ controller.submitting ? 'inherit' : undefined }
							underline={ controller.submitting ? 'none' : 'always'}
							onClick={handleClick}
						>
							{t('common:enquiry.terms.label')}
						</Link>
					</>
				}
				control={
					<Checkbox
						checked={controller.model.terms}
						onChange={ev => controller.model.terms = ev.target.checked}
						required
						disabled={ controller.submitting }
					/>
				}
			/>
			<TermsAndConditionsModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
};
