import type { ClassNameProp } from '../../../util';
import Paper from '@mui/material/Paper';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { SearchFormButton } from './SearchFormButton';
import { SearchFormDates } from './SearchFormDates';
import { SearchFormGuests } from './SearchFormGuests';
import { SearchFormSuggest } from './SearchFormSuggest';


const SearchFormRoot = styled('div')(({ theme }) => css`
	--search-form-radius: 33px;
	--search-form-diameter: calc(var(--search-form-radius) * 2);

	height: var(--search-form-diameter);
	max-height: var(--search-form-diameter);

	display: grid;
	
	.rounded {
		border-radius: var(--search-form-radius);
	}

	input {
		font-size: 1.05rem;
	}

	.search-form {
		display: grid;
		grid-template-areas: 'destinations button';
		grid-template-columns: 1fr var(--search-form-diameter);

		&.transparent {
			opacity: .85;

			&:focus-within {
				opacity: 1;
			}
		}
		
		&__destinations {
			grid-area: destinations;
		}
		&__dates {
			grid-area: dates;
			display: none;
		}
		&__guests {
			grid-area: guests;
			display: none;
		}
		&__button {
			grid-area: button;
		}

		${theme.breakpoints.up('sm')} {
			grid-template-areas: 'destinations dates button';
			grid-template-columns: 1fr 1fr var(--search-form-diameter);

			&__dates {
				display: flex;
			}
		}

		${theme.breakpoints.up('md')} {
			grid-template-areas: 'destinations dates guests button';
			grid-template-columns: 1fr 1.2fr .8fr var(--search-form-diameter);


			&__guests{
				display: flex;
			}
		}
	}
`);

export type SearchFormProps = ClassNameProp & {
	id: string;
	elevate?: boolean;
	transparent?: boolean;
};

export const SearchForm = forwardRef<HTMLDivElement, SearchFormProps>(({ id, className, elevate, transparent }, ref) => {
	const paperClassName = clsx('search-form rounded', { transparent });

	return (
		<SearchFormRoot className={className} ref={ref}>
			<Paper className={paperClassName} elevation={elevate ? 10 : 0}>
				<SearchFormSuggest
					id={id}
					className="search-form__destinations"
				/>
				<SearchFormDates
					id={id}
					className="search-form__dates"
				/>
				<SearchFormGuests
					id={id}
					className="search-form__guests"
				/>
				<SearchFormButton
					className="search-form__button"
				/>
			</Paper>
		</SearchFormRoot>
	);
});
