import type { FC } from 'react';
import type { HeroActionMode, Video } from '../../../domain';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import NextImage from 'next/legacy/image';
import { Children, useEffect, useMemo, useRef } from 'react';
import { InternalLink, TextOverImage } from '../../components';
import { PLACEHOLDER_SVG } from '../../static';
import { HeroAction } from './action';


const HeroRoot = styled('div')(({ theme }) => css`
 	--hero-height: 70vh;

 	${theme.breakpoints.up('sm')} {
 		--hero-height: 90vh;
 	}

	position: relative;
	width: 100%;
	height: var(--hero-height);
	z-index: -1;

	&.small {
		--hero-height: 0;
		padding-bottom: 60%;
	}
	
	transform-style: preserve-3d;

	.hero-full-size {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	
	.hero-media {
		background: url('${PLACEHOLDER_SVG}');
		background-position: center;
		background-size: cover;

		// Parallax effect
		transform: translateZ(-3px) scale(4);
		transform-origin: 0 0;

		@media(prefers-reduced-motion: reduce) {
			transform: none;
		}
		
		&__img {
			position: absolute;
			z-index: 0;
		}
		&__video {
			position: absolute;
			z-index: 1;
			height: var(--hero-height);
			width: 100%;
			object-fit: cover;
		}
	}

	.hero-content {
		z-index: 2;
		display: flex;
		flex-direction: column;

		&--default {
			> * + * {
				margin-top: ${theme.spacing(3)};
			}

			justify-content: center;
			align-items: center;

			h1 {
				max-width: 720px;
				font-size: 3rem;

				${theme.breakpoints.up('sm')} {
					font-size: 4rem;
				}
			}
		}

		&--bottom {
			justify-content: flex-start;
			align-items: flex-end;
		}
	}
	.hero-link {
		position: absolute;
		bottom: ${theme.spacing(2)};
		right: ${theme.spacing(2)};

		z-index: 2;
	}
`);

export type HeroProps = {
	title?: string;
	image?: string;
	imageObjectPosition?: string;
	video?: Video;
	link?: { text: string, uri: string };
	small?: boolean;
	contentAlign?: 'center' | 'bottom';
	action?: HeroActionMode;
	place?: string | undefined;
};

type HeroMediaProps = Pick<HeroProps, 'image' | 'imageObjectPosition' |'video'>;
const HeroMedia = ({ image, imageObjectPosition, video }: HeroMediaProps) => {
	const imageId = useMemo<string | undefined>(() => video?.image ?? image, [image, video]);

	const videoEl = useRef<HTMLVideoElement>(null);
	useEffect(() => {
		videoEl.current?.play();
	}, []);

	if(!video && !imageId) {
		return null;
	}

	return (
		<div
			className={clsx('hero-full-size hero-media', { gradient: !video })}
			data-testid={'hero-media'}
			key={`${imageId ?? 'no-image'}${video?.id ?? 'no-vid'}`}
		>
			{imageId && (
				<div className="hero-media__image">
					<NextImage
						src={`${imageId}.jpg`}
						alt=""
						layout="fill"
						objectFit="cover"
						objectPosition={imageObjectPosition}
						priority
					/>
				</div>
			)}
			{video && (
				<video
					className="hero-media__video"
					ref={videoEl}
					crossOrigin="anonymous"
					preload="auto"
					loop
					muted
					autoPlay
					playsInline
				>
					<source src={video.src} type="video/mp4" />
				</video>
			)}
		</div>
	);
};


export const Hero: FC<HeroProps> = ({ title, image, video, link, contentAlign = 'center', small, action, place: heroSearchPlace, children }) => (
	<HeroRoot className={clsx('hero', { small })}>
		<HeroMedia image={image} video={video} />
		{(title || action) && (
			<div className="hero-full-size hero-content hero-content--default">
				<TextOverImage variant="h1" fontStyle="italic" textAlign="center">
					{title}
				</TextOverImage>
				<HeroAction action={action} place={heroSearchPlace}/>
			</div>
		)}
		{link && (
			<InternalLink className="hero-link" href={link.uri}>
				<TextOverImage>
					{link.text}
				</TextOverImage>
			</InternalLink>
		)}
		{!!Children.count(children) && (
			<div className={clsx('hero-full-size hero-content', { 'hero-content--bottom': contentAlign === 'bottom' })}>
				{children}
			</div>
		)}
	</HeroRoot>
);
