import type { Range } from '../domain';


export const nearestNumber = (num: number, options: number[]): number => (
	options.reduce(
		(prev,current) => Math.abs(current - num) < Math.abs(prev - num) ? current : prev
	)
);

export const asRangeArray = <T> (range: Range<T>): [ T, T | undefined ] => (
	Array.isArray(range) ? range : [ range, undefined ]
);
