import type { HeroActionContentComponent } from './HeroAction';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Box from '@mui/material/Box';
import { EnquireButton } from '../../../enquiry';


export const HeroActionEnquire: HeroActionContentComponent = ({ className, sticky }) => (
	<Box
		className={className}
		display="flex"
		justifyContent={sticky ? 'flex-end' : 'center'}
		paddingY={sticky ? 1 : 0}
		marginRight={sticky ? 1 : 0}
	>
		<EnquireButton startIcon={<EventAvailableIcon fontSize="large" />} />
	</Box>
);
