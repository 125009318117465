export type AgentLink = {
	title: string;
	href: string;
};
export const AGENT_LINKS: AgentLink[] = [
	{
		title: 'common:agent-services',
		href: 'https://agent.charterindex.com/',
	}, {
		title: 'common:agent-login',
		href: 'https://login.charterindex.com',
	},
];
