import type { FC } from 'react';
import type { ClassNameProp } from '../util';
import Fade from '@mui/material/Fade';
import Portal from '@mui/material/Portal';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useSubject } from 'ecce-react';
import { usePageVisibilityController } from '../page/page-visibility-controller-context';


const FloatingControlRoot = styled('div')(({ theme }) => css`
	position: fixed;
	z-index: 1;

	--floating-control-offset: ${theme.spacing(1)};
	
	${theme.breakpoints.up('sm')} {
		--floating-control-offset: ${theme.spacing(2)};
	}
	
	> * > * {
		box-shadow: ${theme.shadows[4]};
	}

	&.left {
		left: var(--floating-control-offset);
	}
	&.right {
		right: var(--floating-control-offset);
	}
	&.top {
		top: var(--floating-control-offset);
	}
	&.bottom {
		bottom: var(--floating-control-offset);
	}
`);


export type FloatingControlProps = ClassNameProp & {
	x: 'left' | 'right';
	y: 'top' | 'bottom';
	hideOverFooter?: boolean;
};
export const FloatingControl: FC<FloatingControlProps> = ({ className, x, y, hideOverFooter, children }) => {
	const pageVisibility = usePageVisibilityController();
	useSubject(pageVisibility, 'contentVisible');
	useSubject(pageVisibility, 'enquiryFormVisible');
	useSubject(pageVisibility, 'footerVisible');
	const largeScreen = useMediaQuery(useTheme().breakpoints.up('sm'));


	const visible = (
		pageVisibility.contentVisible
		&& (!hideOverFooter || !pageVisibility.footerVisible)
		&& (largeScreen || !pageVisibility.enquiryFormVisible)
	);

	return (
		<Portal>
			<Fade in={ visible }>
				<div className="floating-control">
					<FloatingControlRoot className={clsx(x, y, className)}>
						{ children }
					</FloatingControlRoot>
				</div>
			</Fade>
		</Portal>
	);
};
