import Button from '@mui/material/Button';
import Portal from '@mui/material/Portal';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useCookie } from './cookie-context';
import { useCookieModal } from './cookie-modal-context';


const CookieConsentRoot = styled('div')(({ theme }) => css`
	position: fixed;
	bottom: 0;
	width: 100%;

	z-index: ${theme.zIndex.modal};
	
	display: flex;
	justify-content: center;
	gap: ${theme.spacing(1)};
	
	padding: ${theme.spacing(1, 2)};

	
	background-color: rgba(0, 0, 0, .8);
	color: white;

	.cookie-consent-content {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr;

		gap: ${theme.spacing(1, '5vw')};

		max-width: ${theme.contentWidth};

		* {
			font-size: .8rem !important;
			margin: auto 0;
		}

		&__actions {
			display: flex;
			justify-content: flex-end;

			> * + * {
				margin-left: ${theme.spacing(2)};
			}
			width: 100%;
		}

		${theme.breakpoints.up('sm')} {
			padding: theme.spacing(3, 0);
			grid-auto-flow: column;
			grid-template-columns: 1fr auto;
		}
	}

	.MuiButton-root {
		padding: ${theme.spacing(1, 2)};
	}
`);

const SLIDE_TIMEOUT = { enter: 0, exit: 250 };

export const CookieConsent = () => {
	const { t } = useTranslation();
	const { cookieConsent, setCookieConsent } = useCookie();
	const { openCookieModal } = useCookieModal();

	return (
		<>
			<Portal>
				<Slide
					in={cookieConsent === 'unchosen'}
					direction="up"
					timeout={SLIDE_TIMEOUT}
				>
					<CookieConsentRoot>
						<div className="cookie-consent-content">
							<Typography>
								{t('common:cookie.popup-text')}
							</Typography>
							<div className="cookie-consent-content__actions">
								<Button
									onClick={openCookieModal}
									variant="outlined"
									color="inherit"
								>
									<Typography>{t('common:cookie.manage')}</Typography>
								</Button>
								<Button
									onClick={() => setCookieConsent('accepted')}
									variant="contained"
									color="secondary"
								>
									<Typography>{t('common:accept')}</Typography>
								</Button>
							</div>
						</div>
					</CookieConsentRoot>
				</Slide>
			</Portal>
		</>
	);
};
CookieConsent.displayName = 'CookieConsent';
