import type { CSSProperties } from 'react';
import type { YachtLimited } from '../../domain';
import type { ClassNameProp } from '../util';
import { css, styled } from '@mui/material/styles';
import { YachtCard } from '../yacht';
import { Carousel } from './Carousel';


const YachtCardCarouselRoot = styled('div')(({ theme }) => css`
	position: relative;
	--card-spacing: calc(var(--page-pad-y) / 2);
	--num-cards: 1;
	--card-width: calc((100% / var(--num-cards)) - var(--card-spacing));
	--card-offset: 6px;

	width: 100%;
	max-width: min(100%, 100vw);
	padding-left: calc(var(--card-spacing) + var(--card-offset));
	padding-right: var(--card-offset);
	
	${theme.breakpoints.up('sm')} {
		--num-cards: var(--num-cards--sm);
	}

	${theme.breakpoints.up('md')} {
		--num-cards: var(--num-cards--md);
	}

	${theme.breakpoints.up('lg')} {
		--num-cards: var(--num-cards--lg);
	}

	${theme.breakpoints.up('xl')} {
		--num-cards: var(--num-cards--xl);
	}

	.carouselCard {
		min-width: var(--card-width);
		width: var(--card-width);

		& + * {
			margin-left: var(--card-spacing);
		}

		&:first-child {
			margin-left: calc(var(--card-offset) * -1);
		}
	}
`);

export type YachtCardCarouselProps = ClassNameProp & {
	name: string;
	yachts: readonly YachtLimited[];
	numCards: Record<'sm' | 'md' | 'lg' | 'xl', number>;
};
export const YachtCardCarousel = ({ className, name, yachts, numCards }: YachtCardCarouselProps) => (
	<YachtCardCarouselRoot
		className={className}
		style={{
			'--num-cards--sm': numCards.sm,
			'--num-cards--md': numCards.md,
			'--num-cards--lg': numCards.lg,
			'--num-cards--xl': numCards.xl,
		} as CSSProperties}
	>
		<Carousel name={name}>
			{ yachts.map(yacht => (
				<YachtCard
					className="carouselCard"
					yacht={yacht}
					lazy
					key={yacht.id}
				/>
			))}
		</Carousel>
	</YachtCardCarouselRoot>
);
