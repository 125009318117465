import { css, styled } from '@mui/material/styles';
import { YachtSearchFilterButton } from '../filters/FilterDrawerButton';
import { YachtSearchHeaderSuggest } from './YachtSearchHeaderSuggest';
import { YachtSearchOrderControl } from './YachtSearchOrderControl';


const YachtSearchHeaderRoot = styled('div')(({ theme }) => css`
	.yacht-search-header {
		display: grid;
		grid-template-areas:
			'suggest suggest suggest'
			'order   .       filters'
		;
		gap: ${theme.spacing(1)};

		grid-template-columns: auto 1fr auto;
		
		${theme.breakpoints.up('sm')} {
			grid-template-areas: 'suggest order filters';
			grid-template-columns: 1fr auto auto;
		}
		
		&__suggest {
			grid-area: suggest;
			max-width: 500px;
			margin-right: auto;
		}

		&__order {
			grid-area: order;
		}

		&__filters {
			grid-area: filters;
		}
	}
`);

export const YachtSearchHeader = () => (
	<YachtSearchHeaderRoot>
		<div className="yacht-search-header">
			<YachtSearchHeaderSuggest className="yacht-search-header__suggest" />
			<YachtSearchOrderControl className="yacht-search-header__order" />
			<YachtSearchFilterButton className="yacht-search-header__filters" />
		</div>
	</YachtSearchHeaderRoot>
);
