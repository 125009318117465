import Divider from '@mui/material/Divider';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useYacht } from '../../yacht-context';
import { YachtPremiumListing } from '../YachtPremiumListing';
import { YachtStickyHeader } from '../YachtStickyHeader';
import { YachtEnquiryButton } from './YachtEnquiryButton';
import { YachtNameAndBasicInfo } from './YachtNameAndBasicInfo';
import { YachtPrice } from './YachtPrice';
import { YachtStatIcons } from './YachtStatIcons';


const YachtHeaderRoot = styled('header')(({ theme }) => css`
	position: relative;
	display: grid;
	grid-template-areas:
		'name'
		'icons'
		'divider'
		'price'
		'enquire'
	;
	gap: ${theme.spacing(2)};

	width: 100%;

	.yacht-header__name {
		grid-area: name;
	}
	.yacht-header__icons {
		grid-area: icons;
		margin-left: auto;
		margin-top: auto;
	}
	.yacht-header__divider {
		grid-area: divider;
	}
	.yacht-header__price {
		grid-area: price;
	}
	.yacht-header__enquire {
		grid-area: enquire;
	}

	&.in-hero {
		grid-template-areas:
			'name icons icons'
			'name price enquire'
		;
		grid-template-columns: 1fr auto auto;
		grid-template-rows: 1fr auto;

		margin-top: auto;
		padding: ${theme.spacing(2, 'var(--page-pad-x)')};

		color: white;
		background: linear-gradient(90deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,.9) 100%);
		text-shadow: 0px 1px 3px rgba(0,0,0,0.8);

		.yacht-header__name {
			margin: auto 0;
		}

		.yacht-header__divider {
			display: none;
			grid-area: divider;
		}
	}
`);

const HeroPremiumListing = styled(YachtPremiumListing)`
	margin-top: auto;
	margin-right: auto;
	margin-left: var(--page-pad-x);
	margin-bottom: var(--page-pad-x);
`;

export type YachtHeaderProps = {
	className?: string;
	inHero?: boolean;
};
export const YachtHeader = ({ inHero, className }: YachtHeaderProps) => {
	const large = useMediaQuery(useTheme().breakpoints.up('sm'));
	const yacht = useYacht();

	// Toggle sticky header visibility.
	const [ sticky, setSticky ] = useState(false);
	const enquiryButtonRef = useRef<HTMLAnchorElement>(null);
	useEffect(() => {
		const enquiryButton = enquiryButtonRef.current;
		if(!enquiryButton || (!large && inHero) || (large && !inHero))	{
			return;
		}

		const observerCallback: IntersectionObserverCallback = (([ entry ]) => {
			setSticky(!entry?.isIntersecting);
		});
		const observer = new IntersectionObserver(observerCallback, {
			threshold: 1,
		});
		observer.observe(enquiryButton);

		return () => { observer.disconnect(); };
	}, [inHero, large]);


	// Only show the premium listing label in the hero on mobile.
	if(!large && inHero) {
		return <HeroPremiumListing yacht={yacht}/>;
	}

	// Only show the header below the hero on mobile.
	if(large && !inHero) {
		return null;
	}

	return (
		<>
			<YachtStickyHeader open={sticky}/>
			<YachtHeaderRoot className={clsx(className, { 'in-hero': inHero })}>
				<YachtNameAndBasicInfo className="yacht-header__name"/>
				<YachtStatIcons className="yacht-header__icons"/>
				<Divider className="yacht-header__divider"/>
				<YachtPrice className="yacht-header__price"/>
				<YachtEnquiryButton ref={enquiryButtonRef}/>
			</YachtHeaderRoot>
		</>
	);
};
