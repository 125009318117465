import type { YachtSearchQuery, YachtsData, SuggestionType, SuggestionsData, Enquiry, EnquiryResult, RelatedYachts, Article, Language, Currency } from '../../domain';
import type { StaticArticleName } from '../../util/static-article';
import type { HttpMethod } from '@charterindex/armoury-www';
import type { ApiClient } from './api-context';
import { asError } from '@charterindex/armoury-common';
import { callback, observable, subject } from 'ecce-react';
import { CURRENCY_HEADER, isAbortError, LANGUAGE_HEADER } from '../../util';


type ApiRequest = {
	method: HttpMethod;
	path: `/api/${string}`;
	query?: URLSearchParams;
	body?: Record<string, unknown>;
	abort?: AbortController;
	headers?: Record<string, string>;
};

@subject()
export class ApiService implements ApiClient {
	#language: Language;
	#currency: Currency;

	#error: Error | null = null;
	@observable() get error(): Error | null { return this.#error; }
	private set error(value: Error | null) { this.#error = value; }

	constructor(language: Language, currency: Currency) {
		this.#language = language;
		this.#currency = currency;
	}

	async #fetchApi(request: ApiRequest): ReturnType<Response['json']> {
		const init: RequestInit = {
			method: request.method,
			headers: {
				'content-type': 'application/json',
				[LANGUAGE_HEADER]: this.#language,
				[CURRENCY_HEADER]: this.#currency,
				...request.headers,
			},
			body: request.body ? JSON.stringify(request.body) : undefined,
			signal: request.abort?.signal,
		};

		const url = new URL(window.location.href);
		url.pathname = request.path;
		if(request.query) {
			url.search = request.query.toString();
		}

		try {
			const response = await fetch(url.href, init);
			if(response.status >= 500) {
				throw new Error('Server Error: ' + response.status);
			}

			return response.json();
		} catch(err) {
			if(isAbortError(err)) {
				return {};
			}

			this.error = asError(err);
			return null;
		}
	}

	@callback
	search(query: YachtSearchQuery, abort?: AbortController): Promise<YachtsData> {
		return this.#fetchApi({
			method: 'POST',
			path: '/api/search',
			body: query,
			abort,
		});
	}

	@callback
	suggest(q: string, types?: readonly SuggestionType[] | undefined | null, abort?: AbortController): Promise<SuggestionsData> {
		const query = new URLSearchParams({ q });
		if(types) {
			for(const type of types) {
				query.set('type', type);
			}
		}

		return this.#fetchApi({
			method: 'GET',
			path: '/api/suggest',
			query,
			abort,
		});
	}

	@callback
	enquire(enquiry: Enquiry): Promise<EnquiryResult> {
		return this.#fetchApi({
			method: 'POST',
			path: '/api/enquire',
			body: enquiry,
		});
	}

	@callback
	relatedYachts(uri: string): Promise<RelatedYachts[] | null> {
		return this.#fetchApi({
			method: 'GET',
			path: '/api/related-yachts',
			query: new URLSearchParams({ uri }),
		});
	}

	@callback
	staticArticle(name: StaticArticleName): Promise<Article | null> {
		return this.#fetchApi({
			method: 'GET',
			path: '/api/static-article',
			query: new URLSearchParams({ name }),
		});
	}

	setLanguage(language: Language) {
		this.#language = language;
	}

	setCurrency(currency: Currency) {
		this.#currency = currency;
	}
}
