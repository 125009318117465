import type { ClassNameProp } from '../../../util';
import useTranslation from 'next-translate/useTranslation';
import { VisuallyHiddenLabel } from '../../../components';
import { SuggestProvider } from '../../../suggest';
import { SuggestInput } from '../../../suggest/components/SuggestInput';
import { useYachtSearchFilter } from '../../yacht-search-filter-context';


const SUGGEST_ID = 'searchHeaderSuggestInput';
export const YachtSearchHeaderSuggest = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();
	const { filter: { builder, place } } = useYachtSearchFilter();

	return (
		<>
			<VisuallyHiddenLabel htmlFor={SUGGEST_ID} label={t('common:search')}/>
			<SuggestProvider alwaysUpdateFilter>
				<SuggestInput
					id={SUGGEST_ID}
					builder={builder}
					place={place}
					className={className}
					clearable
					showWorldwide
				/>
			</SuggestProvider>
		</>
	);
};
