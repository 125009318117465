import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { visuallyHidden } from '@mui/utils';
import { useYachtSearchFilter } from '../yacht-search-filter-context';


export const YachtSearchPageSeo = () => {
	const { t } = useTranslation();
	const { filter: { place, category } } = useYachtSearchFilter();

	const title = useMemo<string>(() => {
		const titleSegments: string[] = [ t('common:search-luxury-yachts-for-charter') ];

		if(category) {
			titleSegments.push(t(category === 'SAIL' ? t('common:sailing-yachts') : t('common:motor-yachts')));
		}

		if(place) {
			const commaIndex = place.indexOf(',');
			if(commaIndex !== -1) {
				titleSegments.push(place.slice(commaIndex));
			} else {
				titleSegments.push(place);
			}
		} else {
			titleSegments.push(t('common:worldwide'));
		}

		return titleSegments.join(' — ');
	}, [category, place, t]);

	return (
		<>
			<NextSeo
				title={title}
				description={t('common:search-description')}
			/>
			<h1 style={visuallyHidden}>
				{ title }
			</h1>
		</>
	);
};
