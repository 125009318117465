import type { EnquiryResult } from '../../domain';
import type { EnquiryController } from './enquiry-controller';
import { callback, observable, subject } from 'ecce-react';


@subject()
export class EnquiryErrorController {
	#invalidEmail: string | null = null;
	/** The email address which caused an `email-invalid` error. */
	@observable() get invalidEmail(): string | null { return this.#invalidEmail; }
	private set invalidEmail(value: string | null) { this.#invalidEmail = value; }

	#emailSuggestion: string | null = null;
	@observable() get emailSuggestion(): string | null { return this.#emailSuggestion; }
	private set emailSuggestion(value: string | null) { this.#emailSuggestion = value; }

	#invalidTelephone: string | null = null;
	/** The phone number which caused an `phone-invalid` error. */
	@observable() get invalidTelephone(): string | null { return this.#invalidTelephone; }
	private set invalidTelephone(value: string | null) { this.#invalidTelephone = value; }

	readonly #enquiryController: EnquiryController;

	constructor(enquiryController: EnquiryController) {
		this.#enquiryController = enquiryController;
	}

	updateResult(result: Readonly<EnquiryResult> | null) {
		if(!result || result.ok) {
			this.invalidEmail = null;
			this.emailSuggestion = null;

			return;
		}

		for(const reason of result.reasons) {
			switch(reason) {
				case 'email-invalid':
					this.invalidEmail = this.#enquiryController.model.email;
					break;

				case 'phone-invalid':
					this.invalidTelephone = this.#enquiryController.model.telephone;
					break;
			}
		}

		this.emailSuggestion = result.emailSuggestion ?? null;
	}

	@callback
	acceptEmailSuggestion(): void {
		if(this.emailSuggestion) {
			this.#enquiryController.model.email = this.emailSuggestion;
		}
	}
}
