import type { ApiClient } from '../api';
import type { Enumeration, SuggestionResult, SuggestionType } from '../../domain';
import { observable, subject } from 'ecce-react';


export type SuggestControllerConfig = {
	initialQuery?: string;
	types?: SuggestionType[];
};


@subject()
export class SuggestController {
	private static readonly DEBOUNCE_MS = 250;

	#query: string;
	@observable() get query(): string { return this.#query; }
	private set query(value: string) { this.#query = value; }

	#results: readonly SuggestionResult[] = [];
	@observable() get results(): readonly SuggestionResult[] { return this.#results; }
	private set results(value: readonly SuggestionResult[]) { this.#results = value; }

	#loading = false;
	@observable() get loading(): boolean { return this.#loading; }
	private set loading(value: boolean) { this.#loading = value; }

	#debounceTimeout: number | undefined = undefined;
	#abortController: AbortController | null = null;

	readonly types: readonly SuggestionType[];

	readonly #api: ApiClient;

	constructor(config: SuggestControllerConfig, api: ApiClient) {
		this.#api = api;
		this.#query = config.initialQuery ?? '';
		this.types = config.types ? [ ...config.types ] : [];

		this.#fetchResults();
	}

	setQuery(nextQuery: string): void {
		if(this.query === nextQuery) {
			return;
		}
		this.query = nextQuery;

		this.refetch();
	}

	refetch() {
		clearTimeout(this.#debounceTimeout);
		this.#abortController?.abort();
		this.#abortController = null;

		this.loading = true;
		this.#debounceTimeout = setTimeout(() => this.#fetchResults(), SuggestController.DEBOUNCE_MS) as unknown as number;
	}

	getByName(name: string): Enumeration | null {
		return this.results.find(r => r.name === name) ?? null;
	}

	getById(id: string | undefined | null): Enumeration | null {
		return this.results.find(r => r.id === id) ?? null;
	}

	getByAreaOrSubArea(id: string | undefined | null): Enumeration | null {
		if(!id) {
			return null;
		}

		return this.results
			.find(r => {
				if(r.type !== 'location') {
					return false;
				}

				return r.subArea === id
					|| r.area === id
					|| r.id === id;
			}) ?? null;
	}

	async #fetchResults() {
		try {
			this.loading = true;
			this.results = [];
			this.#abortController = new AbortController();
			const results = await this.#api.suggest(this.query, this.types, this.#abortController);
			this.results = results.data.results;
		} catch {
			this.results = [];
		} finally {
			this.loading = false;
			this.#abortController = null;
		}
	}
}
