import type { YachtSearchFilter } from '../../../../domain';
import type { CISliderProps, CISliderValue } from '../../../components';
import type { DimensionUnit } from '../../../preferences';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo } from 'react';
import { useYachtSearchFilter } from '../..';
import { CIButtonGroup, CISlider } from '../../../components';
import { DIMENSION_UNITS, usePreferences } from '../../../preferences';
import { nearestNumber, toFeet, toMeters } from '../../../../util';


type NumberRangeFilterProps = {
	labelId: string;
	filterKey: keyof Pick<YachtSearchFilter, 'guestsSleeping' | 'lowPrice' | 'lengthMeters'>;
	marks: CISliderProps['marks'];
	formatLabel?: CISliderProps['formatLabel'];
	getDisplayValue?: (filterValue: number) => number;
	getFilterValue?: (displayValue: number) => number;
};
const IDENTITY = (x: number) => x;

const NumberRangeFilter = ({ labelId, filterKey, marks, formatLabel, getDisplayValue = IDENTITY, getFilterValue = IDENTITY }: NumberRangeFilterProps) => {
	const { filter, updateFilter } = useYachtSearchFilter();

	const handleChange = useCallback((newValue: CISliderValue) => {
		if(!Array.isArray(newValue)) {
			return;
		}

		const [ lowValue, highValue ] = newValue;
		const lowMark = marks[0];
		const highMark = marks[marks.length - 1];

		// Do not filter if at default positions.
		if(lowValue === lowMark && highValue === highMark) {
			updateFilter({ [filterKey]: undefined });
			return;
		}

		// Don't send high value when at high mark.
		if(highValue === highMark) {
			updateFilter({ [filterKey]: getFilterValue(newValue[0]) });
			return;
		}

		updateFilter({ [filterKey]: newValue.map(getFilterValue) });
	}, [filterKey, getFilterValue, marks, updateFilter]);

	const filterValue = filter[filterKey];
	const value = useMemo<CISliderValue>(() => {
		const value: CISliderValue = [ marks[0], marks[marks.length - 1] ];
		if(filterValue) {
			if(Array.isArray(filterValue)) {
				value[0] = filterValue[0];
				value[1] = filterValue[1];
			} else {
				value[0] = filterValue;
				value[1] = marks[marks.length - 1];
			}
		}

		return value.map(getDisplayValue).map(n => nearestNumber(n, marks)) as CISliderValue;
	}, [filterValue, getDisplayValue, marks]);

	return (
		<CISlider
			value={value}
			min={marks[0]}
			max={marks[marks.length -1]}
			marks={marks}
			onChange={handleChange}
			formatLabel={formatLabel}
			maxPlus
			labelId={labelId}
			color="primary"
		/>
	);
};

type LabelIdProp = { labelId: string };

const GUESTS_MARKS = [ 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 26, 30, 32 ];
export const GuestsFilter = ({ labelId }: LabelIdProp) => <NumberRangeFilter filterKey="guestsSleeping" marks={GUESTS_MARKS} labelId={labelId}/>;

const PRICE_MARKS = [ 0, 20_000, 40_000, 60_000, 80_000, 100_000, 150_000, 200_000, 250_000, 300_000, 350_000, 400_000, 450_000, 500_000 ];
export const PriceFilter = ({ labelId }: LabelIdProp) => {
	const { formatCurrency } = usePreferences();

	const formatLabel = useCallback((value: CISliderValue) => {
		if(Array.isArray(value)) {
			return value.map(v => formatCurrency(v)).join(' - ');
		}
		return formatCurrency(value);
	}, [formatCurrency]);

	return <NumberRangeFilter filterKey="lowPrice" marks={PRICE_MARKS} formatLabel={formatLabel} labelId={labelId} />;
};

const LENGTH_MARKS: Record<DimensionUnit, number[]> = {
	'm': [0, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
	'ft': [0, 20, 40, 60, 80, 100, 125, 150, 175, 200, 225, 250, 275, 300],
};
export const LengthFilter = ({ labelId }: LabelIdProp) => {
	const { t } = useTranslation();
	const { dimensionUnit: preferredDimensionUnit, setDimensionUnit: setPreferredDimensionUnit } = usePreferences();

	const marks = useMemo(() => LENGTH_MARKS[preferredDimensionUnit], [preferredDimensionUnit]);

	const formatSliderLabel = useCallback((sliderValue: CISliderValue) => {
		const toString = (value: number) => t(`common:value.${preferredDimensionUnit}`, { value });

		if(!Array.isArray(sliderValue)) {
			return toString(sliderValue);
		}

		return sliderValue.map(toString).join(' - ');
	}, [preferredDimensionUnit, t]);

	const getOptionsLabel = useCallback((option: string) => t(`common:unit.${option}`), [t]);

	const getDisplayValue = useCallback((filterValue: number): number => {
		switch(preferredDimensionUnit) {
			case 'm':  return filterValue;
			case 'ft': return toFeet(filterValue);
		}
	}, [ preferredDimensionUnit ]);

	const getFilterValue = useCallback((displayValue: number): number => {
		switch(preferredDimensionUnit) {
			case 'm':  return displayValue;
			case 'ft': return toMeters(displayValue);
		}
	}, [ preferredDimensionUnit ]);

	return (
		<>
			<NumberRangeFilter
				filterKey="lengthMeters"
				marks={marks}
				formatLabel={formatSliderLabel}
				getDisplayValue={getDisplayValue}
				getFilterValue={getFilterValue}
				labelId={labelId}
			/>
			<CIButtonGroup
				options={DIMENSION_UNITS}
				value={preferredDimensionUnit}
				onChange={setPreferredDimensionUnit as (o: string) => void}
				getOptionLabel={getOptionsLabel}
				fullWidth
			/>
		</>
	);
};
