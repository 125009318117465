import LoopIcon from '@mui/icons-material/Loop';
import Button from '@mui/material/Button';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useYachtSearchFilter } from '../../yacht-search-filter-context';


const ResetFilterButtonRoot = styled(Button)(() => css`
	color: black;
`);

export const ResetFilterButton = () => {
	const { t } = useTranslation();
	const { resetFilter } = useYachtSearchFilter();

	return (
		<ResetFilterButtonRoot
			startIcon={<LoopIcon/>}
			onClick={resetFilter}
		>
			{ t('common:reset-all-filters')}
		</ResetFilterButtonRoot>
	);
};
