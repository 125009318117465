import type { ClassNameProp } from '../../util';
import type { LanguageSelectProps } from './LanguageSelect';
import { css, styled } from '@mui/material/styles';
import { FloatingControl } from '../../components';
import { CurrencySelect } from './CurrencySelect';
import { LanguageSelect } from './LanguageSelect';


const PreferenceControlsRoot = styled('div')(({ theme }) => css`
	display: grid;
	grid-template-columns: auto auto;
	gap: ${theme.spacing(2)};
`);
export type PreferenceControlsProps = ClassNameProp & LanguageSelectProps;
export const PreferenceControls = ({ id, className, alternativeUris }: PreferenceControlsProps) => (
	<PreferenceControlsRoot className={className}>
		<LanguageSelect id={id + 'LanguageSelect'} alternativeUris={alternativeUris}/>
		<CurrencySelect id={id + 'CurrencySelect'}/>
	</PreferenceControlsRoot>
);

const FloatingPreferenceControlsRoot = styled('div')(({ theme }) => css`
	.preference-controls {
		background-color: ${theme.palette.secondary.main};
		padding: ${theme.spacing(.5, 1.5)};
		border-radius: ${theme.spacing(3)};
	}
`);
export const FloatingPreferenceControls = ({ alternativeUris }: Pick<LanguageSelectProps, 'alternativeUris'>) => (
	<FloatingControl x="left" y="bottom" hideOverFooter>
		<FloatingPreferenceControlsRoot>
			<PreferenceControls id="floatingPreferences" className="preference-controls" alternativeUris={alternativeUris}/>
		</FloatingPreferenceControlsRoot>
	</FloatingControl>
);
