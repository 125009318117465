import type { FC } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { Analytics } from '../analytics';
import { isSsr, Query } from '../util';


export const SEARCH_ORDERS = [
	'-popularity',
	'-lowPrice',
	'lowPrice',
	'-guestsSleeping',
	'guestsSleeping',
	'-lengthMeters',
	'lengthMeters',
	'-yearLaunch',
	'yearLaunch',
] as const;

export type YachtSearchOrder = typeof SEARCH_ORDERS[number];
const DEFAULT_ORDER: YachtSearchOrder = '-popularity';
const ORDER_QUERY_PARAM = 'order';

export type YachtSearchOrderContext = {
	order: YachtSearchOrder;
	setOrder: (newOrder: YachtSearchOrder) => void;
};

const yachtSearchOrderContext = createContext<YachtSearchOrderContext | null>(null);

const fromQuery = (): YachtSearchOrder => {
	if(isSsr()) {
		return DEFAULT_ORDER;
	}

	const order = new URLSearchParams(window.location.search).get(ORDER_QUERY_PARAM);
	switch(order) {
		case '-popularity':
		case '-lowPrice':
		case 'lowPrice':
		case '-guestsSleeping':
		case 'guestsSleeping':
		case '-lengthMeters':
		case 'lengthMeters':
		case '-yearLaunch':
		case 'yearLaunch':
			return order;
		default:
			return DEFAULT_ORDER;
	}
};

export const YachtSearchOrderProvider: FC = ({ children }) => {
	const [ order, setOrder ] = useState<YachtSearchOrder>(fromQuery());

	const value = useMemo<YachtSearchOrderContext>(() => ({
		order,
		setOrder: newOrder => setOrder(prevOrder => {
			if(prevOrder === newOrder) {
				return prevOrder;
			}

			Analytics.changeFilter({ filter: 'sort', value: order });

			Query.shallowRoute({
				[ORDER_QUERY_PARAM]: newOrder === DEFAULT_ORDER ? null : newOrder,
			});

			return newOrder;
		}),
	}), [order]);

	return (
		<yachtSearchOrderContext.Provider value={value}>
			{ children }
		</yachtSearchOrderContext.Provider>
	);
};

export const useYachtSearchOrder = (): YachtSearchOrderContext => {
	const ctx = useContext(yachtSearchOrderContext);
	if(!ctx) {
		throw new Error('yachtSearchOrderContext was null; missing <YachtSearchOrderProvider>?');
	}

	return ctx;
};
