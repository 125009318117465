import type { Language, Currency } from '../../domain';
import { isNullish } from '../../util';


export const makeCurrencyFormatter = (language: Language, currency: Currency): (value: number | undefined) => string => {
	const fmt = new Intl.NumberFormat(language, {
		style: 'currency',
		currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});

	return value => isNullish(value) ? '' : fmt.format(value).replace(/[A-Z]/g, '');
};
