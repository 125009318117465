import type { SelectProps } from '@mui/material/Select';
import Select from '@mui/material/Select';
import { css, styled } from '@mui/material/styles';


const PreferenceSelectSelect = styled(Select)(({ theme }) => css`
	&, * {
		color: white;
	}

	.MuiSelect-standard {
		display: flex;
		align-items: center;
		gap: ${theme.spacing(1)};
	}

	.MuiSvgIcon-root {
		fill: white;
	}
`);

export const PreferenceSelect = (props: SelectProps) => (
	<PreferenceSelectSelect
		variant="standard"
		disableUnderline
		{...props}
	/>
);

