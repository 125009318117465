import type { ButtonProps } from '@mui/material/Button';
import { CharterIcon } from '@charterindex/icons';
import Button from '@mui/material/Button';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { forwardRef } from 'react';
import { EnquiryController } from '../enquiry-controller';
import { useEnquiryController } from '../enquiry-controller-context';


export type EnquireButtonProps = Omit<ButtonProps, 'href' | 'children'>;
export const EnquireButton = forwardRef<HTMLAnchorElement, EnquireButtonProps>((props, ref) => {
	const { labelKey } = useEnquiryController();
	const { t } = useTranslation();

	return (
		<Link href={'#' + EnquiryController.FORM_ID} passHref legacyBehavior>
			<Button
				ref={ref as any} // eslint-disable-line @typescript-eslint/no-explicit-any
				color="primary"
				variant="contained"
				size="large"
				startIcon={ <CharterIcon iconName="enquire" size={24}/> }
				{...props}
			>
				{ t(labelKey) }
			</Button>
		</Link>
	);
});
