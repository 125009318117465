import type { Currency, Enquiry, EnquiryResult, Enumeration, Language, Range } from '../../domain';
import type { ApiClient } from '../api';
import type { ClientYachtSearchFilter } from '../search/yacht-search-filter-util';
import { getUpcomingSeasons } from '@charterindex/armoury-common';
import { differenceInCalendarDays } from 'date-fns';
import { callback, makeSubject, observable, observe, subject } from 'ecce-react';
import { Analytics } from '../analytics';
import { EnquiryErrorController } from './enquiry-error-controller';


type ParseableDate<TDate> = string | number | Date | null | undefined | TDate;
type RangeInput<TDate> = [ParseableDate<TDate>, ParseableDate<TDate>];

export type SeasonOption = {
	translationKey: string;
	value: string;
	year: string;
};

export type EnquiryEstimate = {
	nights: number;
	cost: number;
};

export type EnquiryYachtDetails = {
	id: string;
	name: string;
	lowPrice?: number | null | undefined;
};

export type EnquiryControllerResult = EnquiryResult & {
	id: string;
};

export type EnquiryType = 'full' | 'lite';

type EnquiryModel = Omit<Enquiry, 'yachtId' | 'timezone' | 'startDate' | 'endDate' | 'gclid' | 'campaignCode'> & {
	dates: RangeInput<Date>;
};

export type EnquiryControllerConfig = {
	yacht?: EnquiryYachtDetails;
	campaignCode?: string;
	location?: { id: string, name: string };
	locale: Language;
	currency: Currency;
	labelKey: string;
};
export type EnquiryControllerDependencies = {
	api: ApiClient;
	filter: ClientYachtSearchFilter;
};

@subject()
export class EnquiryController {
	static readonly FORM_ID = 'ENQUIRE';

	#yacht: EnquiryYachtDetails | null;
	readonly #campaignCode: string | undefined;
	#currency: Currency;
	#locale: Language;
	readonly model: EnquiryModel;

	#submitting = false;
	@observable() get submitting(): boolean { return this.#submitting; }
	private set submitting(value: boolean) { this.#submitting = value; }

	#loadingPlaces = false;
	@observable() get loadingPlaces(): boolean { return this.#loadingPlaces; }
	private set loadingPlaces(value: boolean) { this.#loadingPlaces = value; }

	#locationOptions: string[] = [ 'Foo', 'Bar', 'Baz' ];
	@observable() get locationOptions(): string[] { return this.#locationOptions; }
	private set locationOptions(value: string[]) { this.#locationOptions = value; }

	#estimate: EnquiryEstimate | null;
	@observable() get estimate(): EnquiryEstimate | null { return this.#estimate; }
	private set estimate(value: EnquiryEstimate | null) { this.#estimate = value; }

	#result: EnquiryControllerResult | null = null;
	@observable() get result(): EnquiryControllerResult | null { return this.#result; }
	private set result(value: EnquiryControllerResult | null) {
		this.#result = value;
		this.errors.updateResult(value);
	}

	readonly seasonOptions: readonly SeasonOption[];
	readonly budgetOptions: readonly number[];

	readonly labelKey: string;

	readonly api: ApiClient;
	readonly errors: EnquiryErrorController;

	readonly fixedLocation: Enumeration | null;

	constructor(config: EnquiryControllerConfig, deps: EnquiryControllerDependencies) {
		this.#yacht = config.yacht ?? null;
		this.#campaignCode = config.campaignCode;
		this.#currency = config.currency;
		this.#locale = config.locale;
		this.seasonOptions = this.#makeSeasonOptions();
		this.budgetOptions = this.#makeBudgetOptions();

		this.model = makeSubject({
			name: '',
			country: '',
			telephone: '',
			email: '',
			dates: this.#getInitialDates(deps.filter.date),
			guests: this.#getInitialGuests(deps.filter.guestsSleeping),
			yachtType: 'Motor',
			budget: undefined,
			locationId: config.location?.id,
			area: undefined,
			message: '',
			marketing: false,
			terms: false,
			location: config.location?.id ?? deps.filter.place,
			season: this.yacht ? undefined : this.seasonOptions[0].value,
		});

		this.fixedLocation = config.location || null;

		this.#estimate = this.#makeEstimate();

		observe(this.model, 'dates', () => {
			this.estimate = this.#makeEstimate();
		});

		this.labelKey = config.labelKey;

		this.api = deps.api;
		this.errors = new EnquiryErrorController(this);
	}

	@callback
	async submit(id: string, type: EnquiryType, ev?: { preventDefault: VoidFunction }) {
		ev?.preventDefault();

		this.submitting = true;
		this.result = null;
		try {
			this.result = {
				id,
				...await this.api.enquire(this.#makeEnquiry(type)),
			};
			Analytics.enquiry(this.yacht, this.#currency);
		} finally {
			this.submitting = false;
		}
	}

	#makeEnquiry(type: EnquiryType): Readonly<Enquiry> {
		const enquiry: Enquiry = {
			yachtId: this.yacht?.id,
			name: this.model.name,
			country: this.model.country,
			telephone: this.model.telephone,
			email: this.model.email,
			terms: this.model.terms,
			location: this.model.location,
			currency: this.#currency,
			locale: this.#locale,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			marketing: this.model.marketing,
			gclid: this.#getGclid(),
			campaignCode: this.#campaignCode,
		};

		if(type === 'lite') {
			return enquiry;
		}

		return {
			...enquiry,
			startDate: this.#makeEnquiryDate(this.model.dates[0]),
			endDate: this.#makeEnquiryDate(this.model.dates[1]),
			season: this.model.season,
			guests: this.model.guests,
			yachtType: this.model.yachtType,
			budget: this.model.budget,
			message: this.model.message,
			locationId: this.model.locationId,
		};
	}

	#getGclid(): string | undefined {
		return new URLSearchParams(window.location.search).get('gclid') ?? undefined;
	}

	#makeEnquiryDate(x: ParseableDate<Date>): string | undefined {
		if(!x) {
			return undefined;
		}

		return new Date(x).toISOString().split('T')[0];
	}

	#makeSeasonOptions(): SeasonOption[] {
		return getUpcomingSeasons(3)
			.map(s => {
				const year = s.type === 'summer'
					? s.startingYear.toString()
					: `${s.startingYear}/${(s.startingYear + 1).toString().slice(2)}`;


				const value = (s.type === 'summer' ? 'Summer ' : 'Winter ') + year;

				return {
					translationKey: 'common:season.' + s.type,
					value,
					year,
				};
			});
	}

	#makeBudgetOptions(): number[] {
		return [
			5_000,
			10_000,
			20_000,
			50_000,
			100_000,
			250_000,
			500_000,
		];
	}

	#getInitialGuests(guestsFilter: Range<number> | undefined): number {
		if(!guestsFilter) {
			return 2;
		}

		return Math.max(10, Array.isArray(guestsFilter) ? guestsFilter[0] : guestsFilter);
	}

	#getInitialDates(dateFilter: Range<Date> | undefined): RangeInput<Date> {
		if(!dateFilter) {
			return [ null, null ];
		}

		return Array.isArray(dateFilter)
			? dateFilter
			: [ dateFilter, null ];
	}

	#makeEstimate(): EnquiryEstimate | null {
		if(!this.yacht?.lowPrice) {
			return null;
		}

		let nights = 7;
		const [ startDate, endDate ] = this.model.dates;
		if(startDate && endDate) {
			nights = differenceInCalendarDays(new Date(endDate), new Date(startDate));
		}

		const baseRate = nights < 7
			? this.yacht.lowPrice / 6
			: this.yacht.lowPrice / 7;
		const cost = baseRate * Math.max(nights, 1);

		return { nights, cost };
	}

	handleCurrencyChange(lowPrice: number | null | undefined, currency: Currency): void {
		if(!this.#yacht || lowPrice === this.yacht?.lowPrice) {
			return;
		}

		this.#yacht.lowPrice = lowPrice ?? null;
		this.#currency = currency;
		this.estimate = this.#makeEstimate();
	}

	handleLocalChange(locale: Language): void {
		this.#locale = locale;
	}

	get yacht(): Readonly<EnquiryYachtDetails> | null {
		return this.#yacht;
	}
}
