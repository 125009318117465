import type { ReactNode } from 'react';
import type { Article, FooterLinks, HeaderLinks } from '../../../domain';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { EnquiryControllerProvider } from '../../enquiry';
import { Slices } from '../../slices';
import { ArticleJsonLd } from '../../structured-data';
import { SuggestProvider } from '../../suggest';
import { Page } from '../components';
import { Hero } from '../components/Hero';


export type ArticlePageProps = {
	article: Article;
	headerLinks: HeaderLinks;
	footerLinks: FooterLinks;
};
export const ArticlePage = ({ article, headerLinks, footerLinks }: ArticlePageProps) => {
	const { t } = useTranslation();

	const hero = useMemo<ReactNode | null>(() => {
		if(!article.image && !article.video) {
			return null;
		}

		const link = article.featuredYacht && {
			text: t('common:luxury-charter-yacht-name', { name: article.featuredYacht.name }),
			uri: article.featuredYacht.uri,
		};

		return (
			<Hero
				image={article.image}
				video={article.video}
				title={article.title}
				action={article.heroAction}
				place={article.place}
				link={link}
				key={article.id}
			/>
		);
	}, [article, t]);

	const title = useMemo<ReactNode | null>(() => {
		return hero ? null : (
			<>
				<Typography variant="h1" align="center">
					{ article.title }
				</Typography>
			</>
		);
	}, [article.title, hero]);

	const content = useMemo<ReactNode | null>(() => (
		<Slices position="main" slices={ article.slices } />
	), [article]);

	const side = useMemo<ReactNode | null>(() => {
		if(!article.sideSlices) {
			return null;
		}

		return (
			<Slices position="side" slices={ article.sideSlices }/>
		);
	}, [article.sideSlices]);

	return (
		<EnquiryControllerProvider
			location={ (article.place && article.placeId) ? {
				id: article.placeId,
				name: article.place,
			} : undefined }
			campaignCode={article.campaignCode}
			labelKey="common:enquiry.call-to-action"
			key={article.placeId ?? ''}
		>
			<SuggestProvider key={article.id}>
				<ArticleJsonLd article={article} />
				<Page
					id={article.id}
					hero={ hero }
					head={ title }
					main={ content }
					side={ side }
					sideSticky
					tail={ article.tailSlices }
					alternativeUris={ article.uris }
					headerLinks={ headerLinks }
					footerLinks={ footerLinks }
					relatedYachts={ article.relatedYachts }
				/>
			</SuggestProvider>
		</EnquiryControllerProvider>
	);
};
