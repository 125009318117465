import type { ClassNameProp } from '../../util';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';


const EnquireForRateRoot = styled('div')`
	display: flex;
	align-items: center;
`;

export const EnquireForRate = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();

	return (
		<EnquireForRateRoot className={className}>
			<Typography variant="h5" component="span">
				{t('common:enquire-for-rate')}
			</Typography>
		</EnquireForRateRoot>
	);
};
