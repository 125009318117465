import type { ArticleLimited, ArticlesSlice } from '../../../domain';
import type { SliceProps } from '../SliceProps';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { LinkCard, TextOverImage } from '../../components';
import { useNoOrphan } from '../../util';
import { SliceTitle } from '../components/SliceTitle';


const ArticlesRoot = styled('section')(({ theme }) => css`
	max-width: ${theme.contentWidth};
	width: 100%;

	.articles {
		&--one-article {
			display: flex;
			justify-content: center;
			align-items: center;

			.articles__card-content {
				align-items: center;
			}
		}
	
		&.articles--many-articles {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: var(--page-pad-y);

			${theme.breakpoints.up('sm')} {
				grid-template-columns: repeat(2, 1fr);
			}

			.articles__title {
				font-size: 1em;
			}
			
			.articles__tagline {
				display: none;
			}
		}
	}
`);

type ArticleCardProps = {
	article: ArticleLimited;
	single: boolean;
};
const ArticleCard = ({ article, single }: ArticleCardProps) => {
	const title = useNoOrphan(article.title);
	const tagline = useNoOrphan(article.tagline);

	return (
		<LinkCard
			uri={article.uri}
			image={article.image}
			imageWidth={single ? 800 : undefined}
			contentClassName="articles__card-content"
			key={article.uri}
		>
			<TextOverImage className="articles__title" component="span" variant="h4" textAlign="center">
				{title}
			</TextOverImage>
			{ tagline && (
				<TextOverImage
					className="articles__tagline"
					component="span"
					variant="body2"
					fontStyle="italic"
					textAlign="center"
				>
					{tagline}
				</TextOverImage>
			)}
		</LinkCard>
	);
};

export const Slice_ArticlesDefault = ({ slice }: SliceProps<ArticlesSlice>) => (
	<ArticlesRoot>
		<SliceTitle slice={slice}/>
		<div className={clsx('articles', {
			'articles--one-article': slice.articles.length === 1,
			'articles--many-articles': slice.articles.length > 1,
		})}>
			{slice.articles.map(a => (
				<ArticleCard
					article={a}
					single={slice.articles.length === 1}
					key={a.uri}
				/>
			))}
		</div>
	</ArticlesRoot>
);
