import type { FormEventHandler } from 'react';
import type { EnquiryType } from '../../enquiry-controller';
import Divider from '@mui/material/Divider';
import { css, styled } from '@mui/material/styles';
import { usePageVisibilityController } from '../../../page';
import { EnquiryController } from '../../enquiry-controller';
import { useEnquiryController } from '../../enquiry-controller-context';
import { EnquiryFormBudgetField } from './EnquiryFormBudgetField';
import { EnquiryFormDatesField } from './EnquiryFormDatesField';
import { EnquiryFormEmailField } from './EnquiryFormEmailField';
import { EnquiryFormEstimate } from './EnquiryFormEstimate';
import { EnquiryFormGuestsField } from './EnquiryFormGuestsField';
import { EnquiryFormLocationField } from './EnquiryFormLocationField';
import { EnquiryFormMarketingCheckbox } from './EnquiryFormMarketingCheckbox';
import { EnquiryFormNameField } from './EnquiryFormNameField';
import { EnquiryFormPhoneField } from './EnquiryFormPhoneField';
import { EnquiryFormSeasonField } from './EnquiryFormSeasons';
import { EnquiryFormTermsMarketing } from './EnquiryFormTermsCheckbox';
import { EnquiryFormYachtTypeField } from './EnquiryFormYachtTypeField';
import { EnquiryMessageField } from './EnquiryMessageField';
import { EnquiryFormSubmitButton } from './EnquirySubmitButton';


const BP = 'md';
const GUEST_SELECT_WIDTH = '150px';

const EnquiryFormRoot = styled('form')(({ theme }) => css`
	position: relative;

	display: flex;
	flex-direction: column;
	max-width: 70ch;

	.scrollAnchor {
		position: absolute;
		top: ${theme.spacing(-2)};

		${theme.breakpoints.up('sm')} {
			top: -150px;
		}
	}

	--enquiry-form-spacing: ${theme.spacing(1.5)};

	&.wide {
		${theme.breakpoints.up(BP)} {
			--enquiry-form-spacing: ${theme.spacing(2)};

			.enquiryContact {
				grid-auto-flow: column;
			}

			.enquiryGeneralCharter {
				grid-template-columns: repeat(6, 1fr);
				grid-template-areas:
					'location  location  season    season guests guests'
					'yachtType yachtType yachtType budget budget budget'
				;
			}

			.enquiryLegal {
				.MuiFormControlLabel-label {
					font-size: .9rem;
				}
			}
		}
	}

	.enquiryContact {
		display: grid;
		grid-auto-flow: rows;
		gap: var(--enquiry-form-spacing);

		&__phone {
			.MuiButtonBase-root {
				padding: 0;
				width: 24px;
			}
		}
	}

	.enquiryGeneralCharter {
		display: grid;
		grid-template-columns: 3fr ${GUEST_SELECT_WIDTH};
		grid-template-areas:
			'location  location'
			'season    guests'
			'yachtType yachtType'
			'budget    budget'
		;
		gap: var(--enquiry-form-spacing);

		&__location {
			grid-area: location;
		}
		&__season {
			grid-area: season;
		}
		&__guests {
			grid-area: guests;
		}
		&__yachtType {
			grid-area: yachtType;
		}
		&__budget {
			grid-area: budget;
		}
	}

	.enquiryYachtCharter {
		display: grid;
		grid-template-areas:
			'dates    dates'
			'estimate guests'
		;
		grid-template-columns: 3fr ${GUEST_SELECT_WIDTH};
		gap: var(--enquiry-form-spacing);

		&__dates {
			grid-area: dates;
		}
		&__estimate {
			grid-area: estimate;
		}
		&__guests {
			grid-area: guests;
		}
	}

	.enquiryLegal {
		display: flex;
		flex-direction: column;

		margin: ${theme.spacing(-1, 0)};

		.MuiCheckbox-root {
			padding-top: ${theme.spacing(.5)};
			padding-bottom: ${theme.spacing(.5)};
		}

		.MuiFormControlLabel-label {
			line-height: 1.2;
			font-size: .8rem;
			margin-left: ${theme.spacing(-.5)};
		}
	}

	.enquiryMessage {
		margin-top: var(--enquiry-form-spacing);
	}

	.MuiSvgIcon-fontSizeMedium.enquirySendIcon {
		font-size: 25px;
	}

	.MuiInputBase-adornedStart {
		> svg {
			margin-right: ${theme.spacing(1)};
		}
	}

	.MuiDivider-root {
		margin: ${theme.spacing(1, 0)};
		${theme.breakpoints.up(BP)} {
			margin: ${theme.spacing(2, 0)};
		}
	}

	.MuiAutocomplete-root .MuiSvgIcon-root {
		margin-left: ${theme.spacing(.75)};
		margin-right: ${theme.spacing(.5)};
	}
`);


export type EnquiryFormProps = {
	id: string;
	type: EnquiryType;
	wide?: boolean;
};
export const EnquiryForm = ({ id, type, wide }: EnquiryFormProps) => {
	const visibilityController = usePageVisibilityController();
	const controller = useEnquiryController();

	const handleSubmit: FormEventHandler = ev => {
		controller.submit(id, type, ev);
	};

	return (
		<EnquiryFormRoot
			className={wide ? 'wide' : undefined}
			onSubmit={handleSubmit}
			ref={type === 'full' ? visibilityController.enquiryFormRef : undefined}
		>
			{ type === 'full' && (
				<a id={EnquiryController.FORM_ID} className="scrollAnchor"/>
			)}

			<section className="enquiryContact">
				<EnquiryFormNameField className="enquiryContact__name"/>
				<EnquiryFormEmailField className="enquiryContact__email"/>
				<EnquiryFormPhoneField className="enquiryContact__phone"/>
			</section>

			<Divider/>
			{ type === 'full' && (
				<>
					{ !controller.yacht && (
						<section className="enquiryGeneralCharter">
							<EnquiryFormLocationField className="enquiryGeneralCharter__location"/>
							<EnquiryFormSeasonField className="enquiryGeneralCharter__season"/>
							<EnquiryFormGuestsField className="enquiryGeneralCharter__guests"/>
							<EnquiryFormYachtTypeField className="enquiryGeneralCharter__yachtType"/>
							<EnquiryFormBudgetField className="enquiryGeneralCharter__budget"/>
						</section>
					)}
					{ controller.yacht && (
						<section className="enquiryYachtCharter">
							<EnquiryFormDatesField className="enquiryYachtCharter__dates"/>
							<EnquiryFormEstimate className="enquiryYachtCharter__estimate"/>
							<EnquiryFormGuestsField className="enquiryYachtCharter__guests"/>
						</section>
					)}

					<EnquiryMessageField className="enquiryMessage"/>

					<Divider/>
				</>
			) }

			<section className="enquiryLegal">
				<EnquiryFormTermsMarketing/>
				<EnquiryFormMarketingCheckbox/>
			</section>

			<Divider/>

			<EnquiryFormSubmitButton className="submitEnquiry"/>
		</EnquiryFormRoot>
	);
};
