import type { SuggestionResult } from '../../../domain';
import type { SuggestContext, SuggestProviderProps, SuggestState } from './suggest-context';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaticRouter from 'next/router';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useYachtSearchFilter } from '../../search';


export type SelectedSuggestionResult = Readonly<{
	suggestion: SuggestionResult | null;
	source: 'hover' | 'navigation';
}>;
const NULL_SELECTED_RESULT: SelectedSuggestionResult = Object.freeze({
	suggestion: null,
	source: 'hover',
});

export type SuggestConfirmListener = (result: SuggestionResult) => boolean;

export type SuggestionSelectionHook = Pick<SuggestContext, 'selectResult' | 'selectedResult' | 'confirmSelectedResult' | 'onConfirmResult' | 'selectNextResult' | 'selectPreviousResult'>;
export const useSuggestionSelection = (suggestState: SuggestState, alwaysUpdateFilter: SuggestProviderProps['alwaysUpdateFilter'], closeSuggest: SuggestContext['closeSuggest']): SuggestionSelectionHook => {
	const { updateFilter } = useYachtSearchFilter();
	const largeScreen = useMediaQuery(useTheme().breakpoints.up('sm'));

	const [ selectedResult, setSelectedResult ] = useState<SelectedSuggestionResult>(NULL_SELECTED_RESULT);

	const selectResult = useCallback<SuggestContext['selectResult']>(selection => {
		setSelectedResult(selection);
	}, []);

	const navigateResults = useCallback((delta: number) => {
		if(suggestState.kind !== 'results' || !suggestState.results.length || !delta) {
			return;
		}

		const { results } = suggestState;

		setSelectedResult(prevSelectedResult => {
			const prevIndex = prevSelectedResult.suggestion ? results.indexOf(prevSelectedResult.suggestion) : -1;

			let nextIndex = prevIndex + delta;
			while(nextIndex < 0) {
				nextIndex += results.length;
			}
			if(nextIndex >= results.length) {
				nextIndex = 0;
			}

			return {
				suggestion: results[nextIndex],
				source: 'navigation',
			};
		});
	}, [suggestState]);

	const selectNextResult = useCallback<SuggestContext['selectNextResult']>(() => {
		navigateResults(1);
	}, [navigateResults]);

	const selectPreviousResult = useCallback<SuggestContext['selectPreviousResult']>(() => {
		navigateResults(-1);
	}, [navigateResults]);

	const listeners = useRef(new Set<SuggestConfirmListener>());
	const onConfirmResult = useCallback<SuggestContext['onConfirmResult']>(listener => {
		listeners.current.add(listener);
		return () => { listeners.current.delete(listener); };
	}, []);

	const confirmSelectedResult = useCallback<SuggestContext['confirmSelectedResult']>(() => {
		const { suggestion } = selectedResult;

		if(!suggestion) {
			updateFilter({ place: undefined, builder: undefined });
			return;
		}

		for(const listener of listeners.current) {
			if(listener(suggestion)) {
				return;
			}
		}

		switch(suggestion.type) {
			case 'article':
			case 'yacht':
				StaticRouter.push({
					pathname: suggestion.uri,
					query: StaticRouter.query,
					hash: '',
				}).catch(() => { /**/ });
				break;
			case 'builder':
				if(alwaysUpdateFilter || largeScreen) {
					updateFilter({ builder: suggestion.name, place: undefined });
					closeSuggest();
				} else {
					StaticRouter.push({
						pathname: 'search/yachts',
						query: { builder: suggestion.name },
						hash: '',
					}).catch(() => { /**/ });
				}
				break;
			case 'location':
				if(alwaysUpdateFilter || largeScreen) {
					updateFilter({ place: suggestion.name, builder: undefined });
					closeSuggest();
				} else {
					StaticRouter.push({
						pathname: 'search/yachts',
						query: { place: suggestion.name },
						hash: '',
					}).catch(() => { /**/ });
				}
		}
	}, [alwaysUpdateFilter, closeSuggest, largeScreen, selectedResult, updateFilter]);

	return useMemo(() => ({
		selectedResult,
		selectResult,
		selectNextResult,
		selectPreviousResult,
		confirmSelectedResult,
		onConfirmResult,
	}), [confirmSelectedResult, onConfirmResult, selectNextResult, selectPreviousResult, selectResult, selectedResult]);
};
