import type { Components } from '@mui/material/styles';
import { css } from '@mui/material/styles';


export const MuiFab: Components['MuiFab'] = {
	styleOverrides: {
		root: css`
			svg {
				width: 32px;
				height: 32px;
			}
		`,
	},
};
