import type { ArticlesSlice } from '../../../domain';
import type { SliceProps } from '../SliceProps';
import { Slice_ArticlesDefault } from './Slice_ArticlesDefault';
import { Slice_ArticlesSide } from './Slice_ArticlesSide';


export const Slice_Articles = (props: SliceProps<ArticlesSlice>) => {
	switch(props.position) {
		case 'side':
			return <Slice_ArticlesSide {...props}/>;
		default:
			return <Slice_ArticlesDefault {...props}/>;
	}
};
