import type { SuggestContext } from './suggest-context';
import StaticRouter, { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';


const SUGGEST_HASH = 'suggest';

export type SuggestOpenHook = Pick<SuggestContext, 'openSuggest' | 'closeSuggest' | 'suggestOpen'>;

export const useSuggestOpen = (): SuggestOpenHook => {
	const router = useRouter();

	const openSuggest = useCallback(() => {
		StaticRouter.push({
			pathname: StaticRouter.pathname,
			query: StaticRouter.query,
			hash: SUGGEST_HASH,
		}).catch(() => {/**/});
	}, []);

	const closeSuggest = useCallback(() => {
		StaticRouter.replace({
			pathname: StaticRouter.pathname,
			query: StaticRouter.query,
			hash: '',
		}).catch(() => { /**/ });
	}, []);

	const suggestOpen = router.asPath.includes('#' + SUGGEST_HASH);

	return useMemo(() => ({
		suggestOpen,
		openSuggest,
		closeSuggest,
	}), [closeSuggest, openSuggest, suggestOpen]);
};
