import type { ImagesSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import { styled, css } from '@mui/material/styles';
import NextImage from 'next/legacy/image';
import { getImageUrl } from '../util';


const CmsImageListSliceRoot = styled('section')(({ theme }) => css`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: ${theme.spacing(.5)};
	width: 100%;
	max-width: 100ch !important;

	border-radius: ${theme.shape.borderRadius}px;
	overflow: hidden;
	
	> div {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 60%;
		
		&:first-child {
			grid-column: 1 / span 2;
		}
	}
`);

export const Slice_Images = ({ slice }: SliceProps<ImagesSlice>) => {
	return (
		<CmsImageListSliceRoot>
			{slice.images.map(img => (
				<div key={img.id}>
					<NextImage
						src={`${img.id}.jpg`}
						alt={img.caption}
						layout="fill"
						objectFit="cover"
						placeholder="blur"
						blurDataURL={getImageUrl(img, 40)}
					/>
				</div>
			))}
		</CmsImageListSliceRoot>
	);
};
