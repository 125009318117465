import type { HeaderNavProps } from './HeaderNav';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { useRef, useState } from 'react';
import { CILogo, InternalLink, VisuallyHiddenLabel } from '../../../components';
import { NavMenuItem } from './NavMenuItem';
import { AGENT_LINKS } from './agent-links';


const NavMenuButton = styled(IconButton)(({ theme }) => css`
	${theme.breakpoints.up('sm')} {
		display: none;
	}
	
	color: white;

	&.over-hero {
		filter: drop-shadow(0px 0px 8px rgba(0,0,0,.3)) drop-shadow(0px 0px 4px rgba(0,0,0,.6));
	}
`);

const NavDrawer = styled(Drawer)(({ theme }) => css`
	.MuiPaper-root {
		display: flex;
		flex-direction: column;
		height: 100%;

		background-color: ${theme.palette.secondary.main};
		color: ${theme.palette.secondary.contrastText};
	}

	.nav-drawer__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: ${theme.spacing(1)};

		a {
			display: flex;
			> * {
				margin: auto 0;
			}
		}
		
		* {
			color: ${theme.palette.secondary.contrastText};
		}
	}

	.nav-drawer__internal-links {
		.MuiButtonBase-root {
			font-size: 1.5rem;
			font-weight: bold;
		}
	}

	.nav-drawer__agent-links {
		margin: auto 0 ${theme.spacing(6)} 0;
	}
`);

const MENU_BUTTON_ID = 'navCollapsedMenuButton';
const MENU_DRAWER_ID = 'navCollapsedMenuDrawer';

export const HeaderNavCollapsed = ({ className, overHero, links }: HeaderNavProps) => {
	const { t } = useTranslation();
	const [ menuOpen, setMenuOpen ] = useState(false);

	const buttonRef = useRef<HTMLButtonElement>(null);

	return (
		<>
			<NavMenuButton
				id={MENU_BUTTON_ID}
				className={clsx(className, { 'over-hero': overHero })}
				ref={buttonRef}
				onClick={ () => setMenuOpen(true) }
				aria-controls={MENU_DRAWER_ID}
				aria-haspopup
				aria-expanded={menuOpen ? 'true' : undefined}
			>
				<MenuIcon fontSize="large" aria-hidden/>
				<VisuallyHiddenLabel label="Menu" htmlFor={MENU_BUTTON_ID}/>
			</NavMenuButton>
			<NavDrawer
				id={MENU_DRAWER_ID}
				open={menuOpen}
				onClose={ () => setMenuOpen(false) }
				anchor="top"
			>
				<div className="nav-drawer__header">
					<InternalLink href="/">
						<CILogo iconOnly/>
					</InternalLink>
					<IconButton
						onClick={() => setMenuOpen(false)}
						color="inherit"
					>
						<CloseIcon fontSize="large"/>
					</IconButton>
				</div>
				<List className="nav-drawer__internal-links">
					{ links.map(({ title, uri }) => (
						<NavMenuItem key={uri}>
							<ButtonBase LinkComponent={InternalLink} href={uri} focusRipple>
								{ title }
							</ButtonBase>
						</NavMenuItem>
					)) }
				</List>

				<List className="nav-drawer__agent-links">
					{ AGENT_LINKS.map(({ href, title }) => (
						<NavMenuItem key={href}>
							<ButtonBase
								focusRipple
								href={ href }
							>
								{t(title)}
							</ButtonBase>
						</NavMenuItem>
					)) }
				</List>
			</NavDrawer>
		</>
	);
};
