import type { FooterLinks, HeaderLinks, Yacht } from '../../../domain';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo } from 'react';
import { Analytics } from '../../analytics';
import { EnquiryControllerProvider } from '../../enquiry/enquiry-controller-context';
import { usePreferences } from '../../preferences';
import { Slices } from '../../slices';
import { YachtHeader, YachtProvider } from '../../yacht';
import { Page } from '../components';
import { Hero } from '../components/Hero';


const YachtPageTop = styled('div')(({ theme }) => css`
	display: flex;
	flex-direction: column;
	> * + * {
		margin-top: ${theme.spacing(2)};
	}
`);

export type YachtPageProps = {
	yacht: Yacht;
	headerLinks: HeaderLinks;
	footerLinks: FooterLinks;
};
export const YachtPage = ({ yacht, headerLinks, footerLinks }: YachtPageProps) => {
	const { t } = useTranslation();
	const small = useMediaQuery(useTheme().breakpoints.down('sm'));
	const { currency } = usePreferences();

	const title = useMemo<string>(() => {
		const titleSegments: string[] = [];
		if(yacht.active) {
			titleSegments.push(t('common:yacht-title.active', { yachtName: yacht.name }));
		} else {
			titleSegments.push(yacht.name);
		}

		titleSegments.push(
			' - ',
			yacht.builder.name,
			t(yacht.category.id === 'POWER' ? 'common:motor-yacht' : 'common:sailing-yacht')
		);

		return titleSegments.join(' ');
	}, [yacht, t]);

	useEffect(() => {
		Analytics.viewYacht(yacht, currency);
	}, [currency, yacht]);

	return (
		<EnquiryControllerProvider
			yacht={yacht}
			campaignCode={yacht.campaignCode}
			labelKey="common:enquire"
		>
			<YachtProvider yacht={yacht}>
				<NextSeo title={title}/>
				<Page
					id={yacht.id}
					hero={
						<Hero
							image={yacht.image}
							imageObjectPosition="center bottom"
							contentAlign="bottom"
							small={small}
						>
							{ <YachtHeader inHero/> }
						</Hero>
					}
					head={(
						<>
							<YachtPageTop>
								<YachtHeader/>
							</YachtPageTop>
							{ yacht.headSlices && <Slices position="head" slices={yacht.headSlices}/> }
						</>
					)}
					main={<Slices position="main" slices={yacht.slices}/>}
					side={yacht.sideSlices && <Slices position="side" slices={yacht.sideSlices}/>}
					tail={yacht.tailSlices}
					alternativeUris={yacht.uris}
					relatedYachts={yacht.relatedYachts}
					headerLinks={ headerLinks }
					footerLinks={ footerLinks }
				/>
			</YachtProvider>
		</EnquiryControllerProvider>
	);
};
