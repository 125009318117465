import type { YachtSpecificationSlice } from '../../domain';
import { useMemo } from 'react';
import { toNauticalMiles, toUSGallons } from '../../util';
import { getImageUrl } from '../util';
import { useYacht } from '../yacht';
import { JsonLd } from './json-ld';
import { jsonLdOrganization, jsonLdQuantitativeValue } from './structured-data-util';

/*
	https://schema.org/Vehicle
*/

export type YachtJsonLd = {
	specification: YachtSpecificationSlice['specification'];
};
export const YachtJsonLd = ({ specification }: YachtJsonLd) => {
	const yacht = useYacht();

	const value = useMemo(() => {
		if(!yacht.active) {
			return null;
		}
		return {
			'@type': 'Vehicle',
			additionalType: 'http://www.productontology.org/doc/Yacht',
			name: yacht.name,
			description: yacht.metaDescription,
			productionDate: yacht.yearLaunch.toString(),
			manufacturer: jsonLdOrganization(yacht.builder.name),
			category: yacht.category.id === 'POWER' ? 'Motor Yacht' : 'Sailing Yacht',
			speed: specification.speedCruiseKPH && jsonLdQuantitativeValue(toNauticalMiles(specification.speedCruiseKPH), 'kn'),
			fuelConsumption: specification.fuelCruiseLPH && jsonLdQuantitativeValue(toUSGallons(specification.fuelCruiseLPH), 'gph'),
			vehicleEngine: specification.engine && {
				'@type': 'EngineSpecification',
				engineType: specification.engine,
			},
			countryOfOrigin: specification.flagCountry?.id,
			offers: [{
				'@type': 'Offer',
				businessFunction: 'http://purl.org/goodrelations/v1#LeaseOut',
				seller: jsonLdOrganization('Charter Index'),
				price: yacht.basePrice,
				priceCurrency: yacht.baseCurrency,
				url: yacht.uris.en,
			}],
			image: yacht.metaImages?.map(img => getImageUrl(img)),
		};
	}, [yacht, specification]);

	return <JsonLd value={ value }/>;
};
