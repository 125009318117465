import type { Breakpoint } from '@mui/material/styles';
import type { Shadows } from '@mui/material/styles/shadows';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { MuiAccordion, MuiAccordionDetails, MuiAccordionSummary, MuiBackdrop, MuiCssBaseline, MuiFab } from './components';
import { palette } from './palette';


declare module '@mui/material/styles' {
	interface Theme {
		shadows: Shadows;

		contentWidth: string,
		sideContentBreakpoint: Breakpoint,

		pagePaddingVars: (theme: Theme) => string;
	}
	interface ThemeOptions {
		contentWidth: string,
		sideContentBreakpoint: Breakpoint,

		pagePaddingVars: (theme: Theme) => string;
	}
}

export const theme = responsiveFontSizes(createTheme({
	palette,
	contentWidth: '70ch',
	sideContentBreakpoint: 'md',

	pagePaddingVars: theme => `
		--page-pad-x: ${theme.spacing(1)};
		--page-pad-y: ${theme.spacing(2)};

		${theme.breakpoints.up('sm')} {
			--page-pad-x: ${theme.spacing(3)};
			--page-pad-y: ${theme.spacing(3)};
		}

		${theme.breakpoints.up('lg')} {
			--page-pad-x: ${theme.spacing(6)};
			--page-pad-y: ${theme.spacing(3)};
		}
	`,

	typography: {
		fontFamily: 'Mulish, sans-serif',
		h1: {
			fontWeight: 'bold',
			fontSize: '3.75rem',
		},
		h2: {
			fontWeight: 'bold',
			fontSize: '2rem',
		},
		h3: {
			fontWeight: 'bold',
			fontSize: '1.5rem',
		},
		h4: {
			fontWeight: 'bold',
		},
		h5: {
			fontWeight: 'bolder',
		},
		body1: {
			fontSize: '1.2rem',
		},
		body2: {
			fontSize: '1rem',
		},
		button: {
			textTransform: 'none',
			whiteSpace: 'nowrap',
			fontSize: '1.25rem',
		},
	},
	shape: {
		borderRadius: 8,
	},
	components: {
		MuiAccordion,
		MuiAccordionDetails,
		MuiAccordionSummary,
		MuiBackdrop,
		MuiCssBaseline,
		MuiFab,
	},
}));

