import type { Language } from '../../domain';
import facebook from './facebook.svg';
import flagDe from './flag-de.svg';
import flagEnGb from './flag-en-GB.svg';
import flagEn from './flag-en.svg';
import flagEs from './flag-es.svg';
import flagFr from './flag-fr.svg';
import flagIt from './flag-it.svg';
import flagJa from './flag-ja.svg';
import flagPt from './flag-pt.svg';
import flagRu from './flag-ru.svg';
import flagZh from './flag-zh.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';
import logoPrimary from './logo-primary.svg';
import logoHorizontalWhite from './logo-text.svg';
import logoWhite from './logo-white.svg';
import placeholder from './placeholder.svg';
import twitter from './twitter.svg';
import vimeo from './vimeo.svg';
import youtube from './youtube.svg';


export const PLACEHOLDER_SVG = placeholder.src;

export const LANGUAGE_FLAG_SVGS: Readonly<Record<Language, string>> = {
	'de': flagDe.src,
	'en': flagEn.src,
	'en-GB': flagEnGb.src,
	'es': flagEs.src,
	'fr': flagFr.src,
	'it': flagIt.src,
	'ja': flagJa.src,
	'pt': flagPt.src,
	'ru': flagRu.src,
	'zh': flagZh.src,
};

export const LOGO_TEXT_SVG = logoHorizontalWhite.src;
export const LOGO_PRIMARY_SVG = logoPrimary.src;
export const LOGO_WHITE_SVG = logoWhite.src;

export const FACEBOOK_LOGO_SVG = facebook.src;
export const INSTAGRAM_LOGO_SVG = instagram.src;
export const LINKEDIN_LOGO_SVG = linkedin.src;
export const TWITTER_LOGO_SVG = twitter.src;
export const VIMEO_LOGO_SVG = vimeo.src;
export const YOUTUBE_LOGO_SVG = youtube.src;
