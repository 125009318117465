import type { FC } from 'react';
import { useContext , useCallback , createContext, useMemo, useState } from 'react';
import { CookieModal } from './CookieModal';


export type CookieModalContext = {
	openCookieModal: () => void;
};

const cookieModalContext = createContext<CookieModalContext | null>(null);

export const CookieModalProvider: FC = ({ children }) => {
	const [ open, setOpen ] = useState(false);

	const closeCookieModal = useCallback(() => { setOpen(false); }, []);

	const value = useMemo<CookieModalContext>(() => ({
		openCookieModal: () => setOpen(true),
	}), []);

	return (
		<cookieModalContext.Provider value={value}>
			{ children }
			<CookieModal open={open} onClose={ closeCookieModal }/>
		</cookieModalContext.Provider>
	);
};

export const useCookieModal = (): CookieModalContext => {
	const ctx = useContext(cookieModalContext);
	if(!ctx) {
		throw new Error('cookieModalContext was null; missin <CookieModalProvider>?');
	}

	return ctx;
};
