import type { LinkButtonComponent } from './link-button-component-type';
import { css, styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';


export const CardButton = styled(ButtonBase)(({ theme }) => css`
	--card-button-focus-scaling: 1;
	--card-button-focus-duration: 350ms;

	--card-button-shadow-default: ${theme.shadows[2]};
	--card-button-shadow-focus: ${theme.shadows[4]};

	&.no-elevation {
		--card-button-shadow-default: ${theme.shadows[0]};
		--card-button-shadow-focus: ${theme.shadows[0]};
	}
	
	border-radius: ${theme.shape.borderRadius}px;
	box-shadow: var(--card-button-shadow-default);

	outline-offset: ${theme.spacing(.5)};

	transition: box-shadow var(--card-button-focus-duration);

	&:focus-visible {
		outline: 2px solid ${theme.palette.secondary.main};
	}
	
	&:focus-visible, &:hover {
		--card-button-focus-scaling: 1.025;
		box-shadow: var(--card-button-shadow-focus);

		@media(prefers-reduced-motion: reduce) {
			--card-button-focus-scaling: 1;
			box-shadow: var(--card-button-shadow-default);
		}
	}
`) as LinkButtonComponent;
