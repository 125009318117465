import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { LifebuoyIcon } from '../../components';
import { Page } from '../components';


const ErrorPageContentRoot = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-top: 20vh;

	p {
		margin: var(--page-pad-y) 0;
	}

	.MuiSvgIcon-root {
		font-size: 5rem;
	}
`;

type ErrorPageProps = {
	reason: 'not-found' | 'default';
};
const ErrorPage = ({ reason }: ErrorPageProps) => {
	const { t } = useTranslation();

	const [ title, description ] = useMemo<[string, string]>(() => [
		t(`common:error.${reason}.title`),
		t(`common:error.${reason}.description`),
	], [reason, t]);

	return (
		<Page
			id={title + description}
			main={(
				<ErrorPageContentRoot>
					<NextSeo title={title}/>
					<Typography variant="h1">
						{ title }
					</Typography>
					<Typography>{ description }</Typography>
					<LifebuoyIcon/>
				</ErrorPageContentRoot>
			)}
		/>
	);
};


export const NotFoundErrorPage = () => <ErrorPage reason="not-found"/>;
export const DefaultErrorPage = () => <ErrorPage reason="default"/>;
