import type { ClassNameProp } from '../../../util';
import { CharterIcon, type CharterIconName } from '@charterindex/icons';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { useYacht } from '../../yacht-context';


const YachtStatIconsRoot = styled('div')(({ theme }) => css`
	display: flex;
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		+ * {
			margin-inline-start: ${theme.spacing(4)};
		}
	}
	.icon-and-stat {
		display: flex;
		align-items: center;
		
		font-weight: 500;

		svg {
			margin-inline-end: ${theme.spacing(1)};
			&.guests {
				margin-inline-end: ${theme.spacing(.3)};
			}
		}
	}
`);

type IconStat = {
	icon: CharterIconName,
	title: string;
	value: number;
};

export const YachtStatIcons = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();
	const yacht = useYacht();

	const iconStats = useMemo<IconStat[]>(() => [
		{
			icon: 'guests',
			title: t('common:guests'),
			value: yacht.guestsSleeping,
		}, {
			icon: 'cabins',
			title: t('common:cabins'),
			value: yacht.cabinCount,
		}, {
			icon: 'crew',
			title: t('common:crew'),
			value: yacht.crewSize,
		},
	], [yacht, t]);

	return (
		<YachtStatIconsRoot className={className}>
			{ iconStats.map(({ icon, title, value }) => (
				<div key={title}>
					<Typography className="icon-and-stat" variant="h5" component="span">
						<CharterIcon className={icon} iconName={icon}/>
						<span>{ value }</span>
					</Typography>
					<Typography className="caption" variant="caption">
						{ title }
					</Typography>
				</div>
			))}
		</YachtStatIconsRoot>
	);
};
