import useTranslation from 'next-translate/useTranslation';
import { useYachtSearch } from '../yacht-search-context';




export const YachtSearchTotalCount = () => {
	const { t } = useTranslation();
	const { loading, results, totalCount } = useYachtSearch();

	return (
		<>{loading && results.length === 0 ? t('common:loading') : t('common:count.yachts', { count: totalCount })}</>
	);
};
