import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { LOGO_PRIMARY_SVG, LOGO_TEXT_SVG, LOGO_WHITE_SVG } from '../static';



type CILogoMode = 'primary' | 'black' | 'white' | 'shadow';

const getLogoSrc = (iconOnly: boolean | undefined, mode: CILogoMode): string => {
	if(!iconOnly) {
		return LOGO_TEXT_SVG;
	}
	return mode === 'primary' ? LOGO_PRIMARY_SVG : LOGO_WHITE_SVG;
};

const CILogoImage = styled('img')(({ theme }) => css`
	height: ${theme.spacing(3.5)};
	max-width: 100%;

	&.ci-logo--shadow {
		filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.8));
	}

	&.ci-logo--black {
		filter: invert(100%);
	}
`);

export type CILogoProps = {
	className?: string,
	iconOnly?: boolean,
	mode?: 'primary' | 'black' | 'white' | 'shadow',
	role?: string,
};
export const CILogo = ({ className, iconOnly, role, mode = 'shadow' }: CILogoProps) => {
	const { t } = useTranslation();

	return (
		<CILogoImage
			role={role}
			className={clsx(className, `ci-logo--${mode}`)}
			src={getLogoSrc(iconOnly, mode)}
			alt={t('common:brand')}
		/>
	);
};
CILogo.displayName = 'CILogo';
