import MenuItem from '@mui/material/MenuItem';
import { css, styled } from '@mui/material/styles';


export const NavMenuItem = styled(MenuItem)(({ theme }) => css`
	display: flex;
	justify-content: stretch;
	padding: 0;

	> * {
		width: 100%;
	}

	* {
		text-align: left;
	}

	.MuiButtonBase-root {
		text-align: left;
		padding: ${theme.spacing(1, 2)};
	}
`);
