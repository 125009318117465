import type { EmblaOptionsType } from 'embla-carousel';
import type { FC } from 'react';
import type { ClassNameProp } from '../util';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import Hidden from '@mui/material/Hidden';
import { css, styled } from '@mui/material/styles';
import { useEmblaCarousel } from 'embla-carousel/react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Analytics } from '../analytics';


const CarouselRoot = styled('div')(({ theme }) => css`
	position: relative;
	padding: 5px 0;
	width: 100%;
	
	.viewport {
		width: 100%;

		&.is-draggable {
			cursor: grab;
		}

		&.is-dragging {
			cursor: grabbing;
		}
	}

	.slides {
		display: flex;
		user-select: none;
		-webkit-touch-callout: none;
		-webkit-tap-highlight-color: none;
	}

	.scroll-button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		background-color: ${theme.palette.secondary.main};
		color: ${theme.palette.secondary.contrastText};

		transition: opacity 225ms, background-color 225ms !important;

		&:hover {
			background-color: ${theme.palette.secondary.light};
		}

		&--prev {
			left: ${theme.spacing(3)};
		}

		&--next {
			right: ${theme.spacing(3)};
		}
	}
`);

const noop = () => { /**/ };

export type CarouselProps = ClassNameProp & {
	name: string;
	slidesToScroll?: number;
	startIndex?: number;
	speed?: number;
	dragFree?: boolean;
	onSelect?: (index: number) => void;
	onScroll?: () => void;
	onSettle?: () => void;
	onClick?: () => void;
};
export const Carousel: FC<CarouselProps> = ({
	name,
	children = '',
	slidesToScroll = 1,
	startIndex = 0,
	speed = 9,
	dragFree,
	onSelect = noop,
	onScroll = noop,
	onSettle = noop,
	onClick = noop,
	className,
}) => {
	const { t } = useTranslation();

	const emblaProps = useMemo<Partial<EmblaOptionsType>>(() => ({
		align: 'start',
		loop: false,
		dragFree,
		containScroll: 'trimSnaps',
		slidesToScroll,
		startIndex,
		speed,
	}), [dragFree, slidesToScroll, speed, startIndex]);

	const [emblaRef, embla] = useEmblaCarousel(emblaProps);
	const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
	const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

	const scrollPrev = useCallback(() => {
		if (embla) {
			embla.scrollPrev();
		}
		Analytics.interaction({ target: name, action: 'scroll', value: 'backwards' });
	}, [embla, name]);

	const scrollNext = useCallback(() => {
		if (embla) {
			embla.scrollNext();
		}
		Analytics.interaction({ target: name, action: 'scroll', value: 'forwards' });
	}, [embla, name]);

	const enableButtons = useCallback(() => {
		if(embla) {
			setPrevButtonEnabled(embla.canScrollPrev());
			setNextButtonEnabled(embla.canScrollNext());
		}
	}, [embla]);

	const selectIndex = useCallback(() => {
		if(embla) {
			onSelect(embla.selectedScrollSnap());
		}
	}, [embla, onSelect]);

	useEffect(() => {
		if (!embla) {
			return noop;
		}
		embla.on('select', enableButtons);
		embla.on('select', selectIndex);
		enableButtons();
		return () => {
			embla.off('select', enableButtons);
			embla.off('select', selectIndex);
		};
	}, [embla, enableButtons, selectIndex]);

	useEffect(() => {
		if (!embla) {
			return () => { /**/ };
		}
		embla.on('scroll', onScroll);
		return () => {
			embla.on('scroll', onScroll);
		};
	}, [embla, onScroll]);

	useEffect(() => {
		if (!embla) {
			return () => { /**/ };
		}
		embla.on('settle', onSettle);
		return () => {
			embla.on('settle', onSettle);
		};
	}, [embla, onSettle]);

	const handleClick = useCallback(() => {
		if (embla?.clickAllowed()) {
			onClick();
		}
	}, [embla, onClick]);

	return (
		<CarouselRoot className={className}>
			<div className="viewport" ref={emblaRef}>
				<div
					className="slides"
					onClick={handleClick}
				>
					{children}
				</div>
			</div>
			<Hidden smDown implementation="css">
				<Fade in={prevButtonEnabled}>
					<Fab
						className="scroll-button scroll-button--prev"
						onClick={scrollPrev}
						aria-label={t('common:previous')}
						size="small"
					>
						<LeftIcon fontSize="inherit" />
					</Fab>
				</Fade>
				<Fade in={nextButtonEnabled}>
					<Fab
						className="scroll-button scroll-button--next"
						onClick={scrollNext}
						aria-label={t('common:next')}
						size="small"
					>
						<RightIcon fontSize="inherit" />
					</Fab>
				</Fade>
			</Hidden>
		</CarouselRoot>
	);
};
