import type { FC } from 'react';
import type { Currency } from '../../domain';
import useTranslation from 'next-translate/useTranslation';
import StaticRouter from 'next/router';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { asCurrency, asLanguage, isCurrency } from '../../domain';
import { CURRENCY_COOKIE, defaultCurrencyForLanguage } from '../../util';
import { Analytics } from '../analytics';
import { useCookie } from '../cookie';
import { makeCurrencyFormatter } from './currency-format';


export type DimensionUnit = 'm' | 'ft';
export const DIMENSION_UNITS: DimensionUnit[] = [ 'm', 'ft' ];


type PreferencesContext = {
	dimensionUnit: DimensionUnit;
	setDimensionUnit: (newUnit: DimensionUnit) => void;
	currency: Currency,
	setCurrency: (newCurrency: Currency) => void;
	formatCurrency: (value: number | undefined) => string;
};

const preferencesContext = createContext<PreferencesContext | null>(null);

export const PreferencesProvider: FC = ({ children }) => {
	const { lang } = useTranslation();
	const { getCookie, setEssentialCookie } = useCookie();

	const [ dimensionUnit, setDimensionUnit ] = useState<DimensionUnit>('m');
	const [ currencyValue, setCurrencyValue ] = useState<Currency | undefined>(undefined);

	const currency = useMemo<Currency>(() => {
		if(currencyValue) {
			return currencyValue;
		}

		const cookie = getCookie(CURRENCY_COOKIE);
		if(isCurrency(cookie)) {
			return cookie;
		}

		return defaultCurrencyForLanguage(asLanguage(lang));
	}, [currencyValue, getCookie, lang]);

	const setCurrency = useCallback<PreferencesContext['setCurrency']>(newCurrency => {
		newCurrency = asCurrency(newCurrency);
		setCurrencyValue(prevCurrency => {
			if(prevCurrency !== newCurrency) {
				setEssentialCookie(CURRENCY_COOKIE, newCurrency);

				Analytics.changePreference({ preference: 'currency', value: newCurrency });

				StaticRouter
					.replace(window.location)
					.catch(() => { /**/ });
			}

			return newCurrency;
		});
	}, [setEssentialCookie]);

	const formatCurrency = useMemo<PreferencesContext['formatCurrency']>(() => (
		makeCurrencyFormatter(asLanguage(lang), currency)
	), [currency, lang]);

	const value = useMemo<PreferencesContext>(() => ({
		dimensionUnit, setDimensionUnit,
		currency, setCurrency, formatCurrency,
	}), [currency, dimensionUnit, formatCurrency, setCurrency]);

	return (
		<preferencesContext.Provider value={value}>
			{ children }
		</preferencesContext.Provider>
	);
};


export const usePreferences = (): PreferencesContext => {
	const ctx = useContext(preferencesContext);
	if(!ctx) {
		throw new Error('preferencesContext was null; missing <PreferencesProvider>?');
	}

	return ctx;
};
