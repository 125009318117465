import type { RangedSelectFunction, ReactInfiniteCalendarProps } from 'react-infinite-calendar';
import type { ClassNameProp } from '../../util';
import { css, styled, useTheme } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import InfiniteCalendar, { Calendar, EVENT_TYPE, withRange } from 'react-infinite-calendar';
import { MAX_DATE_FILTER, MIN_DATE_FILTER } from '../yacht-search-filter-util';
import 'react-infinite-calendar/styles.css';

/*
	https://github.com/clauderic/react-infinite-calendar#prop-types
*/

const DateRangePickerRoot = styled('div')(({ theme }) => css`
	.Cal__Header__blank {
		color: ${theme.palette.secondary.contrastText};
	}

	.Cal__Header__date {
		font-size: ${theme.typography.body1.fontSize}
	}

	.Cal__Header__root {
		padding: ${theme.spacing(1, 1.5)};
		border-top-left-radius: ${theme.shape.borderRadius}px;
		border-top-right-radius: ${theme.shape.borderRadius}px;
		min-height: 0;
		height: 4em;
		padding-top: ${theme.spacing(2)};
	}

	.Cal__Header__wrapper {
		&::before, &::after {
			display: none;
		}
	}
	
	.Cal__Header__dateWrapper {
		height: unset;
		font-size: unset;
		line-height: unset;
	}
	
	.Cal__Header__date {
		position: unset;
		top: unset;
		line-height: 1em;
 	}

	.Cal__Container__wrapper {
		border-bottom-left-radius: ${theme.shape.borderRadius}px;
		border-bottom-right-radius: ${theme.shape.borderRadius}px;	
		overflow: hidden;
	}

	.Cal__Weekdays__day {
		padding: ${theme.spacing(1, 0, .5, 0)};
	}
`);


const CalendarWithRange = withRange(Calendar);
const DISPLAY_OPTIONS: ReactInfiniteCalendarProps['displayOptions'] = {
	showTodayHelper: false,
};

export type InfiniteDateRangePickerProps = ClassNameProp & {
	selected: false | Date | {
		start: Date;
		end: Date;
	},
	onSelect: RangedSelectFunction;
};

/**
 * Thin wrapper around <InfiniteCalendar>.
 */
export const InfiniteDateRangePicker = ({ className, onSelect, selected }: InfiniteDateRangePickerProps) => {
	const theme = useTheme();

	const calendarTheme = useMemo<ReactInfiniteCalendarProps['theme']>(() => ({
		accentColor: theme.palette.secondary.dark,
		selectionColor: theme.palette.secondary.main,
		textColor: {
			active: theme.palette.secondary.contrastText,
			default: theme.palette.text.primary,
		},
		todayColor: theme.palette.primary.main,
		headerColor: theme.palette.secondary.main,
		weekdayColor: theme.palette.secondary.dark,
	}), [theme]);

	const handleSelect = useCallback<RangedSelectFunction>((value) => {
		if(value.eventType !== EVENT_TYPE.END) {
			return;
		}
		onSelect(value);
	}, [onSelect]);

	return (
		<DateRangePickerRoot className={className}>
			<InfiniteCalendar
				Component={CalendarWithRange}
				min={MIN_DATE_FILTER}
				minDate={MIN_DATE_FILTER}
				max={MAX_DATE_FILTER}
				maxDate={MAX_DATE_FILTER}
				width="100%"
				height={260}
				displayOptions={DISPLAY_OPTIONS}
				theme={calendarTheme}
				selected={selected}
				onSelect={handleSelect}
			/>
		</DateRangePickerRoot>
	);
};
