/* eslint-disable @typescript-eslint/no-explicit-any */
import { NextScript } from 'next/document';


function dedupe(bundles: any[]) {
	const files = new Set();
	const kept: any[] = [];

	bundles.forEach((bundle) => {
		if (!files.has(bundle.file)) {
			files.add(bundle.file);
			kept.push(bundle);
		}
	});
	return kept;
}

export class DeferNextScript extends NextScript {
	getDynamicChunks(files: any) {
		const {
			dynamicImports,
			assetPrefix,
			isDevelopment,
		} = this.context;

		return dedupe(dynamicImports).map((bundle) => {
			if (
				!bundle.file.endsWith('.js') ||
        files.allFiles.includes(bundle.file)
			) {
				return null;
			}

			return (
				<script
					defer={!isDevelopment}
					key={bundle.file}
					src={`${assetPrefix}/_next/${encodeURI(
						bundle.file
					)}`}
					nonce={this.props.nonce}
					crossOrigin={
						this.props.crossOrigin || process.env.__NEXT_CROSS_ORIGIN
					}
				/>
			);
		});
	}

	getScripts(files: any) {
		const {
			assetPrefix,
			buildManifest,
			isDevelopment,
		} = this.context;

		const normalScripts = files.allFiles.filter((file: any) => file.endsWith('.js'));
		const lowPriorityScripts = buildManifest.lowPriorityFiles?.filter((file) =>
			file.endsWith('.js')
		);

		return [...normalScripts, ...lowPriorityScripts].map((file) => (
			<script
				key={file}
				src={`${assetPrefix}/_next/${encodeURI(
					file
				)}`}
				nonce={this.props.nonce}
				defer={!isDevelopment}
				crossOrigin={this.props.crossOrigin || process.env.__NEXT_CROSS_ORIGIN}
			/>
		));
	}
}
