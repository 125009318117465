import type { ClassNameProp } from '@charterindex/armoury-www';
import type { ChangeEvent } from 'react';
import type { Enumeration, SuggestionResult } from '../../../../domain';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { SuggestController } from '../../../suggest/suggest-controller';
import { useEnquiryController } from '../../enquiry-controller-context';


const EnquiryFormLocationFieldAutocomplete = styled(Autocomplete)`
	.MuiIconButton-root {
		width: 28px;
		height: 28px;
	}
` as typeof Autocomplete;

export const EnquiryFormLocationField = ({ className }: ClassNameProp) => {
	const enquiryController = useEnquiryController();
	useSubject(enquiryController.model, 'locationId');
	useSubject(enquiryController, 'submitting');

	const api = useApi();
	const [ suggestController ] = useState(new SuggestController({ types: [ 'location' ] }, api));
	useSubject(suggestController, 'loading');
	useSubject(suggestController, 'results');

	const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
		suggestController.setQuery(ev.target.value);
	};

	const { t, lang } = useTranslation();

	useEffect(() => {
		suggestController.refetch();
	}, [lang, suggestController]);

	const options = enquiryController.fixedLocation
		? [ enquiryController.fixedLocation ]
		: suggestController.results;

	const value = enquiryController.fixedLocation
		? enquiryController.fixedLocation
		: suggestController.getByAreaOrSubArea(enquiryController.model.locationId);

	const handleAutocompleteChange = (_: unknown, value: Enumeration | SuggestionResult |  null) => {
		if(!value) {
			enquiryController.model.locationId = undefined;
			return;
		}

		let locationId: string;
		if('type' in value && value.type === 'location') {
			locationId = value.subArea || value.area || value.id;
		} else {
			locationId = value.id;
		}

		enquiryController.model.locationId = locationId;
	};

	return  (
		<EnquiryFormLocationFieldAutocomplete<Enumeration | SuggestionResult>
			className={className}
			value={value}
			onChange={handleAutocompleteChange}
			options={options}
			getOptionLabel={o => o.name}
			loading={suggestController.loading}
			disabled={!!enquiryController.fixedLocation || enquiryController.submitting}
			renderInput={params => (
				<TextField
					{...params}
					label={t('common:enquiry.destination.label')}
					size="small"
					onChange={handleInputChange}
					InputProps={{
						...params.InputProps,
						startAdornment: <LocationOnIcon/>,
					}}
					required
				/>
			)}
		/>
	);
};
