import type { ReactElement } from 'react';
import type { HeaderLinks, Language } from '../../../domain';
import { languages } from '../../../domain';
import { YachtSearchFilterDrawer, YachtSearchFilterProvider, YachtSearchHeader, YachtSearchOrderProvider, YachtSearchProvider, YachtSearchResults } from '../../search';
import { YachtSearchPageSeo } from '../../search/components/YachtSearchPageSeo';
import { Page } from '../components';


const ALTERNATIVE_URIS: Record<Language, string> = Object.fromEntries(
	languages.map(language => [
		language,
		`/${ language === 'en' ? '' : language}/search/yachts`,
	])
) as Record<Language, string>;

export type YachtSearchPageProps = {
	extraTopContent?: ReactElement | null
	headerLinks?: HeaderLinks;
	search?: string;
};
export const YachtSearchPage = ({ extraTopContent = null, headerLinks, search }: YachtSearchPageProps) => (
	<YachtSearchOrderProvider>
		<YachtSearchFilterProvider initialSearch={search}>
			<YachtSearchProvider>
				<YachtSearchPageSeo />
				<Page
					id="yacht-search-page"
					head={<YachtSearchHeader/>}
					headSticky
					main={(
						<>
							{extraTopContent}
							<YachtSearchResults/>
						</>
					)}
					alternativeUris={ALTERNATIVE_URIS}
					headerLinks={headerLinks}
				/>
				<YachtSearchFilterDrawer/>
			</YachtSearchProvider>
		</YachtSearchFilterProvider>
	</YachtSearchOrderProvider>
);
