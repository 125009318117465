import type { FC } from 'react';
import type { YachtLimited, YachtSearchQuery } from '../../domain';
import type { ClientYachtSearchFilter } from './yacht-search-filter-util';
import deepEqual from 'deep-equal';
import useTranslation from 'next-translate/useTranslation';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useApi } from '../api';
import { usePreferences } from '../preferences';
import { prepareDateFilter , FILTER_KEYS } from './yacht-search-filter-util';
import { useYachtSearchFilter } from './yacht-search-filter-context';
import { useYachtSearchOrder } from './yacht-search-order-context';


type ClientYachtSearchQuery = Omit<YachtSearchQuery, 'filter'> & {
	filter: ClientYachtSearchFilter
};

type SearchStatus = {
	loading: boolean;
	totalCount: number;
	fetchedCount: number;
	hasMore: boolean;
};
const DEFAULT_SEARCH_STATUS: SearchStatus = {
	loading: true,
	totalCount: 0,
	fetchedCount: 0,
	hasMore: false,
};

type YachtSearchContext = SearchStatus & {
	results: YachtLimited[][];
	fetchMore: () => void;
	totalCount: number;
};

const yachtSearchContext = createContext<YachtSearchContext | null>(null);

const LIMIT = 12;
const DEBOUNCE_DURATION = 150;

/** Represent the total state of a dispatched search query. */
type DispatchedQuery = ClientYachtSearchQuery & {
	lang: string;
	currency: string;
};

const isQueryIdentical = (prevQuery: DispatchedQuery | null, newQuery: DispatchedQuery): boolean => {
	if(!prevQuery) {
		return false;
	}

	return deepEqual(prevQuery, newQuery);
};

const prepareQuery = (query: ClientYachtSearchQuery): YachtSearchQuery => {
	const preparedQuery: YachtSearchQuery = {
		filter: {},
		page: query.page,
		sort: query.sort,
	};

	FILTER_KEYS.forEach(key => {
		switch(key) {
			case 'date': {
				preparedQuery.filter.date = prepareDateFilter(query.filter[key]);
				break;
			}
			default: {
				const value = query.filter[key];
				if(value) {
					preparedQuery.filter[key] = value as any; // eslint-disable-line @typescript-eslint/no-explicit-any
				}
			}

		}
	});

	return preparedQuery;
};

export const YachtSearchProvider: FC = ({ children }) => {
	const { lang } = useTranslation();
	const { filter } = useYachtSearchFilter();
	const { order } = useYachtSearchOrder();
	const { currency } = usePreferences();
	const api = useApi();

	const [ status, setStatus ] = useState<SearchStatus>(DEFAULT_SEARCH_STATUS);
	const [ results, setResults ] = useState<YachtSearchContext['results']>([]);

	const lastDispatchedQuery = useRef<DispatchedQuery | null>(null);
	const fetchResultsDebounce = useRef<any | undefined>(undefined); // eslint-disable-line @typescript-eslint/no-explicit-any

	const fetchResultsAbort = useRef<AbortController | null>(null);
	const fetchResults = useCallback((page: number) => {
		const query: ClientYachtSearchQuery = {
			filter,
			page: { offset: page * LIMIT, limit: LIMIT },
			sort: order,
		};

		// Do not re-fetch identical queries.
		const nextDispatchedQuery = { ...query, currency, lang };
		if(isQueryIdentical(lastDispatchedQuery.current, nextDispatchedQuery)) {
			return;
		}
		lastDispatchedQuery.current = { ...nextDispatchedQuery };

		// Abandon any in-flight requests.
		const needsDebounce = !!fetchResultsDebounce;
		clearTimeout(fetchResultsDebounce.current);
		fetchResultsAbort.current?.abort();

		const doFetch = async () => {
			setStatus(prevStatus => ({ ...prevStatus, loading: true }));

			fetchResultsAbort.current = new AbortController();

			const result = await api.search(prepareQuery(query), fetchResultsAbort.current);
			if(!result.data) {
				return;
			}

			const { data: { yachts, meta } } = result;
			setStatus(prevStatus => {
				const fetchedCount = prevStatus.fetchedCount + yachts.length;
				return {
					loading: false,
					totalCount: meta.totalCount,
					fetchedCount,
					hasMore: fetchedCount < meta.totalCount,
				};
			});

			if(yachts.length) {
				setResults(prevResult => [ ...prevResult, yachts ]);
			}
		};

		fetchResultsDebounce.current = setTimeout(doFetch, needsDebounce ? DEBOUNCE_DURATION : 0);
	}, [filter, order, currency, lang, api]);

	const page = useRef<number>(0);

	// Perform initial fetch on page load, or when filter, order, or currency has changed.
	useEffect(() => {
		page.current = 0;
		setResults([]);
		setStatus(DEFAULT_SEARCH_STATUS);
		fetchResults(page.current);
	}, [fetchResults]);

	// Fetch the next page of results.
	const fetchMore = useCallback(() => {
		if(status.loading || !status.hasMore) {
			return;
		}
		fetchResults(++page.current);
	}, [fetchResults, status]);

	const value = useMemo<YachtSearchContext>(() => ({
		...status,
		results,
		fetchMore,
	}), [fetchMore, results, status]);

	// Abandon in-flight requests on unmount.
	useEffect(() => () => {
		clearTimeout(fetchResultsDebounce.current);
		fetchResultsAbort.current?.abort();
	}, []);

	return (
		<yachtSearchContext.Provider value={value}>
			{ children }
		</yachtSearchContext.Provider>
	);
};


export const useYachtSearch = (): YachtSearchContext => {
	const ctx = useContext(yachtSearchContext);
	if(!ctx) {
		throw new Error('yachtSearchContext was null; missing <YachtSearchProvider>?');
	}

	return ctx;
};

