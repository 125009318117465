import type { ClassNameProp } from '@charterindex/armoury-www';
import TextField from '@mui/material/TextField';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


export const EnquiryMessageField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'message', { immediate: true });
	useSubject(controller, 'submitting');

	const { t } = useTranslation();

	return (
		<TextField
			className={className}
			label={t('common:enquiry.message.label')}
			value={controller.model.message}
			onChange={ev => controller.model.message = ev.target.value}
			multiline
			size="small"
			rows={3}
			fullWidth
			disabled={controller.submitting}
		/>
	);
};
