import type { ReactNode } from 'react';
import type { Category, YachtsByTypeSlice } from '../../domain';
import type { Query } from '../util';
import type { SliceProps } from './SliceProps';
import { CharterIcon } from '@charterindex/icons';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { CardButton, InternalLink } from '../components';
import { SliceTitle } from './components';


const YachtsByTypeRoot = styled('section')(({ theme }) => css`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: ${theme.spacing(2)};

	.type-list-button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		padding: ${theme.spacing(3, 0)};

		border-radius: ${theme.shape.borderRadius}px;
		
		color: ${theme.palette.secondary.main};
	}

	.icon {
		width: 60px;
		height: 60px;

		position: relative;

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			&.motor-icon {
				top: unset;
				bottom: -95%;
			}
		}
	}
`);

type CategoryDef = {
	category: Category['id'];
	icon: ReactNode;
	label: string;
	query: Query;
};

const NAMES: Record<Category['id'], string> = {
	POWER: 'motor',
	SAIL: 'sailing',
};

const getIcon = (category: Category['id']): JSX.Element => {
	switch(category) {
		case 'POWER':
			return <CharterIcon className="motor-icon" iconName="motorYacht" size={80}/>;
		case 'SAIL':
			return <CharterIcon className="sailing-icon" iconName="sailingYacht" size="xl"/>;
	}
};

const CATEGORIES: Category['id'][] = [ 'POWER', 'SAIL' ];


export const Slice_YachtsByType = ({ slice: { place, ...slice } }: SliceProps<YachtsByTypeSlice>) => {
	const { t } = useTranslation();

	const categories = (
		CATEGORIES.map<CategoryDef>(category => ({
			category,
			icon: getIcon(category),
			label: t(`common:${NAMES[category]}-yachts`),
			query: {
				category,
				place,
			},
		}))
	);

	return (
		<div>
			<SliceTitle slice={slice}/>
			<YachtsByTypeRoot>
				{
					categories.map(({ category, icon, label, query }) => (
						<CardButton
							className="type-list-button"
							LinkComponent={p => <InternalLink {...p} query={query}/>}
							href="/search/yachts"
							key={category}
						>
							<div className="icon">
								{icon}
							</div>
							<Typography component="span">
								{label}
							</Typography>
						</CardButton>
					))
				}
			</YachtsByTypeRoot>
		</div>
	);
};
