import type { Currency as CurrencyCode, Language as LanguageCode, Yacht } from '../../domain';
import type { DimensionUnit } from '../preferences';
import type { EnquiryYachtDetails } from '../enquiry/enquiry-controller';


export namespace Analytics {
	export namespace ChangePreferenceEvent {
		export type Language = {
			preference: 'language';
			value: LanguageCode;
		};
		export type Currency = {
			preference: 'currency';
			value: CurrencyCode;
		};
		export type Unit = {
			preference: 'unit';
			value: DimensionUnit;
		};
	}
	export type ChangePreferenceEvent = ChangePreferenceEvent.Language | ChangePreferenceEvent.Currency | ChangePreferenceEvent.Unit;
	export const changePreference = (event: ChangePreferenceEvent): void => {
		gtag('event', 'change_preference', event);
	};

	export type ChangeFilterEvent = {
		filter: string;
		value: unknown;
	};
	export const changeFilter = (event: ChangeFilterEvent): void => {
		gtag('event', 'change_filter', event);
	};

	export type InteractionEvent = {
		target: string;
		action: 'click' | 'select' | 'open' | 'close' | 'scroll' | 'change';
		value?: string | number;
	};
	export const interaction = (event: InteractionEvent) => {
		gtag('event', 'interaction', event);
	};


	type YachtDetails = Pick<Yacht, 'id' | 'name' | 'lowPrice'>;

	export const viewYacht = (yacht: YachtDetails, currency: CurrencyCode): void => {
		gtag('event', 'view_item', {
			currency,
			value: yacht.lowPrice,
			items: [{
				item_id: yacht.id,
				item_name: yacht.name,
			}],
		});
	};

	export const enquiry = (yacht: EnquiryYachtDetails | null, currency: CurrencyCode): void => {
		const eventParams = yacht
			? {
				currency,
				value: (yacht.lowPrice ?? 0) * 0.05,
				items: [{
					item_id: yacht.id,
					item_name: yacht.name,
				}],
			} : {};

		gtag('event', 'generate_lead', eventParams);
	};
}
