import type { ReactNode } from 'react';
import { useMemo } from 'react';


export const useNoOrphan = (text: string | undefined) => useMemo<ReactNode>(() => {
	if(!text) {
		return '';
	}
	const words = text.split(' ');
	if(words.length <= 2) {
		return text;
	}

	const main = words.slice(0, -1).join(' ');
	const last = words[words.length - 1];

	return <>{main}&nbsp;{last}</>;
}, [text]);
