import type { PopoverOrigin } from '@mui/material/Popover';
import type { MouseEventHandler } from 'react';
import type { ClassNameProp } from '../../../util';
import type { YachtSearchOrder } from '../../yacht-search-order-context';
import SortIcon from '@mui/icons-material/Sort';
import FormControlLabel from '@mui/material/FormControlLabel';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo, useState } from 'react';
import { SEARCH_ORDERS, useYachtSearchOrder } from '../../yacht-search-order-context';
import { YachtSearchHeaderButton } from './YachtSearchHeaderButton';


const ANCHOR_ORIGIN: PopoverOrigin = {
	vertical: 'bottom',
	horizontal: 'left',
};

const ORDER_LABELS: Record<YachtSearchOrder, string> = {
	'-popularity': 'common:popularity',
	'-lowPrice': 'common:highest-price',
	'lowPrice': 'common:lowest-price',
	'-guestsSleeping': 'common:most-guests',
	'guestsSleeping': 'common:least-guests',
	'-lengthMeters': 'common:longest-length',
	'lengthMeters': 'common:shortest-length',
	'-yearLaunch': 'common:newest-yachts',
	'yearLaunch': 'common:oldest-yachts',
};

const OrderPopover = styled(Popover)(({ theme }) => css`
	.MuiPaper-root {
		padding: ${theme.spacing(1, 2)};
	}
`);

export const YachtSearchOrderControl = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();
	const { order, setOrder } = useYachtSearchOrder();

	const [ anchor, setAnchor ] = useState<HTMLElement | null>(null);

	const handleClick = useCallback<MouseEventHandler<HTMLElement>>(ev => {
		setAnchor(ev.currentTarget);
	}, []);

	const handleClose = useCallback(() => {
		setAnchor(null);
	}, []);

	const handleChange = useCallback((ev: unknown, value: string) => {
		setOrder(value as YachtSearchOrder);
		setAnchor(null);
	}, [setOrder]);

	const orderOptions = useMemo(() => SEARCH_ORDERS.map(order => (
		<FormControlLabel
			key={order}
			value={order}
			control={<Radio/>}
			label={t(ORDER_LABELS[order])}
		/>
	)), [t]);

	return (
		<>
			<YachtSearchHeaderButton
				className={className}
				icon={<SortIcon/>}
				text={t('common:sort-by')}
				onClick={handleClick}
			/>
			<OrderPopover
				open={!!anchor}
				anchorEl={anchor}
				anchorOrigin={ANCHOR_ORIGIN}
				onClose={handleClose}
			>
				<RadioGroup
					value={order}
					onChange={handleChange}
				>
					{ orderOptions }
				</RadioGroup>
			</OrderPopover>
		</>
	);
};
