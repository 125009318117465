import type { ClassNameProp } from '@charterindex/armoury-www';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


const EnquiryFormSubmitButtonRoot = styled(Button)`
	position: relative;
	.submit-spinner {
		position: absolute;
		display: grid;

		> * {
			margin: auto;
		}
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const EnquiryFormSubmitButton = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller, 'submitting');

	const { t } = useTranslation();

	return (
		<EnquiryFormSubmitButtonRoot
			className={className}
			type="submit"
			variant="contained"
			fullWidth
			startIcon={ <SendIcon className="enquirySendIcon"/> }
			disabled={controller.submitting}
		>
			{ t('common:enquiry.button.send') }

			{ controller.submitting && (
				<div className="submit-spinner">
					<CircularProgress
						color="info"
						size="2rem"
					/>
				</div>
			)}


		</EnquiryFormSubmitButtonRoot>
	);
};
