import type { HeaderLinks } from '../../../domain';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { css, styled } from '@mui/material/styles';
import { CILogo, InternalLink } from '../../components';
import { HeaderNav } from './nav';


const HeaderAppBar = styled(AppBar)(({ theme, color }) => css`
	top: 0;
	padding: ${theme.spacing(1, 'var(--page-pad-x)')};
	box-shadow: ${theme.shadows[ color === 'transparent' ? 0 : 1]};
	
	.toolbar {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 0;
	}
	
	.logo {
		display: flex;
		margin: auto auto auto 0;

		${theme.breakpoints.only('sm')} {	
			img {
				height: 20px;
				width: auto;
			}
		}
	}

	.nav {
		margin-left: auto;
	}
`);

const HeaderLogo = styled('div')(({ theme }) => css`
	.large {
		display: none;
	}


	${theme.breakpoints.up('sm')} {
		.small {
			display: none;
		}
		.large {
			display: unset;
		}
	}
`);

export type PageHeaderProps = {
	overHero: boolean;
	links?: HeaderLinks;
};
export const PageHeader = ({ overHero, links }: PageHeaderProps) => {
	return (
		<>
			<HeaderAppBar
				color={ overHero ? 'transparent' : undefined }
				position={ overHero ? 'absolute' : 'relative' }
			>
				<Toolbar className="toolbar">
					<InternalLink href="/">
						<HeaderLogo className="logo">
							<CILogo
								className="small"
								iconOnly
							/>
							<CILogo
								className="large"
							/>
						</HeaderLogo>
					</InternalLink>

					{ links && (
						<HeaderNav
							className="nav"
							links={links}
							overHero={overHero}
						/>
					)}

				</Toolbar>
			</HeaderAppBar>
		</>
	);
};
