import type { ChildrenProp } from '@charterindex/armoury-www';
import type { LinkProps } from 'next/link';
import type { YachtSearchFilter } from '../../domain';
import type { ClassNameProp } from '../util';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, useMemo } from 'react';
import { Query } from '../util';


export type InternalLinkProps = ClassNameProp & ChildrenProp & {
	/**
	 * URI we are linking to. Can include hard-coded URL search params.
	 */
	href: string;

	/**
	 * Define the URL search params for the link.
	 *
	 * These will be merged with the page's current search params; see
	 * {@link Query.merge} for details.
	 */
	query?: Query;
	preserveFilters?: boolean;
};

const cleanPathname = (pathname: string) => {
	if(pathname.startsWith('http') || pathname.startsWith('/')) {
		return pathname;
	}

	return '/' + pathname;
};


const FILTER_RESET: Record<keyof Omit<YachtSearchFilter, 'date'>, undefined> & { order: undefined } = {
	areas: undefined,
	subAreas: undefined,
	builder: undefined,
	category: undefined,
	guestsSleeping: undefined,
	hull: undefined,
	lengthMeters: undefined,
	lowPrice: undefined,
	place: undefined,
	yearLaunch: undefined,
	order: undefined,
};

/**
 * Wrapper around NextJS's `<Link>` component which preserves certain URL
 * parameters.
 *
 * This lets us preserve date filters across navigations, as well as Google
 * Analytics tracking ids, etc.
 */
export const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>(({ className, href, query, preserveFilters, children }, ref) => {
	const { query: previousQuery } = useRouter();

	const computedHref = useMemo<LinkProps['href']>(() => {
		const [ pathname, extra ] = Query.extractPathnameAndQuery(href);

		const baseQuery = preserveFilters ? {} : FILTER_RESET;
		const newQuery = Query.merge(previousQuery, { ...baseQuery, ...query }, extra);

		return {
			pathname: cleanPathname(pathname),
			query: newQuery,
		};

	}, [preserveFilters, previousQuery, query, href]);

	return (
		<Link className={className} ref={ref} href={computedHref} scroll>
			{ children }
		</Link>
	);
});
