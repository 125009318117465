import type { SearchFormFilterProps } from './SearchFormControl';
import useTranslation from 'next-translate/useTranslation';
import { useSuggest } from '../../../suggest';
import { SuggestInput } from '../../../suggest/components/SuggestInput';
import { SearchFormControl } from './SearchFormControl';
import { useYachtSearchFormQuery } from './search-form-query-context';



export const SearchFormSuggest = ({ id, className }: SearchFormFilterProps) => {
	const { t } = useTranslation();
	const { openSuggest } = useSuggest();
	const { place, builder } = useYachtSearchFormQuery();

	return (
		<SearchFormControl className={className} onClick={openSuggest}>
			<SuggestInput
				id={id + 'suggestInput'}
				builder={builder}
				place={place}
				label={t('common:search')}
				InputLabelProps={{ shrink: true }}
				variant="standard"
				placeholder={t('common:where-in-the-world')}
			/>
		</SearchFormControl>
	);
};
