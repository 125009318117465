import type { ClassNameProp } from '../../../util';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { usePreferences } from '../../../preferences';
import { useYacht } from '../../yacht-context';
import { EnquireForRate } from '../EnquireForRate';


export const YachtPrice = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();
	const yacht = useYacht();
	const { formatCurrency } = usePreferences();

	if(!yacht.active) {
		return (
			<div className={className}>
				<Typography variant="caption" noWrap>
					{t('common:private-yacht')}
				</Typography>
				<Typography variant="body1" noWrap>
					{t('common:unavailable')}
				</Typography>
			</div>
		);
	}

	if(!yacht.lowPrice) {
		return <EnquireForRate className={className}/>;
	}

	return (
		<div className={className}>
			<Typography variant="caption" lineHeight="1" component="div">
				{t('common:from')}
			</Typography>
			<Typography variant="h4" component="span">
				{formatCurrency(yacht.lowPrice)}
			</Typography>
			<Typography variant="body2" component="span">
				{t('common:per-week')}
			</Typography>
		</div>
	);
};
