export const headAndTail = <T>(arr: T[]): [ head: T, tail: T ] => {
	if(!arr.length) {
		throw new RangeError('Cannot get headAndTail of empty array.');
	}

	return [
		arr[0],
		arr[arr.length - 1],
	];
};

export const uniqueArray = <T>(arr: T[]): T[] => [ ...new Set(arr) ];

export const nearestNumberInArray = (target: number, arr: number[]): number => (
	arr.reduce((nearest, value) => (
		(Math.abs(value - target) < Math.abs(nearest - target) ? value : nearest)
	))
);

export const nearestIndexInArray = (target: number, arr: number[]): number => (
	arr.indexOf(nearestNumberInArray(target, arr))
);
