import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { Analytics } from '../../analytics';
import { FloatingControl } from '../../components';
import { PAGE_ID } from '../../util';


const ScrollTopFab = styled(Fab)(({ theme }) => css`
	background-color: rgba(0, 0, 0, .6);
	color: white;

	box-shadow: ${theme.shadows[2]};

	svg {
		box-shadow: none;
	}
	
	&:hover {
		background-color: rgba(0, 0, 0, 1);
	}
`);

export const ScrollTopButton = () => {
	const { t } = useTranslation();

	const handleClick = () => {
		Analytics.interaction({ target: 'Scroll-Top Button', action: 'click' });

		document.getElementById(PAGE_ID)?.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<FloatingControl x="right" y="bottom" >
			<ScrollTopFab
				onClick={handleClick}
				aria-label={t('common:scroll-top')}
				size="small"
			>
				<UpIcon fontSize="inherit" />
			</ScrollTopFab>
		</FloatingControl>
	);
};
