
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { DefaultSeoProps } from 'next-seo';
import type { AppProps } from 'next/dist/shared/lib/router/router';
import { CharterIcon } from '@charterindex/icons';
import svgUrl from '@charterindex/icons/icons.svg';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { DefaultSeo } from 'next-seo';
import React from 'react';
import { ApiProvider } from '../client/api';
import { CookieConsent, CookieModalProvider, CookieProvider } from '../client/cookie';
import { PageVisibilityControllerProvider } from '../client/page/page-visibility-controller-context';
import { PreferencesProvider } from '../client/preferences';
import { theme } from '../client/theme';
// Suppress useLayoutEffect during SSR.
// https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
if (typeof window === 'undefined') {
    React.useLayoutEffect = () => { };
}
const DEFAULT_SEO_PROPS: DefaultSeoProps = {
    titleTemplate: '%s - Charter Index',
    twitter: {
        cardType: 'summary_large_image',
        site: '@charterindex',
        handle: '@charterindex',
    },
    openGraph: {
        site_name: 'Charter Index',
        type: 'website',
        profile: {
            username: 'charterindex',
        },
    },
};
CharterIcon.init(svgUrl.src);
LicenseInfo.setLicenseKey('1a386d46b1a6433c61065edaa190014fTz05NzM1OSxFPTE3NTY4OTcwMjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==');
const BroadsideApp = ({ Component, pageProps }: AppProps) => (<ThemeProvider theme={theme}>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<CssBaseline />
			<DefaultSeo {...DEFAULT_SEO_PROPS}/>
			<CookieProvider cookie={pageProps.cookie}>
				<PreferencesProvider>
					<ApiProvider>
						<CookieModalProvider>
							<PageVisibilityControllerProvider>
								<Component {...pageProps}/>
							</PageVisibilityControllerProvider>
							<CookieConsent />
						</CookieModalProvider>
					</ApiProvider>
				</PreferencesProvider>
			</CookieProvider>
		</LocalizationProvider>
	</ThemeProvider>);
const __Next_Translate__Page__192b368d5b7__ = BroadsideApp;

    export default __appWithI18n(__Next_Translate__Page__192b368d5b7__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  