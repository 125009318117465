import type { ChildrenProp } from '@charterindex/armoury-www';
import type { EnquiryControllerConfig } from './enquiry-controller';
import { NullContextError } from '@charterindex/armoury-www/dist/jsx';
import useTranslation from 'next-translate/useTranslation';
import { createContext, useContext, useEffect, useState } from 'react';
import { asLanguage } from '../../domain';
import { useApi } from '../api';
import { usePreferences } from '../preferences';
import { useYachtSearchFilter } from '../search';
import { EnquiryDialog } from './components';
import { EnquiryController } from './enquiry-controller';


const enquiryControllerContext = createContext<EnquiryController | null>(null);

export type EnquiryControllerProviderProps = Omit<EnquiryControllerConfig, 'currency' | 'locale'> & ChildrenProp;
export const EnquiryControllerProvider = ({ children, ...config }: EnquiryControllerProviderProps) => {
	const api = useApi();
	const { filter } = useYachtSearchFilter();
	const { currency } = usePreferences();
	const { lang } = useTranslation();

	const [ controller ] = useState(() => new EnquiryController({
		...config,
		currency,
		locale: asLanguage(lang),
	}, { api, filter }));

	// Update the controller when the yacht's price changes due to currency change.
	useEffect(() => {
		controller.handleCurrencyChange(config.yacht?.lowPrice, currency);
	}, [config.yacht?.lowPrice, controller, currency]);

	useEffect(() => {
		controller.handleLocalChange(asLanguage(lang));
	}, [controller, lang]);

	return (
		<enquiryControllerContext.Provider value={controller}>
			{ children }
			<EnquiryDialog/>
		</enquiryControllerContext.Provider>
	);
};


export const useEnquiryController = (): EnquiryController => {
	const ctx = useContext(enquiryControllerContext);
	if(!ctx) {
		throw new NullContextError('enquiryControllerContext', 'EnquiryControllerProvider');
	}

	return ctx;
};
