import type { ClassNameProp } from '../../util';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


const SliceTitleTypography = styled(Typography)(({ theme }) => css`
	padding-bottom: calc(var(--page-pad-y) / 4);
	margin-bottom: calc(var(--page-pad-y) / 2);
	border-bottom: 1px solid ${theme.palette.grey[200]};

	.slices--side & {
		font-size: 1.2rem;
	}
`);

export type SliceTitleProps = ClassNameProp & {
	slice?: { title?: string };
	title?: string | JSX.Element;
};
export const SliceTitle = ({ slice, title, className }: SliceTitleProps) => {
	const titleToRender = slice?.title ?? title;

	if(!titleToRender) {
		return null;
	}
	return <SliceTitleTypography className={className} variant="h2">{ titleToRender }</SliceTitleTypography>;
};
