import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { VisuallyHiddenLabel } from '../../components';
import { useYachtSearchFilter } from '../../search';
import { useSuggest } from '../suggest-context';
import { SuggestDefault } from './SuggestDefault';
import { SuggestInput } from './SuggestInput';
import { SuggestResultList } from './SuggestResultList';
import { SUGGEST_RESULT_SIZE } from './suggest-result-size';


const SuggestDrawerRoot = styled(Drawer)(({ theme }) => css`
	${theme.pagePaddingVars(theme)};

	> .MuiPaper-root {
		display: grid;
		grid-template-areas: 'input' 'default' 'results';
		grid-template-rows: auto auto 1fr;
		
		height: 100vh;

		.suggest-drawer__header {
			grid-area: input;
			
			display: grid;
			grid-template-columns: ${SUGGEST_RESULT_SIZE} 1fr;

			padding: var(--page-pad-x);
			z-index: 1;
		}

		.suggest-drawer__default {
			grid-area: default;
			padding: 0 var(--page-pad-x);
		}

		.suggest-drawer__results {
			grid-area: results;
			overflow-y: auto;
		}
	}
`);

const SUGGEST_DRAW_INPUT_ID = 'suggestDrawerInput';

export type SuggestDrawerProps = { open: boolean, onClose: () => void };
export const SuggestDrawer = ({ open, onClose }: SuggestDrawerProps) => {
	const { t } = useTranslation();
	const { closeSuggest } = useSuggest();

	const { filter: { builder, place } } = useYachtSearchFilter();

	return (
		<SuggestDrawerRoot
			open={open}
			onClose={onClose}
			anchor="bottom"
		>
			<VisuallyHiddenLabel htmlFor={SUGGEST_DRAW_INPUT_ID} label={t('common:search')}/>
			<header className="suggest-drawer__header">
				<ButtonBase
					onClick={closeSuggest}
					title={t('common:close')}
				>
					<ArrowBackIcon fontSize="large"/>
				</ButtonBase>
				<SuggestInput
					id={SUGGEST_DRAW_INPUT_ID}
					builder={builder}
					place={place}
					autoFocus
					noOpen
					clearable
				/>
			</header>
			<SuggestDefault className="suggest-drawer__default"/>
			<SuggestResultList className="suggest-drawer__results"/>
		</SuggestDrawerRoot>
	);
};
