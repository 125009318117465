import type { Range, YachtSearchFilter } from '../../domain';
import type { Query } from '../util';
import type { ClientYachtSearchFilter, FilterValue } from './yacht-search-filter-util';
import { isCategoryId, isHullId } from '../../domain';
import { isNullish, omitNullish } from '../../util';
import { isSsr } from '../util';
import { FILTER_KEYS, isFilterKey } from './yacht-search-filter-util';


const encodeValue = (v: FilterValue): string | null => {
	if(!v) {
		return null;
	}

	if(!Array.isArray(v)) {
		return v.toString();
	}

	return v.join(',');
};

const encodeDate = (v: Range<Date> | undefined): string | null => {
	if(!v) {
		return null;
	}

	if(!Array.isArray(v)) {
		return v.toISOString();
	}

	return [ v[0].toISOString(), v[1]?.toISOString() ].join(',');
};

const NULL_FILTER: Readonly<Record<keyof YachtSearchFilter, null>> = {
	areas: null,
	subAreas: null,
	place: null,
	builder: null,
	hull: null,
	category: null,
	date: null,
	guestsSleeping: null,
	lowPrice: null,
	lengthMeters: null,
	yearLaunch: null,
};

/**
 * Construct URL query params from a filter.
 */
export const filterToQuery = (filter: ClientYachtSearchFilter): Query.ParsedUrlQueryInput => {
	const input: Query.ParsedUrlQueryInput = { ...NULL_FILTER }; // Erase filters which are removed.
	FILTER_KEYS.forEach(key => {
		switch(key) {
			case 'date': {
				const encoded = encodeDate(filter[key]);
				if(encoded) {
					input[key] = encoded;
				}
				break;
			}
			default: {
				const encoded = encodeValue(filter[key]);
				if(encoded) {
					input[key] = encoded;
				}
			}
		}
	});

	return input;
};

const parseRangeString = (input: string): Range<string> | null => {
	if(input.includes(',')) {
		const values = input.split(',');
		if(values.length !== 2) {
			return null;
		}

		return values as Range<string>;
	}

	return input;
};

const parseRangeNumber = (input: string): Range<number> | null => {
	const strRange = parseRangeString(input);
	if(Array.isArray(strRange)) {
		const numValues = strRange.map(v => parseInt(v, 10));
		if(numValues.some(Number.isNaN)) {
			return null;
		}

		return numValues as Range<number>;
	}

	const numVal = parseInt(input, 10);
	if(Number.isNaN(numVal)) {
		return null;
	}

	return numVal;
};

const parseSingleDate = (input: string): Date | null => {
	try {
		const date = new Date(input);
		date.toISOString(); // This will throw for an invalid date.
		return date;
	} catch {
		return null;
	}
};

const parseRangeDate = (input: string): Range<Date> | null => {
	const strRange = parseRangeString(input);
	if(Array.isArray(strRange)) {
		const dateValues = strRange.map(parseSingleDate);
		if(dateValues.some(isNullish)) {
			return null;
		}
		return dateValues as Range<Date>;
	}

	return parseSingleDate(input);
};

/**
 * Reconstruct the YachtSearchFilter from URL search params.
 *
 * If no `search` argument is passed, the filter will be empty during SSR, and
 * the filter will be created client-side only using `window.location.search`.
 *
 * If we need the filter values during SSR, pass the `search` into this method.
 *
 * @param search if provided, will be used in place of `window.location.search`.
 *  Required if we need to reconstruct the filter during SSR.
 */
export const queryToFilter = (search?: string | null | undefined): ClientYachtSearchFilter => {
	// If SSR, `search` is required.
	if(isSsr() && !search) {
		return {};
	}

	const filter: ClientYachtSearchFilter = {};
	// Safe to use `window` here, as we will always have `search` during SSR.
	const params = new URLSearchParams(search ?? window.location.search);

	params.forEach((v, k) => {
		if(!isFilterKey(k) || Array.isArray(v)) {
			return;
		}

		switch(k) {
			// Strings
			case 'place':
			case 'builder': {
				filter[k] = v;
			} break;

			// Enumerations
			case 'category':
				filter[k] = isCategoryId(v) ? v : undefined;
				break;
			case 'hull':
				filter[k] = isHullId(v) ? v : undefined;
				break;

			// String range
			case 'date': {
				const range = parseRangeDate(v);
				if(range) {
					filter[k] = range;
				}
			} break;

			// Number range
			case 'guestsSleeping':
			case 'lowPrice':
			case 'lengthMeters':
			case 'yearLaunch': {
				const range = parseRangeNumber(v);
				if(range) {
					filter[k] = range;
				}
			} break;
		}
	});

	return omitNullish(filter);
};

