import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import useTranslation from 'next-translate/useTranslation';
import { StaticArticleModal } from '../../../components';
import { useEnquiryController } from '../../enquiry-controller-context';


export type TermsAndConditionsModalProps = {
	open: boolean;
	onClose: () => void;
};
export const TermsAndConditionsModal = ({ open, onClose }: TermsAndConditionsModalProps) => {
	const controller = useEnquiryController();
	const { t } = useTranslation();

	const handleClick = () => {
		controller.model.terms = true;
		onClose();
	};

	return (
		<StaticArticleModal articleName="terms-and-conditions" open={open} onClose={onClose}>
			<Box width="100%" display="flex" justifyContent="space-between" gap={2}>
				<Button
					color="inherit"
					variant="text"
					onClick={onClose}
				>
					{ t('common:close') }
				</Button>

				<Button
					variant="contained"
					color="secondary"
					onClick={handleClick}
				>
					{ t('common:accept') }
				</Button>
			</Box>
		</StaticArticleModal>
	);
};
