module.exports = {
  locales: ['en', 'en-GB', 'fr', 'de', 'es', 'pt', 'it', 'ru', 'ja', 'zh'],
  defaultLocale: 'en',
  pages: {
    '*': ['common'],
  },
	loadLocaleFrom: (lang, ns) => {
		return Promise.resolve(require(`../../locales/${lang}/${ns}`))	
	},
	logBuild: false,
}
