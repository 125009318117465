export const asError = (maybeError: unknown): Error => (
	maybeError instanceof Error ? maybeError : new Error(`(non-Error) ${maybeError}`)
);

/**
 * Check if `e` is an `Error` thrown as a result of an `AbortController` signal.
 */
export const isAbortError = (e: unknown): boolean => (
	e instanceof Error && e.name === 'AbortError'
);
