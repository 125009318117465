import type { ClassNameProp } from '@charterindex/armoury-www';
import type { ChangeEvent } from 'react';
import type { EnquiryYachtType } from '../../../../domain';
import { CharterIcon } from '@charterindex/icons';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


type YachtTypeOption = {
	translationKey: string;
	value: EnquiryYachtType | null;
};

const YACHT_TYPE_OPTIONS: readonly YachtTypeOption[] = [
	{ translationKey: 'common:motor-yachts', value: 'Motor' },
	{ translationKey: 'common:sailing-yachts', value: 'Sailing' },
];

export const EnquiryFormYachtTypeField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'yachtType');
	useSubject(controller, 'submitting');

	const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
		controller.model.yachtType = (ev.target.value as EnquiryYachtType | '') || undefined;
	};

	const { t } = useTranslation();

	return (
		<TextField
			className={className}
			select
			size="small"
			label={ t('common:enquiry.yacht-type.label') }
			value={ controller.model.yachtType ?? '' }
			onChange={ handleChange }
			disabled={ controller.submitting }
			InputProps={{
				startAdornment: (
					<CharterIcon
						iconName={ controller.model.yachtType === 'Sailing' ? 'sailingYacht' : 'motorYacht' }
						size={24}
					/>
				),
			}}
		>
			{ YACHT_TYPE_OPTIONS.map(o => (
				<MenuItem
					value={o.value ?? ''}
					key={o.value ?? 'none'}
					sx={{ display: 'flex', alignItems: 'center' }}
				>
					{ t(o.translationKey) }
				</MenuItem>
			))}
		</TextField>
	);
};
