import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { StaticArticleModal } from '../components';
import { useCookie } from './cookie-context';


export type CookieModalProps = {
	open: boolean;
	onClose: () => void;
};
export const CookieModal = ({ open, onClose }: CookieModalProps) => {
	const { t } = useTranslation();
	const { setCookieConsent } = useCookie();

	const acceptAll = useCallback(() => {
		setCookieConsent('accepted');
		onClose();
	}, [onClose, setCookieConsent]);

	const rejectNonEssential = useCallback(() => {
		setCookieConsent('rejected');
		onClose();
	}, [onClose, setCookieConsent]);

	if(!open) {
		return null;
	}

	return (
		<StaticArticleModal
			articleName="cookie-policy"
			open={open}
			onClose={onClose}
		>
			<Button
				onClick={rejectNonEssential}
				variant="outlined"
				color="inherit"
			>
				<Typography>{t('common:cookie.reject-non-essential')}</Typography>
			</Button>
			<Button
				onClick={acceptAll}
				variant="contained"
				color="secondary"
			>
				<Typography>{t('common:cookie.accept-all')}</Typography>
			</Button>
		</StaticArticleModal>
	);
};
