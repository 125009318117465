import type { HeroActionContentComponent } from './HeroAction';
import Box from '@mui/material/Box';
import { SearchForm } from '../../../search';


export const HeroActionSearch: HeroActionContentComponent = ({ className, sticky }) => {
	return (
		<Box className={className} margin="auto">
			<SearchForm
				id={'heroCtaSearch' + sticky ? 'Sticky' : ''}
				elevate={!sticky}
				transparent={!sticky}
			/>
		</Box>
	);
};
