import type { FC } from 'react';
import type { Yacht } from '../../domain';
import { createContext, useContext } from 'react';


const yachtContext = createContext<Yacht | null>(null);

export type YachtProviderProps = {
	yacht: Yacht;
};
export const YachtProvider: FC<YachtProviderProps> = ({ yacht, children }) => (
	<yachtContext.Provider value={yacht}>
		{ children }
	</yachtContext.Provider>
);

export const useYacht = (): Yacht => {
	const ctx = useContext(yachtContext);
	if(!ctx) {
		throw new Error('yachtContext was null.');
	}

	return ctx;
};
