import type { Currency } from '../domain';


const IN_TO_M = 0.0254;
const FT_TO_M = 0.3048;
const NM_TO_KM = 1.8520;
const USG_TO_L = 3.78541;

export function floatConversion(value: number, ratio: number, decimalPlaces =0): number {
	decimalPlaces = decimalPlaces || 0;
	const decimalMultiplier = Math.pow(10,decimalPlaces);
	return Math.round(decimalMultiplier*value*ratio)/decimalMultiplier;
}

export function toMeters(feet: number): number {
	return floatConversion(feet, FT_TO_M, 1);
}

export function toFeet(meters: number): number {
	return Math.round(meters/FT_TO_M);
}

export function toFeetInches(meters: number): [ number, number ] {
	const inches = Math.round(meters/IN_TO_M);
	return [ Math.floor(inches/12), inches%12 ];
}

export function toNauticalMiles(kilometers: number): number {
	return floatConversion(kilometers, 1/NM_TO_KM, 1);
}

export function toUSGallons(litres: number): number {
	return floatConversion(litres, 1/USG_TO_L, 1);
}

export const defaultCurrencyForLanguage = (lang: string | undefined): Currency => {
	switch (lang) {
		case 'fr':
		case 'de':
		case 'it':
		case 'pt':
		case 'es':
			return 'EUR';
		case 'en-GB':
			return 'GBP';
		default:
			return 'USD';
	}
};
