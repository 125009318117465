/**
 * Set whether GTM has permission to set & read cookies.
 *
 * https://developers.google.com/tag-platform/devguides/consent
 */
export const setGtmConsent = (granted: boolean): void => {
	const state = granted ? 'granted' : 'denied';
	gtag('consent', 'update', {
		ad_storage: state,
		analytics_storage: state,
	});
};
