import type { Components } from '@mui/material/styles';
import { css } from '@mui/material/styles';


export const MuiAccordion: Components['MuiAccordion'] = {
	styleOverrides: {
		root: css`
			&.Mui-expanded {
				margin-top: 0 !important;
				margin-bottom: 0 !important;
			}

			h3 {
				margin: 0;
			}
		`,
	},
};


export const MuiAccordionSummary: Components['MuiAccordionSummary'] = {
	styleOverrides: {
		root: css`
			padding: 16px !important;
			min-height: 0 !important;

			& > * {
				margin: 0 !important;
			}
		`,
	},
};

export const MuiAccordionDetails: Components['MuiAccordionDetails'] = {
	styleOverrides: {
		root: css`padding-top: 0 !important;`,
	},
};
