import type { ChildrenProp } from '@charterindex/armoury-www';
import type { Article, Enquiry, EnquiryResult, RelatedYachts, SuggestionsData, SuggestionType, YachtsData, YachtSearchQuery } from '../../domain';
import type { StaticArticleName } from '../../util/static-article';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { createContext, useContext, useState } from 'react';
import { asLanguage } from '../../domain';
import { usePreferences } from '../preferences';
import { ApiService } from './api-service';


export type ApiClient = Readonly<{
	search: (query: YachtSearchQuery, abort?: AbortController) => Promise<YachtsData>;
	suggest: (q: string, types?: readonly SuggestionType[] | undefined | null, abort?: AbortController) => Promise<SuggestionsData>;
	enquire: (enquiry: Enquiry) => Promise<EnquiryResult>
	relatedYachts: (uri: string) => Promise<RelatedYachts[] | null>;
	staticArticle: (name: StaticArticleName) => Promise<Article | null>;
}>;


const apiContext = createContext<ApiClient | null>(null);

export const ApiProvider = ({ children }: ChildrenProp) => {
	const { lang } = useTranslation();
	const { currency } = usePreferences();

	const [ apiService ] = useState(new ApiService(asLanguage(lang), currency));
	apiService.setLanguage(asLanguage(lang));
	apiService.setCurrency(currency);

	useSubject(apiService, 'error');
	if(apiService.error) {
		throw apiService.error;
	}

	return (
		<apiContext.Provider value={apiService}>
			{ children }
		</apiContext.Provider>
	);
};

export const useApi = (): ApiClient => {
	const ctx = useContext(apiContext);
	if(!ctx) {
		throw new Error('apiContext was null; missing <ApiProvider>?');
	}

	return ctx;
};
