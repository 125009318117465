import type { FooterLinks, Language } from '../../../domain';
import type { ClassNameProp } from '../../util';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { forwardRef } from 'react';
import { CILogo, InternalLink } from '../../components';
import { useCookieModal } from '../../cookie';
import { PreferenceControls } from '../../preferences/components/PreferenceControls';
import { FACEBOOK_LOGO_SVG, INSTAGRAM_LOGO_SVG, LINKEDIN_LOGO_SVG, TWITTER_LOGO_SVG, VIMEO_LOGO_SVG, YOUTUBE_LOGO_SVG } from '../../static';
import { FOOTER_ID } from '../../util';


const FOOTER_SOCIAL_LINKS = [
	{
		href: 'https://www.instagram.com/charterindex/',
		icon: INSTAGRAM_LOGO_SVG,
		label: 'Instagram',
	},
	{
		href: 'https://www.youtube.com/channel/UCZ3-z2fhFAXIm3DwJV_wSWA/',
		icon: YOUTUBE_LOGO_SVG,
		label: 'YouTube',
	},
	{
		href: 'https://twitter.com/charterindex',
		icon: TWITTER_LOGO_SVG,
		label: 'Twitter',
	},
	{
		href: 'https://facebook.com/charterindex',
		icon: FACEBOOK_LOGO_SVG,
		label: 'Facebook',
	},
	{
		href: 'https://www.linkedin.com/company/charter-index',
		icon: LINKEDIN_LOGO_SVG,
		label: 'LinkedIn',
	},
	{
		href: 'https://vimeo.com/charterindex',
		icon: VIMEO_LOGO_SVG,
		label: 'Vimeo',
	},
];

const FooterRoot = styled('footer')(({ theme }) => css`
	a {
		color: inherit;
	}

	background-color: ${theme.palette.secondary.main};
	color: ${theme.palette.secondary.contrastText};

	padding: var(--page-pad-y) var(--page-pad-x);

	.footer-layout {
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(4)};
		margin: 0 auto;

		// Space for the scroll-top button.
		padding-bottom: ${theme.spacing(5)};

		background: none;

		${theme.breakpoints.up('md')} {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: repeat(3, auto);
			grid-template-areas:
				'text      text      links     links   social  social'
				'divider   divider   divider   divider divider divider'
				'copyright copyright copyright legal   legal   legal'
			;

			padding-bottom: unset;
		}

		&__text {
			grid-area: text;
		}
		&__links {
			grid-area: links;
			max-width: 500px;
			margin: 0 auto;
		}
		&__social {
			grid-area: social;
			max-width: 700px;
			margin: 0 auto;
		}
		&__divider {
			grid-area: divider;
			display: grid;
			grid-template-columns: 1fr auto 1fr;
			gap: ${theme.spacing(1.5)};

			> * {
				margin: auto 0;
			}

			img {
				transform: translateY(-3px);
			}

			hr {
				background-color: ${theme.palette.common.white};
			}
		}
		&__copyright {
			grid-area: copyright;
			justify-content: space-between;
		}
		&__legal {
			grid-area: legal;
			align-items: center;

			ul {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				list-style: none;
				text-align: center;
				column-gap: ${theme.spacing(2)};
				padding: 0;
				margin: 0;
				margin-top: ${theme.spacing(2)};

				li {
					margin-bottom: auto;
					text-align: center;
					width: 100%;

					min-height: 2rem;

					&, * {
						line-height: .7;
					}
					
					button {
						margin: 0;
						padding: 0;
					}
				}
			}

			${theme.breakpoints.up('md')} {
				align-items: flex-end;

				ul {
					flex-direction: row;
					margin-top: 0;

				}
			}
		}

		section {
			display: flex;
			flex-direction: column;

			h2 {
				margin: 0 auto;
				font-size: 1.5rem;
			}
		}

		.footer-logo {
			height: 20px !important;
			margin-top: 9px;
			margin-bottom: 11px;
	
			${theme.breakpoints.up('md')} {
				margin-right: 'auto';
			}
		}

		.fine-print {
			font-size: .6rem;
		}
	}

	.MuiTypography-body1 {
		font-size: .8em;
	}
`);

const LinkGrid = styled('ul')(({ theme }) => css`
	display: grid;
	list-style: none;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 5vw;
	row-gap: ${theme.spacing(1.5)};
	padding: 0;

	li {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: ${theme.spacing(1)};
		height: auto;

		svg {
			width: 1.5em;
			height: 1.5em;
		}

		${theme.breakpoints.up('md')} {
			justify-content: flex-start;
		}
	}
`);

export type FooterProps = ClassNameProp & {
	links: FooterLinks | undefined;
	alternativeUris: Record<Language, string> | undefined;
};
export const Footer = forwardRef<HTMLDivElement, FooterProps>(({ links, alternativeUris, className }, ref) => {
	const { t } = useTranslation();
	const { openCookieModal } = useCookieModal();

	if(!links) {
		return null;
	}

	const { pages, legal } = links;

	return (
		<FooterRoot id={FOOTER_ID} className={className} ref={ref}>
			<div className="footer-layout">
				<section className="footer-layout__text">
					<CILogo className="footer-logo" mode="white" />
					<Typography>
						{t('common:footer-text')}
					</Typography>
				</section>

				<section className="footer-layout__links">
					<Typography variant="h2">
						{t('common:more-from-charter-index')}
					</Typography>
					<LinkGrid>
						{pages.map(({ title, uri }) => (
							<li key={uri}>
								<Typography component={InternalLink} href={uri}>{title}</Typography>
							</li>
						))}
					</LinkGrid>
				</section>

				<section className="footer-layout__social">
					<Typography variant="h2">
						{t('common:follow-us-on')}
					</Typography>
					<LinkGrid>
						{FOOTER_SOCIAL_LINKS.map(({ href, icon, label }) => (
							<li key={href}>
								<img src={icon} width={24} height={24} loading="lazy" alt=""/>
								<a href={href} rel="noopener noreferrer me" target="_blank">
									<Typography>{label}</Typography>
								</a>
							</li>
						))}
					</LinkGrid>
				</section>

				<div className="footer-layout__divider" aria-hidden>
					<Divider />
					<CILogo iconOnly />
					<Divider />
				</div>

				<section className="footer-layout__legal">
					{ alternativeUris && <PreferenceControls id="footerPreferences" alternativeUris={alternativeUris}/>}
					<ul>
						{legal.map(({ title, uri }) => (
							<li key={uri}>
								<Typography component={InternalLink} href={uri} variant="caption">{title}</Typography>
							</li>
						))}
						<li>
							<Button
								variant="text"
								color="inherit"
								onClick={openCookieModal}
								size="small"
							>
								<Typography variant="caption">
									{t('common:cookie.manage-cookies')}
								</Typography>
							</Button>
						</li>
					</ul>
				</section>

				<section className="footer-layout__copyright">
					<Typography>{t('common:copyright.charter-index')}</Typography>
					<div>
						<Typography className="fine-print">
							{t('common:copyright.respective-owners')}
						</Typography>
						<Typography className="fine-print">
							{t('common:copyright.usage')}
						</Typography>
					</div>

				</section>
			</div>
		</FooterRoot>
	);
});
