import { Script } from '../components';


export const GtmHead = () => (
	<>
		<Script js={`
			window.dataLayer = window.dataLayer || [];
			function gtag() { dataLayer.push(arguments); }
			
			gtag('consent', 'default', {
				ad_storage: 'denied',
				analytics_storage: 'denied',
			})`
		}/>

		<Script js={`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.GTM_AUTH}&gtm_preview=${process.env.GTM_PREVIEW}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-N5TSKM6');`}/>
	</>
);

export const GtmNoScript = () => (
	<noscript dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N5TSKM6&gtm_auth=${process.env.GTM_AUTH}&gtm_preview=${process.env.GTM_PREVIEW}&gtm_cookies_win=x"
	height="0" width="0" style="display:none;visibility:hidden"></iframe>` }}/>
);
