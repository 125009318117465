import type { PopperProps } from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { css, styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSuggest } from '../suggest-context';
import { SuggestDefault } from './SuggestDefault';
import { SuggestResultList } from './SuggestResultList';


const SuggestPopupRoot = styled(Paper)(({ theme }) => css`
	${theme.pagePaddingVars(theme)};

	display: grid;
	grid-template-areas: 'default' 'results';
	grid-template-rows: auto 1fr;

	max-height: min(500px, 40vh);
	width: 450px;
	max-width: 450px;
	overflow-y: scroll;

	.suggest-popup__default {
		grid-area: default;
		padding: ${theme.spacing(1)};
	}
	.suggest-popup__results {
		grid-area: results;
	}
`);

const getPaperStyle = (anchorEl: PopperProps['anchorEl']) => {
	if(!anchorEl) {
		return {};
	}

	const el = typeof(anchorEl) === 'function' ? anchorEl() : anchorEl;

	return {
		minWidth: el.getBoundingClientRect().width,
	};
};

export type SuggestPopupProps = Pick<PopperProps, 'anchorEl'> & {
	open: boolean;
};
export const SuggestPopup = ({ anchorEl, open: inputFocus }: SuggestPopupProps) => {
	const { drawerMode } = useSuggest();

	const [ style, setStyle ] = useState(getPaperStyle(anchorEl));
	useEffect(() => {
		const handleResize = () => setStyle(getPaperStyle(anchorEl));

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => { window.removeEventListener('resize', handleResize); };
	}, [anchorEl]);

	if(drawerMode) {
		return null;
	}

	return (
		<Popper
			open={inputFocus}
			anchorEl={anchorEl}
			transition
		>
			{({ TransitionProps }) => (
				<Grow {...TransitionProps} style={{ transformOrigin: '50% 0 0' }}>
					<SuggestPopupRoot style={style}>
						<SuggestDefault className="suggest-popup__default"/>
						<SuggestResultList className="suggest-popup__results"/>
					</SuggestPopupRoot>
				</Grow>
			)}
		</Popper>
	);
};
