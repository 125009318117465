import type { YachtSearchFilter, YachtSearchQuery } from '../../domain';
import StaticRouter from 'next/router';
import { omitNullish } from '../../util';


export type Query = Partial<YachtSearchFilter> & Partial<{ sort: YachtSearchQuery['sort'] }>;
export namespace Query {
	/** Type of `query` object passed in to NextJS's `<Link>` component. */
	export type ParsedUrlQueryInput = Record<string, string | number | boolean | ReadonlyArray<string> | ReadonlyArray<number> | ReadonlyArray<boolean> | null>;
	export type ParsedUrlQuery = Record<string, string | string[]>;

	/** All query keys we manage manually in the application. */
	export type Key = keyof Query;
	type NullableQuery = Partial<Record<Key, Query[Key] | null>>;

	/**
	 * Keys which should never be included in a merged query.
	 */
	const NEVER_MERGE_KEYS: Readonly<string>[] = [ 'segments' ];

	/**
	 * Cleanly merge an existing query with a new one, accounting for mutually
	 * exclusive keys and keys we do not want to persist.
	 *
	 * Pass in a `null` | `undefined` value to explicitly delete an existing param.
	 *
	 * @param previousQuery The existing URL query; e.g. as provided by the NextJS router.
	 * @param query The new query being merged in.
	 * @param extra Extra params which will override `previousQuery` & `query`,
	 * 	e.g. those coming from a hard-coded href.
	 *
	 * @returns a merged `query` object, suitable for passing into NextJS's
	 * 	`<Link>` component.
	 */
	export const merge = (previousQuery: Record<string, unknown>, query: Query = {}, extra: Record<string, string> = {}): ParsedUrlQueryInput => {
		const queryAndExtra: NullableQuery = {
			...query,
			...extra,
		};

		const newQuery: NullableQuery = {
			...previousQuery,
			...queryAndExtra,
		};

		NEVER_MERGE_KEYS.forEach(key => newQuery[key as Key] = null);

		return omitNullish(newQuery);
	};

	/**
	 * Split out any search params present in `href` into a base `pathname` &
	 * `query` record.
	 */
	export const extractPathnameAndQuery = (href: string): [ pathname: string, query: Record<string, string> ] => {
		if(!href.includes('?')) {
			return [ href, {} ];
		}

		const [ pathname, queryString ] = href.split('?');
		const query: Record<string, string> = {};
		new URLSearchParams(queryString).forEach((v, k) => query[k] = v);

		return [ pathname, query ];
	};

	/**
	 * Merge passed `newQuery` with the current Router's query, and shallow
	 * route to it.
	 */
	export const shallowRoute = (newQuery: Query.ParsedUrlQueryInput): void => {
		setTimeout(() => {
			StaticRouter.replace({
				pathname: StaticRouter.pathname,
				query: omitNullish({ ...StaticRouter.query, ...newQuery }),
				hash: window.location.hash,
			}, undefined, { shallow: true })
				.catch(() => { /**/ });
		});
	};
}
