import type { ButtonProps } from '@mui/material/Button';
import type { ButtonGroupProps } from '@mui/material/ButtonGroup';
import type { Theme } from '@mui/material/styles';
import type { ReactElement } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useMemo } from 'react';


export type CIButtonGroupProps = {
	className?: string;
	options: string[];
	value: string;
	onChange: (newValue: string) => void;
	orientation?: ButtonGroupProps['orientation'];
	color?: ButtonProps['color'];
	getOptionLabel?: (option: string) => string;
	fullWidth?: boolean;
	evenSpacing?: boolean;
	disabled?: boolean;
};

const getOptionLabelDefault: CIButtonGroupProps['getOptionLabel'] = option => option;

const getButtonBorderColor = (theme: Theme, color: ButtonProps['color']) => {
	switch(color) {
		case undefined:
		case 'inherit':
			return theme.palette.secondary.main;
		default:
			return theme.palette[color].main;
	}
};

const CIButtonGroupRoot = styled(ButtonGroup)(({ theme, color }) => css`
	&.full-width {
		width: 100%;
		& > * {
			flex-grow: 1;
		}
	}

	.MuiButton-root {
		padding: ${theme.spacing(1, 0)};
		border-color: ${getButtonBorderColor(theme, color)} !important;
	}
`);

export const CIButtonGroup = ({
	className,
	value,
	onChange,
	options,
	orientation = 'horizontal',
	color = 'secondary',
	fullWidth,
	getOptionLabel = getOptionLabelDefault,
	evenSpacing,
	disabled,
}: CIButtonGroupProps): ReactElement => {

	const style = useMemo(() => {
		const width = evenSpacing ? `${100 / options.length}%` : undefined;
		return {
			minWidth: width,
			width,
			maxWidth: width,
		};
	}, [evenSpacing, options.length]);

	const computedClassName = useMemo(() => clsx(
		className, 'ci-button-group', { 'full-width': fullWidth }
	), [className, fullWidth]);

	return (
		<CIButtonGroupRoot
			className={computedClassName}
			orientation={orientation}
		>
			{options.map(option => {
				const selected = option === value;

				return (
					<Button
						variant={selected ? 'contained' : undefined}
						color={color}
						style={style}
						aria-selected={selected ? 'true' : 'false'}
						onClick={() => onChange(option)}
						disabled={disabled}
						key={option}
					>
						<Typography variant="body2" component="span">
							{getOptionLabel(option)}
						</Typography>
					</Button>
				);
			})}
		</CIButtonGroupRoot>
	);
};
