import type { YachtSpecificationSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { toNauticalMiles, toUSGallons } from '../../util';
import { YachtJsonLd } from '../structured-data';
import { SliceTitle } from './components';


type SpecKey = keyof YachtSpecificationSlice['specification'];
const SPECS: Readonly<Record<SpecKey, string>> = {
	'name': 'common:yacht-name',
	'priorName': 'common:prior-name',
	'lengthMeters': 'common:length',
	'beamMeters': 'common:beam',
	'draftMeters': 'common:draft',
	'speedCruiseKPH': 'common:speed-cruising',
	'speedMaxKPH': 'common:speed-max',
	'fuelCruiseLPH': 'common:fuel-consumption-cruising',
	'fuelMaxLPH': 'common:fuel-consumption-max',
	'rangeCruiseKm': 'common:range-cruising',
	'rangeMaxKm': 'common:range-max',
	'engine': 'common:engine',
	'hull': 'common:hull',
	'flagCountry': 'common:flag',
	'yearLaunch': 'common:launched',
	'yearRefit': 'common:refitted',
	'builder': 'common:builder',
	'designer': 'common:designer',
};

type Spec = { label: string, value: string };

const Slice_YachtSpecificationRoot = styled('section')(({ theme }) => css`
	display: flex;
	flex-direction: column;

	.yacht-spec__title {
		${theme.breakpoints.up(theme.sideContentBreakpoint)} {
			display: none;
		}
	}

	.yacht-spec__table {
		tr:first-of-type {
			th, td {
				padding-top: ${theme.spacing(1)};
			}
		}
		
		td {
			font-weight: ${theme.typography.fontWeightBold};
		}
	}

	.yacht-spec__label {
		border-bottom: 1px dashed ${theme.palette.grey[300]};
		margin-bottom: ${theme.spacing(.5)};
	}
`);

export const Slice_YachtSpecification = ({ slice: { specification } }: SliceProps<YachtSpecificationSlice>) => {
	const { t } = useTranslation();

	const specs = useMemo<Spec[]>(() => (
		Object.entries(SPECS).reduce((out: Spec[], [ key, label ]) => {
			let value: string | undefined;
			const specKey = key as SpecKey;

			switch(specKey) {
				// Strings
				case 'name':
				case 'priorName':
				case 'engine':
				case 'designer': {
					value = specification[specKey];
					break;
				}
				// Speeds
				case 'speedCruiseKPH':
				case 'speedMaxKPH': {
					const kph = specification[specKey];
					if(kph) {
						const nm = toNauticalMiles(kph);
						value = `${t('common:value.kph', { value: kph })} / ${t('common:value.kn', { value: nm })}`;
					}
					break;
				}
				// Volumes
				case 'fuelCruiseLPH':
				case 'fuelMaxLPH': {
					const lph = specification[specKey];
					if(lph) {
						const gph = toUSGallons(lph);
						value = `${t('common:value.lph', { value: lph })} / ${t('common:value.gph', { value: gph })}`;
					}
					break;
				}
				// Distances
				case 'rangeCruiseKm':
				case 'rangeMaxKm': {
					const km = specification[specKey];
					if(km) {
						const nm = toNauticalMiles(km);
						value = `${t('common:value.km', { value: km })} / ${t('common:value.nm', { value: nm })}`;
					}
					break;
				}
				// Enumerations
				case 'hull':
				case 'flagCountry':
				case 'builder':
					value = specification[specKey]?.name;
					break;
				// Numbers
				case 'yearLaunch':
				case 'yearRefit': {
					value = specification[specKey]?.toString();
					break;
				}
			}

			if(value) {
				out.push({ label: t(label), value });
			}
			return out;
		}, [])
	), [t, specification]);

	return (
		<>
			<YachtJsonLd specification={specification}/>
			<Slice_YachtSpecificationRoot className="yacht-spec">
				<SliceTitle className="yacht-spec__title" title={t('common:specification')}/>
				<Table className="yacht-spec__table">
					<TableBody>
						{ specs.map(({ label, value }) => (
							<TableRow key={label}>
								<TableCell component="th" scope="row" width="180">{label}</TableCell>
								<TableCell>{value}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Slice_YachtSpecificationRoot>
		</>
	);
};
