import type { YachtLocationsSlice } from '../../domain';
import type { Query } from '../util';
import type { SliceProps } from './SliceProps';
import Chip from '@mui/material/Chip';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { InternalLink } from '../components';
import { SliceTitle } from './components';


const Slice_YachtLocationsRoot = styled('section')(({ theme }) => css`
	ul {
		display: flex;
		flex-wrap: wrap;
		
		padding: 0;
		margin: 0;
		list-style: none;
	}

	li {
		margin: 0 ${theme.spacing(1)} ${theme.spacing(1)} 0;
	}

	.MuiChip-label {
		font-size: 1rem;
	}
`);

const LocationChip = ({ location }: { location: string }) => {
	const query = useMemo<Query>(() => ({
		place: location,
	}), [location]);

	return (
		<InternalLink href="/search/yachts" query={query}>
			<Chip
				label={location}
				variant="outlined"
				clickable
			/>
		</InternalLink>
	);
};

export const Slice_YachtLocations = ({ slice }: SliceProps<YachtLocationsSlice>) => {
	const { t } = useTranslation();

	return (
		<Slice_YachtLocationsRoot>
			<SliceTitle title={ t('common:locations') }/>
			<ul>
				{
					slice.locations.map(location => (
						<li key={location}>
							<LocationChip location={location}/>
						</li>
					))
				}
			</ul>
		</Slice_YachtLocationsRoot>
	);
};
