import type { TextFieldProps } from '@mui/material/TextField';
import type { DateRange } from '@mui/x-date-pickers-pro';
import type { ClassNameProp } from '../../../util';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { add } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo } from 'react';
import { useYachtSearchFilter } from '../..';


export type DateRangePickerFilterProps = ClassNameProp & {
	id: string;
	open?: boolean;
	onOpen?: VoidFunction;
	onClose?: VoidFunction;
	required?: boolean;
	variant?: TextFieldProps['variant']
};
export const DateRangePickerFilter = ({ className, variant, ...pickerProps }: DateRangePickerFilterProps) => {
	const { filter: { date }, updateFilter } = useYachtSearchFilter();
	const { t } = useTranslation();

	const value = useMemo<DateRange<Date>>(() => {
		if(!date) {
			return [ null, null ];
		}

		if(Array.isArray(date)) {
			return date as DateRange<Date>;
		}

		return [ date, null ];
	}, [ date ]);

	const handleAccept = useCallback((dates: DateRange<Date>) => {
		if(dates[0] && !dates[1]) {
			updateFilter({ date: dates[0] });
			return;
		}

		if(dates[0] && dates[1]) {
			updateFilter({ date: [ dates[0], dates[1] ] });
		}

	}, [updateFilter]);

	return (
		<DateRangePicker
			className={className}
			{...pickerProps}
			value={value}
			onAccept={handleAccept}
			minDate={add(Date.now(), { days: 1 })}
			localeText={{
				start: t('common:start-date'),
				end: t('common:end-date'),
			}}
			slotProps={{
				textField: {
					InputLabelProps: {
						shrink: true,
					},
					variant,
				},
			}}
		/>
	);
};
