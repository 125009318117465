import type { Components } from '@mui/material/styles';
import { palette } from '../palette';


export const MuiCssBaseline: Components['MuiCssBaseline'] = {
	styleOverrides: {
		html: {
			WebkitFontSmoothing: 'auto',
		},
		a: {
			textDecoration: 'none',
			color: palette.secondary.main,
		},
		main: {
			transformStyle: 'preserve-3d',
		},
	},
};
