import type { ArticleLimited, ArticlesSlice } from '../../../domain';
import type { SliceProps } from '../SliceProps';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { CardButton, InternalLink } from '../../components';
import { getImageUrl } from '../../util';
import { SliceTitle } from '../components/SliceTitle';


const ArticlesRoot = styled('section')(({ theme }) => css`
	.articles {
		display: flex;
		flex-direction: column;
		li + li {
			margin-top: var(--page-pad-y);
		}
		
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.article {
		overflow: hidden;
		display: grid;
		grid-template-columns: 2fr 2fr;
		gap: ${theme.spacing(1)};
		min-height: 150px;

		&__img {
			width: 100%;
			height: 100%;
			position: relative;

			border-radius: ${theme.shape.borderRadius}px;
			overflow: hidden;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 101%; // Account for minor rendering jank in FireFox
				height: 100%;
				object-fit: cover;
				will-change: transform;
			
				transform: scale(var(--card-button-focus-scaling, 1));
				transition: transform var(--card-button-focus-duration, 0);
			}
		}
		
		&__content {
			display: flex;
			flex-direction: column;
			align-items: space-between;
			height: 100%;

			> * + * {
				margin-top: ${theme.spacing(.5)};
			}
		}

		&__title {
			line-height: 1;
		}
	}
`);

type ArticleCardProps = { article: ArticleLimited };
const ArticleCard = ({ article }: ArticleCardProps) => {
	const description = useMemo<string>(() => {
		let description = article.description;
		if(description.endsWith(',')) {
			description = description.slice(0, -1);
		}
		if(!description.endsWith('.')) {
			return description += '...';
		}
		return description;
	}, [article.description]);
	return (
		<CardButton className="article no-elevation" LinkComponent={InternalLink} href={article.uri}>
			<div className="article__img">
				<img src={getImageUrl(article.image, 300)} loading="lazy" alt="" />
			</div>
			<div className="article__content">
				<Typography className="article__title" variant="h5" component="div">{article.title}</Typography>
				<Typography variant="body2" component="div">{description}</Typography>
			</div>
		</CardButton>
	);
};

export const Slice_ArticlesSide = ({ slice: { title, articles } }: SliceProps<ArticlesSlice>) => (
	<ArticlesRoot>
		<SliceTitle title={title}/>
		<ul className="articles">
			{
				articles.map(article => (
					<li key={article.id}>
						<ArticleCard article={article}/>
					</li>
				))
			}
		</ul>
	</ArticlesRoot>
);
