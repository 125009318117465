import type { MarkdownSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import { useMarkdown } from './use-markdown';


export type Slice_TextProps = SliceProps<MarkdownSlice> & {
	first: boolean;
};
export const Slice_Text = ({ slice: { markdown }, first }: Slice_TextProps) => {
	const content = useMarkdown(markdown, first);

	return (
		<section>
			{ content }
		</section>
	);
};
