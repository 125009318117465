import type { PropsWithChildren } from 'react';
import type { ClassNameProp } from '../../../util';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { forwardRef } from 'react';


export type SearchFormFilterProps = ClassNameProp & { id: string; };

const SearchFormControlRoot = styled('div')(({ theme }) => css`
	display: flex;
	align-items: center;

	padding: 0 var(--search-form-radius);
	height: 100%;

	height: calc(var(--search-form-radius) * 2);

	background-color: transparent;

	&:hover {
		background-color: ${theme.palette.grey[100]};
	}

	&:focus-within, &.focus {
		background-color: ${theme.palette.background.paper};
		box-shadow: ${theme.shadows[5]};
		z-index: 10;
	}

	transition: background-color 200ms, box-shadow 200ms;

	&.clickable {
		cursor: pointer;
	}

	.MuiInputLabel-root {
		pointer-events: none;
	}

	.MuiInput-root.MuiInput-underline:before {
		border-bottom: none !important;
	}

	.search-form-adornment {
		color: ${theme.palette.grey[800]};
	}
`);

export type SearchFormControlProps = ClassNameProp & {
	onClick?: () => void;
};
export const SearchFormControl = forwardRef<HTMLDivElement, PropsWithChildren<SearchFormControlProps>>(({ className, onClick, children }, ref) => {
	return (
		<SearchFormControlRoot
			ref={ref}
			className={clsx(className, 'rounded', { clickable: !!onClick })}
			onClickCapture={onClick}
		>
			{ children }
		</SearchFormControlRoot>
	);
});
