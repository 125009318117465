import type { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSuggest } from '../../../suggest';


export type SearchFormQueryContext = {
	place?: string;
	builder?: string;
	guestsSleeping?: number;
	setGuestsSleeping: (guestsSleeping: number | undefined) => void;
};

const searchFormQueryContext = createContext<SearchFormQueryContext | null>(null);

type PlaceOrBuilder = {
	kind: 'place' | 'builder';
	name: string;
};

export type SearchFormQueryProviderProps = {
	initialPlace?: string;
};
export const SearchFormQueryProvider: FC<SearchFormQueryProviderProps> = ({ initialPlace, children }) => {
	const [ placeOrBuilder, setPlaceOrBuilder ] = useState<PlaceOrBuilder | undefined>(initialPlace ? { kind: 'place', name: initialPlace } : undefined);
	const [ guestsSleeping, setGuestsSleeping ] = useState<number | undefined>();

	// On desktop, intercept suggestion selection to set placeOrBuilder state.
	const largeScreen = useMediaQuery(useTheme().breakpoints.up('sm'));
	const { onConfirmResult } = useSuggest();
	useEffect(() => {
		if(!largeScreen) {
			return;
		}

		return onConfirmResult(result => {
			switch(result.type) {
				case 'builder':
					setPlaceOrBuilder({ kind: 'builder', name: result.name });
					return true;
				case 'location':
					setPlaceOrBuilder({ kind: 'place', name: result.name });
					return true;
				default:
					return false;
			}
		});
	}, [largeScreen, onConfirmResult]);

	const value = useMemo<SearchFormQueryContext>(() => ({
		place: placeOrBuilder?.kind === 'place' ? placeOrBuilder.name : undefined,
		builder: placeOrBuilder?.kind === 'builder' ? placeOrBuilder.name : undefined,
		guestsSleeping,
		setGuestsSleeping,
	}), [guestsSleeping, placeOrBuilder]);

	return (
		<searchFormQueryContext.Provider value={ value }>
			{ children }
		</searchFormQueryContext.Provider>
	);
};

export const useYachtSearchFormQuery = (): SearchFormQueryContext => {
	const ctx = useContext(searchFormQueryContext);
	if(!ctx) {
		throw new Error('searchFormQueryContext was null; missing <SearchFormQueryProvider>?');
	}

	return ctx;
};
