import type { MediaCarouselSlice } from '../../domain';
import type { LinkButtonComponent } from '../components';
import type { SliceProps } from './SliceProps';
import SimpleReactLightbox, { SRLWrapper } from '@charterindex/simple-react-lightbox';
import PlayIcon from '@mui/icons-material/PlayArrow';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import { Analytics } from '../analytics';
import { Carousel } from '../components';
import { CardButton } from '../components/CardButton';
import { PLACEHOLDER_SVG } from '../static';
import { getImageUrl } from '../util';


const Slice_MediaCarouselRoot = styled('section')(({ theme }) => css`
	width: 100%;
	max-width: calc(100vw - ${theme.spacing(1)});
	margin-right: auto;
	padding: 2px 0;
	
	--card-spacing: calc(var(--page-pad-y) / 2);
	--num-cards: 1;
	--card-width: calc((100% / var(--num-cards)) - var(--card-spacing));
	--card-padding-bottom: 60%;
	
	${theme.breakpoints.up('sm')} {
		--num-cards: 2;
		--card-padding-bottom: 33%;
	}

	${theme.breakpoints.up('md')} {
		--num-cards: 3;
		--card-padding-bottom: 20%;
	}

	${theme.breakpoints.up('lg')} {
		--num-cards: 4;
		--card-padding-bottom: 15%;
	}

	${theme.breakpoints.up('xl')} {
		--num-cards: 5;
		--card-padding-bottom: 10%;
	}

`);

const ThumbnailSlide = styled(CardButton)`
	position: relative;
	width: 100%;
	height: 0;
	min-width: var(--card-width);
	padding-bottom: var(--card-padding-bottom);

	background-image: url('${PLACEHOLDER_SVG}');
	background-position: center;
	background-size: cover;

	> :not(.MuiTouchRipple-root) {
		transform: translateY(50%);
	}

	& + * {
		margin-left: var(--card-spacing);
	}

` as LinkButtonComponent<{
	srl_video_thumbnail?: string;
	srl_video_caption?: string;
	srl_video_scale?: number;
}>; // Extra props required by SimpleReactLightbox.

const ThumbnailImage = styled('img')(({ theme }) => css`
	position: absolute;
	height: 100%;
	width: 100%;
	/* width: calc(100% - ${theme.spacing(2)}); */
	left: 0;
	overflow: hidden;

	border-radius: ${theme.shape.borderRadius}px;
	
	object-fit: cover;

	img {
		pointer-events: none;
	}
`);

const PlayButtonContainer = styled('div')(({ theme }) => css`
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	& > svg {
		margin: auto;
		color: white;
		font-size: ${theme.spacing(10)};
		border-radius: 50%;
		background-color: rgba(0,0,0,0.3);
	}
	z-index: 100;
`);

const LIGHTBOX_OPTIONS = {
	buttons: {
		showDownloadButton: false,
	},
};

export const Slice_MediaCarousel = ({ slice: { video, images } }: SliceProps<MediaCarouselSlice>) => {
	const theme = useTheme();
	const smUpMediaQuery = useMediaQuery(theme.breakpoints.up('sm'));
	const mdUpMediaQuery = useMediaQuery(theme.breakpoints.up('md'));
	const lgUpMediaQuery = useMediaQuery(theme.breakpoints.up('lg'));

	const slidesToScroll = useMemo(() => {
		if(smUpMediaQuery) {
			return 2;
		}
		if (mdUpMediaQuery) {
			return 3;
		}
		if (lgUpMediaQuery) {
			return 4;
		}
		return 1;
	}, [lgUpMediaQuery, mdUpMediaQuery, smUpMediaQuery]);

	const lightboxCallbacks = {
		onLightboxOpened: () => {
			Analytics.interaction({ target: 'Yacht Media Lightbox', action: 'open' });
		},
		onLightboxClosed: () => {
			Analytics.interaction({ target: 'Yacht Media Lightbox', action: 'close' });
		},
	};


	return (
		<Slice_MediaCarouselRoot>
			<SimpleReactLightbox>
				<SRLWrapper options={LIGHTBOX_OPTIONS} callbacks={lightboxCallbacks}>
					<Carousel name="Yacht Media" slidesToScroll={slidesToScroll}>
						{video && (
							<ThumbnailSlide
								href={video.url}
								rel="nofollow"
								srl_video_thumbnail={getImageUrl(video.image, 400)}
								srl_video_caption={video.title}
								srl_video_scale={100}
							>
								<ThumbnailImage
									src={getImageUrl(video.image, 400)}
									alt="Video"
									loading="lazy"
								/>
								<PlayButtonContainer>
									<PlayIcon/>
								</PlayButtonContainer>
							</ThumbnailSlide>
						)}
						{images.map(({ id, caption }) => (
							<ThumbnailSlide
								href={getImageUrl(id)}
								key={id}
							>
								<ThumbnailImage
									src={getImageUrl(id, 400)}
									alt={caption}
									loading="lazy"
								/>
							</ThumbnailSlide>
						))}
					</Carousel>
				</SRLWrapper>
			</SimpleReactLightbox>
		</Slice_MediaCarouselRoot>
	);
};
