export const palette = {
	primary: {
		light: '#e566a9',
		main: '#d40072',
		dark: '#7f0043',
	},
	secondary: {
		light: '#66b7f0',
		main: '#0079d1',
		dark: '#00518a',
	},
};
