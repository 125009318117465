import type { Article } from '../../domain';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { asLanguage } from '../../domain';
import { getImageUrl } from '../util';
import { JsonLd } from './json-ld';


export type ArticleJsonLdProps = {
	article: Article
};
export const ArticleJsonLd = ({ article }: ArticleJsonLdProps) => {
	const { lang } = useTranslation();

	const value = useMemo(() => ({
		'@type': 'Article',
		headline: article.title,
		url: article.uris[asLanguage(lang)],
		inLanguage: lang,
		datePublished: article.datePublished,
		dateModified: article.dateModified,
		description: article.metaDescription,
		image: article.image && getImageUrl(article.image),
		author: {
			'@type': 'Organisation',
			name: 'Charter Index',
			url: 'https://charterindex.com',
		},
	}), [article, lang]);

	return (
		<JsonLd value={value}/>
	);
};
