import type { ClassNameProp } from '../../util';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { InternalLink } from '../../components';
import { useSuggest } from '../suggest-context';


const SuggestDefaultRoot = styled('div')(({ theme }) => css`
	display: grid;
	grid-auto-flow: row;
	gap: ${theme.spacing(2)};

	h2, a {
		font-size: ${theme.typography.h5.fontSize};
	}
`);

export const SuggestDefault = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();
	const { defaultSuggest, suggestState, setSuggestQuery } = useSuggest();

	const handleClick = useCallback(() => {
		setSuggestQuery('');
	}, [setSuggestQuery]);

	if(!defaultSuggest || suggestState.loading) {
		return null;
	}

	return (
		<SuggestDefaultRoot className={className}>
			<Typography variant="h2">
				{ t('common:charter-anywhere') }
			</Typography>

			<Button
				variant="contained"
				size="large"
				startIcon={<TravelExploreIcon/>}
				onClick={handleClick}
				LinkComponent={InternalLink}
				href="/search/yachts"
			>
				{ t('common:worldwide-yachts') }
			</Button>

			<Typography variant="h2">Popular Destinations</Typography>
		</SuggestDefaultRoot>
	);
};
