/* eslint-disable @typescript-eslint/no-unused-vars */
import type { MarkdownToJSX } from 'markdown-to-jsx';
import type { FC, ReactElement, ReactNode } from 'react';
import type { ClassNameProp } from '../util';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { compiler } from 'markdown-to-jsx';
import { Children, createElement } from 'react';
import { cleanId } from '../util';


type MarkdownHeadingProps = ClassNameProp & {
	variant: 'h1' | 'h2' | 'h3'
};
/** Construct heading ID from direct text content children. */
const headingId = (children: ReactNode): string => {
	const idSegments: string[] = [];
	Children.forEach(children, child => {
		if(!child) {
			return;
		}

		switch(typeof(child)) {
			case 'string':
				idSegments.push(...child.split(' '));
				return;
			case 'number':
				idSegments.push(child.toString());
				return;
			case 'boolean':
				return;
		}
	});

	return cleanId(idSegments.map(seg => seg.toLocaleLowerCase()).join('-'));
};
const MarkdownHeading: FC<MarkdownHeadingProps> = ({ variant, className, children }) => {
	return (
		<Typography
			id={headingId(variant)}
			className={className}
			variant={variant}
		>
			{ children }
		</Typography>
	);
};

const makeMarkdownOptions = (first: boolean | undefined): MarkdownToJSX.Options => ({
	forceBlock: true,
	overrides: {
		h1: {
			component: MarkdownHeading,
			/*
				An `<h1>` in markdown content is always a mistake, so catch that here
				and render as `<h2>`.
			*/
			props: { variant: 'h2', margin: 'unset' },
		},
		h2: {
			component: MarkdownHeading,
			props: { variant: 'h2', margin: 'unset' },
		},
		h3: {
			component: MarkdownHeading,
			props: { variant: 'h3', margin: 'unset' },
		},
		p: {
			component: Typography,
			props: { variant: 'body1', margin: 'unset' },
		},
	},
	wrapper: null,
	createElement(tag, props, ...children) {
		return createElement(tag, {
			...props,
			className: ((first && (props as any).variant === 'body1') ? 'firstParagraph' : undefined), // eslint-disable-line @typescript-eslint/no-explicit-any
		} as any, children.length ? children : undefined); // eslint-disable-line @typescript-eslint/no-explicit-any
	},
});

const MarkdownRoot = styled('div')`
	display: flex;
	flex-direction: column;
	
	> .MuiTypography-root + .MuiTypography-root {
		margin-top: var(--typographySpacing, .75em);
	}
	
	h2, h3 {
		--typographySpacing: 1.25em;
		text-transform: capitalize;
	}
	
	h2 {
		font-size: 1.6rem;
	}

	h3 {
		font-size: 1.3rem;
	}
	
	.firstParagraph:first-of-type {
		font-size: 1.1em;
		&::first-letter {
			font-size: 3.25em;
			line-height: .7;
			margin-top: .115em;
			margin-right: .05em;
			float: left;
		}
	}
`;

export const useMarkdown = (markdown: string | undefined, _first?: boolean): ReactElement | null => {
	if(!markdown) {
		return null;
	}

	return (
		<MarkdownRoot>
			{ compiler(markdown, makeMarkdownOptions(_first)) }
		</MarkdownRoot>
	);
};
