import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import { PAGE_ID } from '../../util';
import { useYacht } from '../yacht-context';
import { YachtEnquiryButton } from './header';
import { YachtPrice } from './header/YachtPrice';


const YachtStickyHeaderRoot = styled(Paper)(({ theme }) => css`
	${theme.pagePaddingVars(theme)};

	position: fixed;
	top: 0;
	padding: ${theme.spacing(1)} var(--page-pad-x);
	
	display: grid;
	grid-template-areas: 'price enquire';
	grid-template-columns: 1fr auto;	

	.yacht-sticky-header__name {
		grid-area: name;
		display: none;
		margin: auto 0;
	}
	.yacht-sticky-header__price {
		grid-area: price;
		margin-left: 0;
	}
	.yacht-sticky-header__enquire {
		grid-area: enquire;
		padding-inline: 20px;
		margin-block: auto;
	}

	${theme.breakpoints.up('sm')} {
		grid-template-areas: 'name price enquire';
		grid-template-columns: 1fr auto auto;

		.yacht-sticky-header__name {
			display: unset;
		}
	}

	> * + * {
		margin-left: ${theme.spacing(2)};
	}
`);

export type YachtStickyHeaderProps = {
	open: boolean;
};

export const YachtStickyHeader = ({ open }: YachtStickyHeaderProps) => {
	const { name } = useYacht();
	const rootRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleResize = () => {
			const anchor = document.getElementById(PAGE_ID);
			const root = rootRef.current;

			if(!anchor || !root) {
				setTimeout(handleResize);
				return;
			}

			root.style.width = `${anchor.clientWidth}px`;
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => { window.removeEventListener('resize', handleResize); };
	}, []);

	return (
		<>
			<Portal>
				<Slide direction="down" in={open}>
					<YachtStickyHeaderRoot
						ref={rootRef}
						elevation={10}
						square
					>
						<Typography className="yacht-sticky-header__name" variant="h2" component="span">
							{ name }
						</Typography>
						<YachtPrice className="yacht-sticky-header__price"/>
						<YachtEnquiryButton className="yacht-sticky-header__enquire"/>
					</YachtStickyHeaderRoot>
				</Slide>
			</Portal>
		</>
	);
};
