import type { Translate } from 'next-translate';
import type { Category, Hull, Range, YachtSearchFilter } from '../../domain';
import type { DimensionUnit } from '../preferences';
import { add } from 'date-fns';
import { headAndTail, isNullish } from '../../util';


/** Local filter type, using full `Date` object for date. */
export type ClientYachtSearchFilter = Omit<YachtSearchFilter, 'date'> & {
	date?: Range<Date>;
};

export type FilterKey = (keyof YachtSearchFilter);
export const FILTER_KEYS: FilterKey[] = [
	'place',
	'builder',
	'hull',
	'category',
	'date',
	'guestsSleeping',
	'lowPrice',
	'lengthMeters',
	'yearLaunch',
];
export const isFilterKey = (x: string): x is keyof YachtSearchFilter => FILTER_KEYS.includes(x as keyof YachtSearchFilter);

export const EMPTY_FILTER: Record<keyof YachtSearchFilter, undefined> = {
	areas: undefined,
	subAreas: undefined,
	place: undefined,
	builder: undefined,
	hull: undefined,
	category: undefined,
	date: undefined,
	guestsSleeping: undefined,
	lowPrice: undefined,
	lengthMeters: undefined,
	yearLaunch: undefined,
};

export type FilterValue = YachtSearchFilter[keyof YachtSearchFilter];

export const dateToFilterValue = (date: Date): string => date.toISOString();
const prepareSingleDate = (date: Date): string | undefined => {
	try {
		return date.toISOString().split('T')[0];
	} catch(err) {
		return undefined;
	}
};
export const prepareDateFilter = (date: ClientYachtSearchFilter['date']): YachtSearchFilter['date'] => {
	if(!date) {
		return undefined;
	}

	if(Array.isArray(date)) {
		const prepared = date.map(prepareSingleDate);
		if(prepared.some(isNullish)) {
			return undefined;
		}
		return prepared as Range<string>;
	}

	return prepareSingleDate(date);
};
export const dateFilterToParam = (date: ClientYachtSearchFilter['date'] | undefined): string | undefined => {
	const prepared = prepareDateFilter(date);

	if(!prepared) {
		return undefined;
	}

	if(Array.isArray(prepared)) {
		return prepared.join(',');
	}

	return prepared;
};
export const MIN_DATE_FILTER = add(new Date(), { days: 1 });
export const MAX_DATE_FILTER = add(MIN_DATE_FILTER, { years: 2 });

export type NumberRangeFilterKey = keyof Pick<YachtSearchFilter, 'guestsSleeping' | 'lowPrice' | 'lengthMeters'>;
export type StringFilterKey = keyof Pick<YachtSearchFilter, 'place' | 'builder' | 'hull' | 'category'>;

export const GUESTS_MARKS = [ 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 26, 30, 32 ];
export const [ MIN_GUEST_FILTER, MAX_GUEST_FILTER ] = headAndTail(GUESTS_MARKS);

export const PRICE_MARKS = [ 0, 20_000, 40_000, 60_000, 80_000, 100_000, 150_000, 200_000, 250_000, 300_000, 350_000, 400_000, 450_000, 500_000 ];
export const [ MIN_PRICE_FILTER, MAX_PRICE_FILTER ] = headAndTail(PRICE_MARKS);

export const LENGTH_MARKS: Record<DimensionUnit, number[]> = {
	'm': [0, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
	'ft': [0, 20, 40, 60, 80, 100, 125, 150, 175, 200, 225, 250, 275, 300],
};
export const MIN_LENGTH_FILTER: Record<DimensionUnit, number> = {
	m: LENGTH_MARKS.m[0],
	ft: LENGTH_MARKS.ft[0],
};
export const MAX_LENGTH_FILTER: Record<DimensionUnit, number> = {
	m: LENGTH_MARKS.m[LENGTH_MARKS.m.length - 1],
	ft: LENGTH_MARKS.ft[LENGTH_MARKS.ft.length - 1],
};

export const translateCategory = (t: Translate, category: Category.Id): string => {
	switch(category) {
		case 'POWER': return t('common:motor-yachts');
		case 'SAIL': return t('common:sailing-yachts');
	}
};



export const translateHull = (t: Translate, hull: Hull.Id): string => (
	t(`common:${hull.toLocaleLowerCase()}`)
);

export const rangeMin = (r: Range<number> | undefined): number | undefined => (
	Array.isArray(r) ? r[0] : Number.isFinite(r) ? r : undefined
);
