import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useYacht } from '../yacht';
import { SliceTitle } from './components';


const YachtLegalRoot = styled('section')(({ theme }) => css`
	> h2 {
		font-size: 1rem;
		margin-bottom: ${theme.spacing(.5)};
	}

	> p {
		font-size: .8rem;
		margin: 0;
	}

	> p + h2 {
		margin-top: ${theme.spacing(2)};
	}
`);

export const Slice_YachtLegal = (_: unknown) => {
	const { t } = useTranslation();
	const yacht = useYacht();

	return (
		<YachtLegalRoot lang="en">
			<SliceTitle title={t('common:yacht-legal.disclaimer.title')}/>
			<Typography component="p">
				{t('common:yacht-legal.disclaimer.content', {
					yachtName: yacht.name,
					yachtCategory: t(yacht.category.id === 'POWER' ? 'common:motor-yacht' : 'common:sailing-yacht'),
				})}
			</Typography>

			<SliceTitle title={t('common:yacht-legal.us-customs.title')}/>
			<Typography component="p">
				{t('common:yacht-legal.us-customs.content')}
			</Typography>
		</YachtLegalRoot>
	);
};
