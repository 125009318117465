import type { Currency } from '../../../../domain';
import type { ClassNameProp } from '../../../util';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { usePreferences } from '../../../preferences';
import { useEnquiryController } from '../../enquiry-controller-context';


const getIcon = (currency: Currency): JSX.Element => {
	switch(currency) {
		case 'GBP':
			return <CurrencyPoundIcon/>;
		case 'EUR':
			return <EuroSymbolIcon/>;
		default:
			return <AttachMoneyIcon/>;
	}
};

export const EnquiryFormBudgetField = ({ className }: ClassNameProp) => {
	const { formatCurrency, currency } = usePreferences();

	const controller = useEnquiryController();
	useSubject(controller.model, 'budget');
	useSubject(controller, 'submitting');

	const options = useMemo<string[]>(() => {
		return controller.budgetOptions.map((value, index, array) => {
			let option = formatCurrency(value);

			const nextValue = array[index + 1];
			if(nextValue !== undefined) {
				option += ' - ' + formatCurrency(nextValue);
			} else {
				option += '+';
			}

			return option;
		});
	}, [controller.budgetOptions, formatCurrency]);

	const { t } = useTranslation();

	return (
		<TextField
			className={className}
			select
			label={t('common:enquiry.budget.label')}
			size="small"
			value={controller.model.budget ?? ''}
			onChange={ev => controller.model.budget = ev.target.value}
			disabled={controller.submitting}
			InputProps={{
				startAdornment: getIcon(currency),
			}}
			required
		>
			{ options.map(option => (
				<MenuItem value={option} key={option}>
					{ option }
				</MenuItem>
			))}
		</TextField>
	);
};
