
export const omitNullish = <T extends Record<string, unknown>>(obj: T): T => (
	Object.fromEntries(
		Object.entries(obj).filter(([ , v ]) => v !== null && v !== undefined)
	) as T
);

export const omit = <T extends Record<string, unknown>, K extends keyof T> (obj: T, ...keys: K[]): Omit<T, K> => (
	Object.fromEntries(
		Object.entries(obj).filter(([ k ]) => !keys.includes(k as K))
	) as Omit<T, K>
);

export const isNullish = <T>(x: T | undefined | null): x is undefined | null => (
	x === null || x === undefined
);

export const notNullish = <T>(x: T | undefined | null): x is T => (
	x !== undefined && x !== null
);

export const mapEntries = <InK extends string, InV, OutK extends string, OutV>(object: Record<InK, InV>, map: (entry: [InK, InV]) => [OutK, OutV] | null): Record<OutK, OutV> => (
	Object.fromEntries(
		Object.entries(object)
			.map(entry => map(entry as [InK, InV]))
			.filter(Array.isArray) as [OutK, OutV][]
	) as Record<OutK, OutV>
);
