import { callback, observable, subject } from 'ecce-react';


@subject()
export class PageVisibilityController {
	#root: HTMLElement | null = null;
	#heroCta: HTMLElement | null = null;
	#heroCtaObserver: IntersectionObserver | null = null;
	#enquiryForm: HTMLElement | null = null;
	#enquiryFormObserver: IntersectionObserver | null = null;
	#footer: HTMLElement | null = null;
	#footerObserver: IntersectionObserver | null = null;

	#contentVisible = false;
	@observable() get contentVisible(): boolean { return this.#contentVisible; }
	private set contentVisible(value: boolean) { this.#contentVisible = value; }

	#heroCtaVisible = true;
	@observable() get heroCtaVisible(): boolean { return this.#heroCtaVisible; }
	private set heroCtaVisible(value: boolean) { this.#heroCtaVisible = value; }

	#enquiryFormVisible = false;
	@observable() get enquiryFormVisible(): boolean { return this.#enquiryFormVisible; }
	private set enquiryFormVisible(value: boolean) { this.#enquiryFormVisible = value; }

	#footerVisible = false;
	@observable() get footerVisible(): boolean { return this.#footerVisible; }
	private set footerVisible(value: boolean) { this.#footerVisible = value; }

	@callback
	rootRef(el: HTMLElement | null): void {
		if(el === this.#root) {
			return;
		}

		this.#root?.removeEventListener('scroll', this.#handleRootScroll);
		this.#root = el;
		this.#root?.addEventListener('scroll', this.#handleRootScroll, {
			passive: true,
		});
	}

	#handleRootScroll = () => {
		if(!this.#root) {
			return;
		}

		const nextContentVisible = this.#root.scrollTop > this.#contentVisibilityThreshold;
		if(nextContentVisible === this.contentVisible) {
			return;
		}
		this.contentVisible = nextContentVisible;
	};

	get #contentVisibilityThreshold() {
		return (window.visualViewport?.height ?? window.innerHeight) * .25;
	}

	@callback
	heroCtaRef(el: HTMLElement | null) {
		if(el === this.#heroCta) {
			return;
		}

		this.#heroCtaObserver?.disconnect();
		this.#heroCtaObserver = null;

		this.#heroCta = el;
		if(this.#heroCta) {
			this.#heroCtaObserver = new IntersectionObserver(([entry]) => {
				const nextHeroCtaVisible = entry.isIntersecting;
				if(nextHeroCtaVisible !== this.heroCtaVisible) {
					this.heroCtaVisible = nextHeroCtaVisible;
				}
			}, { threshold: 1 });
			this.#heroCtaObserver.observe(this.#heroCta);
		}
	}

	@callback
	enquiryFormRef(el: HTMLElement | null) {
		if(el === this.#enquiryForm) {
			return;
		}

		this.#enquiryFormObserver?.disconnect();
		this.#enquiryFormObserver = null;

		this.#enquiryForm = el;
		if(this.#enquiryForm) {
			this.#enquiryFormObserver = new IntersectionObserver(([ entry ]) => {
				const nextEnquiryFormVisible = entry.isIntersecting;
				if(nextEnquiryFormVisible !== this.enquiryFormVisible) {
					this.enquiryFormVisible = nextEnquiryFormVisible;
				}
			}, { threshold: .5 });
			this.#enquiryFormObserver.observe(this.#enquiryForm);
		}
	}

	@callback
	footerRef(el: HTMLElement | null): void {
		if(el === this.#footer) {
			return;
		}

		this.#footerObserver?.disconnect();
		this.#footerObserver = null;

		this.#footer = el;
		if(this.#footer) {
			this.#footerObserver = new IntersectionObserver(([ entry ]) => {
				const nextFooterVisible = entry.isIntersecting;
				if(nextFooterVisible === this.footerVisible) {
					return;
				}

				this.footerVisible = nextFooterVisible;
			});
			this.#footerObserver.observe(this.#footer);
		}
	}
}
