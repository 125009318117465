import type { RangedSelectFunction } from 'react-infinite-calendar';
import type { ClassNameProp } from '../../../util';
import { useCallback, useMemo } from 'react';
import { useYachtSearchFilter } from '../..';
import { InfiniteDateRangePicker } from '../InfiniteDateRangePicker';


export const StaticDateRangeFilter = ({ className }: ClassNameProp) => {
	const { filter: { date }, updateFilter } = useYachtSearchFilter();

	const value = useMemo(() => {
		if(!date) {
			return false;
		}

		if(Array.isArray(date)) {
			return { start: date[0], end: date[1] };
		}

		return date;
	}, [ date ]);

	const handleChange = useCallback<RangedSelectFunction>(value => {
		updateFilter({ date: [ value.start, value.end ] });
	}, [updateFilter]);


	return <InfiniteDateRangePicker
		className={className}
		selected={value}
		onSelect={handleChange}
	/>;
};
