
import { CharterIcon } from '@charterindex/icons';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef } from 'react';
import { useEnquiryController } from '../enquiry-controller-context';


const EnquiryCompleteRoot = styled(Alert)(({ theme }) => css`
	position: relative;
	
	.scrollAnchor {
		position: absolute;
		top: -150px;
	}

	.MuiAlert-message {
		display: grid;
		grid-template-areas:
			'icon   message'
			'action action'
		;
		grid-template-columns: 52px 1fr;
		gap: ${theme.spacing(1, 2)};

		.enquiryComplete__icon {
			grid-area: icon;
			display: block;
			width: 100%;
			height: auto;
		}
		.enquiryComplete__message {
			grid-area: message;
			margin: auto 0;
		}
		.enquiryComplete__action {
			grid-area: action;
		}
	}
`);

export type EnquiryCompleteProps = {
	id: string;
	action?: JSX.Element;
};
export const EnquiryComplete = ({ id, action }: EnquiryCompleteProps) => {
	const controller = useEnquiryController();
	const { t } = useTranslation();

	const anchorRef = useRef<HTMLAnchorElement>(null);
	useEffect(() => {
		if(controller.result?.ok && controller.result.id === id) {
			anchorRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
		}
	}, [controller.result, id]);

	return (
		<EnquiryCompleteRoot
			severity="success"
			icon={false}
			data-enquiry-complete
		>
			<a className="scrollAnchor" ref={anchorRef}/>
			<CharterIcon iconName="enquiryComplete" size="lg"/>
			<Typography
				className="enquiryComplete__message"
				component="span"
				fontSize="1.4rem"
				lineHeight="1.2"
			>
				{
					t('common:enquiry.dialog.thank-you-content', { firstName: controller.model.name })
				}
			</Typography>

			{ action && (
				<Box
					className="enquiryComplete__action"
					display="flex"
					justifyContent="flex-end"
				>
					{ action }
				</Box>
			) }
		</EnquiryCompleteRoot>
	);
};
