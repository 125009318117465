import type { ClassNameProp } from '@charterindex/armoury-www';
import type { Translate } from 'next-translate';
import type { EnquiryController } from '../../enquiry-controller';
import EmailIcon from '@mui/icons-material/Email';
import TextField from '@mui/material/TextField';
import { css, styled } from '@mui/material/styles';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


const EmailSuggestionButton = styled('button')(({ theme }) => css`
	display: inline;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	font: inherit;
	color: ${theme.palette.error.main};
	cursor: pointer;
	text-decoration: underline;
	text-align: start;
`);

const getHelperText = (t: Translate, controller: EnquiryController): | JSX.Element | undefined => {
	if(controller.errors.invalidEmail !== controller.model.email) {
		return undefined;
	}

	return (
		<>
			<span>{t('common:enquiry.email.invalid')}. </span>
			{ controller.errors.emailSuggestion && (
				<EmailSuggestionButton
					type="button"
					onClick={controller.errors.acceptEmailSuggestion}
				>
					{ t('common:enquiry.email.suggestion', { emailAddress: controller.errors.emailSuggestion })}
				</EmailSuggestionButton>
			)}
		</>
	);
};

export const EnquiryFormEmailField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'email', { immediate: true });
	useSubject(controller, 'submitting');

	useSubject(controller.errors, 'invalidEmail');
	useSubject(controller.errors, 'emailSuggestion');

	const { t } = useTranslation();

	return (
		<TextField
			className={className}
			label={t('common:enquiry.email.label')}
			InputProps={{
				startAdornment: <EmailIcon/>,
				type: 'email',
			}}
			autoComplete="email"
			value={controller.model.email}
			onChange={ ev => controller.model.email = ev.target.value }
			disabled={controller.submitting}
			required
			fullWidth
			size="small"
			error={controller.model.email === controller.errors.invalidEmail}
			helperText={ getHelperText(t, controller) }
		/>
	);
};
