import type { ClassNameProp } from '@charterindex/armoury-www';
import Person2Icon from '@mui/icons-material/Person';
import TextField from '@mui/material/TextField';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


export const EnquiryFormNameField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'name', { immediate: true });
	useSubject(controller, 'submitting');

	const { t } = useTranslation();

	return (
		<TextField
			className={className}
			label={t('common:enquiry.name.label')}
			InputProps={{
				startAdornment: <Person2Icon/>,
			}}
			autoComplete="name"
			value={controller.model.name}
			onChange={ ev => controller.model.name = ev.target.value }
			disabled={controller.submitting}
			required
			fullWidth
			size="small"
		/>
	);
};
