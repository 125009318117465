import { TelephoneNumberInput, type ClassNameProp } from '@charterindex/armoury-www';
import '@charterindex/armoury-www/telephone-number-input.css';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


const langToCountryCode = (lang: string): string => {
	switch(lang) {
		case 'en':
			return 'us';
		case 'en-GB':
			return 'gb';
		case 'ja':
			return 'jp';
		case 'zh':
			return 'cn';
		case 'de':
		case 'es':
		case 'fr':
		case 'it':
		case 'pt':
		case 'ru':
			return lang;
		default:
			return 'us';
	}
};

export const EnquiryFormPhoneField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'telephone', { immediate: true });
	useSubject(controller, 'submitting');
	useSubject(controller.errors, 'invalidTelephone');

	const invalid = controller.errors.invalidTelephone === controller.model.telephone;
	const { t, lang } = useTranslation();

	return (
		<TelephoneNumberInput
			className={className}
			label={t('common:enquiry.telephone.label')}
			value={controller.model.telephone}
			onChange={ev => {
				controller.model.telephone = ev.value;
				controller.model.country = ev.details.countryCode?.toUpperCase() ?? '';
			}}
			autoComplete
			disabled={controller.submitting}
			defaultCountryCode={langToCountryCode(lang)}
			required
			error={invalid}
			helperText={ invalid ? t('common:enquiry.telephone.invalid') : undefined }
		/>
	);
};
