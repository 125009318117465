import type { FC } from 'react';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


const SectionHeaderRoot = styled(Typography)(({ theme }) => css`
	margin: var(--page-pad-y) 0 ;
	padding-top: var(--page-pad-y);
	border-top: 1px solid ${theme.palette.grey[300]};
`);

export const SectionHeader: FC = ({ children }) => (
	<SectionHeaderRoot variant="h2">{ children }</SectionHeaderRoot>
);
