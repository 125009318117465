import type { ReactNode } from 'react';
import type { FooterLinks, HeaderLinks, Language, RelatedYachts, Slice } from '../../../domain';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { FloatingPreferenceControls } from '../../preferences/components/PreferenceControls';
import { Slices } from '../../slices';
import { PAGE_ID, useStickyState } from '../../util';
import { usePageVisibilityController } from '../page-visibility-controller-context';
import { Footer } from './Footer';
import { PageHeader } from './PageHeader';
import { RelatedYachtsCarousels } from './RelatedYachtsCarousels';
import { ScrollTopButton } from './ScrollTopButton';


const PageRoot = styled('div')(({ theme }) => css`
	${theme.pagePaddingVars(theme)};

	position: relative;
	display: grid;
	grid-template-rows: auto 1fr auto;

	min-height: 100vh;
	max-height: 100vh;
	overflow-y: auto;
	width: 100%;

	overflow-x: hidden;

	// Hero parallax effect.
	perspective: 1px;
	perspective-origin: 0 0;
	transform-style: preserve-3d;

	.content {
		position: relative;
		display: grid;
		grid-template-areas:
			'head'
			'main-and-side'
			'tail'
		;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		
		background-color: ${theme.palette.background.paper};

		.head {
			grid-area: head;
			padding: 0 var(--page-pad-x);
			padding-top: var(--page-pad-y);

			&--sticky {
				position: sticky;
				top: 0;
				padding-top: calc(var(--page-pad-y) * .5);
				padding-bottom: calc(var(--page-pad-y) * .5);
				background: white;
				transition: box-shadow 300ms;
				z-index: 1;
			}

			&--stuck {
				box-shadow: ${theme.shadows[3]};
			}
		}

		.main-and-side {
			grid-area: main-and-side;

			display: grid;
			grid-template-areas: 'main';
			grid-template-columns: 1fr;
			
			padding-top: var(--page-pad-y);

			max-width: 100vw;

			&__main {
				grid-area: main;
			}

			&--with-side {
				grid-template-areas: 'main' 'side';
				margin: 0 auto;
				gap: calc(var(--page-pad-x) / 2);
				
				${theme.breakpoints.up('md')} {
					grid-template-areas: 'main side';
					grid-template-columns: 3fr clamp(40ch, 40ch, 25vw);
				}

				${theme.breakpoints.up('md')} {
					.main-and-side__content {
						padding: 0 calc(var(--page-pad-x) / 2) 0 var(--page-pad-x);
					}

					.main-and-side__main {
						border-right: 1px solid ${theme.palette.grey[200]};
					}
				}
			}

			&__side {
				grid-area: side;
				margin-right: ${theme.spacing(1)};
				padding: var(--page-pad-y) var(--page-pad-x);
				padding-top: calc(var(--page-pad-y) * 2);

				${theme.breakpoints.up('md')} {
					padding: 0;
					padding-right: ${theme.spacing(1)};
				}
			}
			
			&--no-pad-top {
				padding-top: 0;
			}
			
			&__content {
				padding: 0 var(--page-pad-x);
				width: 100%;
			}
		}

		.tail {
			grid-area: tail;
			min-height: var(--page-pad-y);
			display: grid;
			max-width: 100%;
			overflow-x: hidden;

			&__content {
				margin-top: var(--page-pad-y);
				padding: var(--page-pad-y) 0;
			}
		}
	}
`);

export type PageProps = {
	id: string;
	/** The hero for the page. */
	hero?: ReactNode;
	/** Head content, placed above the main content & side-bar. */
	head?: ReactNode;
	/** Whether the page's head content is sticky to the top. */
	headSticky?: boolean;
	/** Main content of the page. */
	main: ReactNode;
	/** Content placed into the page's side-bar. */
	side?: ReactNode;
	sideSticky?: boolean;
	/** Content placed below the page's main content & side-bar. */
	tail?: Slice[];
	/** Alternative URIs for the page, used to populate the language select. */
	alternativeUris?: Record<Language, string>;
	/** Yacht carousels to render at foot of page. */
	relatedYachts?: RelatedYachts[];
	/** Footer links for the page; omit to disable the footer. */
	footerLinks?: FooterLinks;

	/** Header links for the page; omit to disable nav links in the header. */
	headerLinks?: HeaderLinks;
};
export const Page = ({ id, hero, head, headSticky, main, side, tail, alternativeUris, relatedYachts, headerLinks, footerLinks }: PageProps) => {
	const [ topEl, topStuck ] = useStickyState<HTMLDivElement>(!!headSticky);
	const pageVisibilityController = usePageVisibilityController();

	return (
		<>
			<PageRoot
				id={PAGE_ID}
				key={id}
				className={clsx({ 'with-side': !!side })}
				ref={pageVisibilityController.rootRef}
			>
				<PageHeader overHero={!!hero} links={ headerLinks }/>
				{hero}
				<div className="content">
					{head && (
						<div
							ref={topEl}
							className={clsx('head', {
								'head--sticky': headSticky,
								'head--stuck': topStuck,
							})}>
							{head}
						</div>
					)}
					<div className={clsx('main-and-side', {
						'main-and-side--no-pad-top': head && headSticky,
						'main-and-side--with-side': !!side,
					})}>
						<main className={clsx('main-and-side__main', { 'main--no-pad-top': head && headSticky })}>
							<div className="main-and-side__content">
								{main}
							</div>
						</main>
						{side && (
							<div className="main-and-side__side">
								{side}
							</div>
						)}
					</div>
					<div className="tail">
						{ tail && (
							<div className="tail__content">
								<Slices slices={tail} position="tail"/>
							</div>
						)}
					</div>
				</div>
				<RelatedYachtsCarousels initialRelatedYachts={relatedYachts}/>
				{ footerLinks && (
					<Footer
						className="footer"
						ref={pageVisibilityController.footerRef}
						links={footerLinks}
						alternativeUris={alternativeUris}
					/>
				)}
			</PageRoot>
			{ alternativeUris && <FloatingPreferenceControls alternativeUris={alternativeUris}/> }
			<ScrollTopButton/>
		</>
	);
};
Page.displayName = 'Layout';
