import type { ClassNameProp } from '@charterindex/armoury-www';
import type { HeroActionMode } from '../../../../domain';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import Slide from '@mui/material/Slide';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useSubject } from 'ecce-react';
import Link from 'next/link';
import { CILogo } from '../../../components';
import { SearchFormQueryProvider } from '../../../search';
import { usePageVisibilityController } from '../../page-visibility-controller-context';
import { HeroActionEnquire } from './HeroActionEnquire';
import { HeroActionSearch } from './HeroActionSearch';


const CTA_WIDTH = '72ch';

const HeroCtaRoot = styled('div')(() => css`
	width: ${CTA_WIDTH};
	max-width: 95vw;
`);

const HeroCtaSticky = styled(Paper)(({ theme }) => css`
	position: fixed;
	top: 0;
	width: 100%;
	max-width: 100vw;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas: 'logo content';
	gap: ${theme.spacing(1)};
	
	
	.stickyLogo {
		grid-area: 'logo';
		display: flex;
	}

	.stickyLogo {
		display: flex;
		align-items: center;
		margin: auto 0 auto auto;
		padding-left: ${theme.spacing(1)};
		&.hideMobile {
			display: none;
		}
	}

	.stickyContent {
		grid-area: content;
	}

	${theme.breakpoints.up('md')} {
		grid-template-areas: 'logo content .';
		grid-template-columns: 1fr ${CTA_WIDTH} 1fr;

		.stickyLogo {
			padding-left: 0;
			&.hideMobile {
				display: flex;
			}
		}
	}
`);

export type HeroActionContentProps = ClassNameProp & {
	sticky?: boolean;
};
export type HeroActionContentComponent = (props: HeroActionContentProps) => JSX.Element;

const HERO_ACTION_COMPONENTS: Record<HeroActionMode, HeroActionContentComponent> = {
	search: HeroActionSearch,
	enquire: HeroActionEnquire,
};
const HERO_HIDE_MOBILE_LOGO: Record<HeroActionMode, boolean> = {
	search: true,
	enquire: false,
};

export type HeroActionProps = {
	action?: HeroActionMode;
	place?: string;
};
export const HeroAction = ({ action, place }: HeroActionProps) => {
	const pageVisibility = usePageVisibilityController();
	useSubject(pageVisibility, 'heroCtaVisible');
	useSubject(pageVisibility, 'enquiryFormVisible');

	const largeScreen = useMediaQuery(useTheme().breakpoints.up('sm'));

	if(!action) {
		return null;
	}

	const ContentComponent = HERO_ACTION_COMPONENTS[action];
	if(!ContentComponent) {
		return null;
	}

	const hideMobileLogo = HERO_HIDE_MOBILE_LOGO[action];
	const sticky = !pageVisibility.heroCtaVisible && (largeScreen || !pageVisibility.enquiryFormVisible);

	return (
		<SearchFormQueryProvider initialPlace={place}>
			<HeroCtaRoot ref={pageVisibility.heroCtaRef}>
				<ContentComponent/>
			</HeroCtaRoot>
			<Portal>
				<Slide direction="down" in={sticky}>
					<HeroCtaSticky
						elevation={10}
						square
					>
						<Link href="/" passHref legacyBehavior>
							<a className={clsx('stickyLogo', { 'hideMobile': hideMobileLogo })}>
								<CILogo iconOnly mode="primary"/>
							</a>
						</Link>
						<ContentComponent className="stickyContent" sticky/>
					</HeroCtaSticky>
				</Slide>
			</Portal>
		</SearchFormQueryProvider>
	);
};
