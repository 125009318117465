import Cookies from 'universal-cookie';

/** Name of the cookie set to persist a user's language preference. */
export const CURRENCY_COOKIE = 'currency';
export const LANGUAGE_HEADER = 'x-language';
export const CURRENCY_HEADER = 'x-currency';

export const parseCookie = (cookies: string, cookieName: string): string | undefined => (
	new Cookies(cookies).get(cookieName)
);

/**
 * URI encode each segment of `path` individually.
 *
 * @example
 * 	'/ja/クッキーポリシー' -> '/ja/%E3%82%AF%E3%83%83%E3%82%AD%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC'
 */
export const encodeUriPathSegments = (path: string): string => (
	path.split('/').map(encodeURIComponent).join('/')
);
