import type { ClassNameProp } from '../../util';
import type { SuggestionResult } from '../../../domain';
import { css, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { useSuggest } from '../suggest-context';
import { SuggestResult } from './SuggestResult';


/*
	https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/listbox_role
*/

const SuggestResultListRoot = styled('ul')(() => css`
	list-style: none;
	padding: 0;
	margin: 0;
`);

const SuggestLoading = styled(CircularProgress)(() => css`
	margin: 200px auto;
`);

const optionId = (result: SuggestionResult | null): string | undefined => {
	if(!result) {
		return undefined;
	}

	return result.type + result.id;
};

export type SuggestResultListProps = ClassNameProp;
export const SuggestResultList = ({ className }: SuggestResultListProps) => {
	const { t } = useTranslation();
	const { suggestState, selectedResult: selectedSuggestion, suggestQuery } = useSuggest();

	if(suggestState.loading) {
		return <SuggestLoading/>;
	}

	if(suggestState.default) {
		return null;
	}

	if(suggestState.results.length === 0) {
		return (
			<Typography component="span" paddingY="2rem" textAlign="center">
				{ t('common:no-search-results', { query: suggestQuery }) }
			</Typography>
		);
	}

	return (
		<SuggestResultListRoot
			className={className}
			role="listbox"
			aria-activedescendant={optionId(selectedSuggestion.suggestion)}
		>
			{ suggestState.results.map(result => {
				const id = optionId(result);
				return (
					<li
						id={id}
						key={id}
						role="option"
						aria-selected={selectedSuggestion.suggestion === result}
					>
						<SuggestResult suggestion={result} />
					</li>
				);
			})}
		</SuggestResultListRoot>
	);
};
