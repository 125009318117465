import type { FAQSlice, TemplatedFAQSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import { asLanguage } from '../../domain';
import { makeCurrencyFormatter } from '../preferences';
import { notNullish } from '../../util';
import { Slice_Faq } from './Slice_Faq';


const PLACE_QUESTION_KEY = 'yacht-faq.position.question';

export const Slice_TemplatedFaq = ({ slice: { id, faqs, model, titleKey }, position }: SliceProps<TemplatedFAQSlice>) => {
	const { t, lang } = useTranslation();

	// Convert to a regular FaqSlice.
	const faqSlice = useMemo<FAQSlice>(() => {
		const computedModel: Record<string, string | number> = (() => {
			const computedModel: Record<string, string | number> = { ...model };

			if(model.priceDay && model.priceWeek && model.currency) {
				const currencyFmt = makeCurrencyFormatter(asLanguage(lang), model.currency);
				computedModel.priceWeekFormatted = currencyFmt(model.priceWeek);
				computedModel.priceDayFormatted = currencyFmt(model.priceDay);
			}

			return computedModel;
		})();

		const isPlaceStale = !!model.positionTime && differenceInDays(new Date(model.positionTime), new Date()) < -7;

		return {
			id,
			title: t('common:' + titleKey),
			contentType: 'FAQS',
			faqs: faqs
				.map(templatedFaq => {
					if(isPlaceStale && templatedFaq.questionKey === PLACE_QUESTION_KEY) {
						return null;
					}

					const faq = {
						title: t('common:' + templatedFaq.questionKey, computedModel),
						markdown: t('common:' + templatedFaq.answerKey, computedModel),
					};

					return faq;
				})
				.filter(notNullish),
		};

	}, [faqs, id, lang, model, t, titleKey]);


	return <Slice_Faq slice={faqSlice} position={position}/>;
};
