import type { ClassNameProp } from '../../../util';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { toFeet } from '../../../../util';
import { useYacht } from '../../yacht-context';
import { YachtPremiumListing } from '../YachtPremiumListing';


const YachtBasicInfo = styled('div')(({ theme }) => css`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	
	.premium-listing {
		display: none;
		margin-top: .1em;
		margin-right: ${theme.spacing(2)};

		${theme.breakpoints.up('sm')} {
			display: unset;
		}
	}
`);

export const YachtNameAndBasicInfo = ({ className }: ClassNameProp) => {
	const { t } = useTranslation();
	const yacht = useYacht();

	return (
		<div className={className}>
			<Typography component="h2" variant="h1">
				{ yacht.name }
			</Typography>
			<YachtBasicInfo>
				<YachtPremiumListing className="premium-listing" yacht={yacht}/>
				<Typography component="h3" variant="body2">
					{yacht.yearLaunch}&nbsp;{yacht.builder.name}
					&nbsp;&nbsp;
					{t('common:value.ft', { value: toFeet(yacht.lengthMeters) })}
					&nbsp;/&nbsp;
					{t('common:value.m', { value: yacht.lengthMeters })}
				</Typography>
			</YachtBasicInfo>
		</div>
	);
};
