import type { FC } from 'react';
import type { Cookie, CookieGetOptions, CookieSetOptions } from 'universal-cookie';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import { Analytics, setGtmConsent } from '../analytics';


export type CookieConsentStatus = 'unchosen' | 'accepted' | 'rejected';
export type CookieContext = {
	cookieConsent: CookieConsentStatus;
	setCookieConsent: (newConsent: CookieConsentStatus) => void;
	getCookie: Cookies['get'];
	setCookie: Cookies['set'];
	setEssentialCookie: Cookie['set'];
	removeCookie: Cookie['remove'];
};
const cookieContext = createContext<CookieContext | null>(null);

const getConsentCookieStatusFromCookie = (cookie: string): CookieConsentStatus => {
	switch(cookie) {
		case 'unchosen':
		case 'accepted':
		case 'rejected':
			return cookie;
		default:
			return 'unchosen';
	}
};

const hasConsented = (cookieConsent: CookieConsentStatus): boolean => (
	cookieConsent === 'accepted'
);

const getCookie = (cookie?: string): string => {
	if(cookie) {
		return cookie;
	}
	if(typeof(window) !== 'undefined') {
		return document.cookie;
	}

	return '';
};

export const COOKIE_MAX_AGE = 31536000;

export type CookieProviderProps = { cookie?: string };
export const CookieProvider: FC<CookieProviderProps> = ({ cookie, children }) => {
	const cookies = useMemo(() => new Cookies(getCookie(cookie)), [cookie]);
	const [ cookieConsent, setCookieConsent ] = useState(getConsentCookieStatusFromCookie(cookies.get('consent')));

	useEffect(() => {
		setGtmConsent(hasConsented(cookieConsent));
	}, [ cookieConsent ]);

	const value = useMemo<CookieContext>(() => ({
		cookieConsent,
		setCookieConsent: consent => {
			Analytics.interaction({ target: 'Cookie Consent', action: 'select', value: consent });

			cookies.set('consent', consent, {
				path: '/',
				maxAge: COOKIE_MAX_AGE,
			});
			setCookieConsent(consent);
		},
		getCookie: (name: string, options?: CookieGetOptions) => {
			return cookies.get(name, options);
		},
		setCookie: (name: string, value: Cookie, options?: CookieSetOptions) => {
			if(!hasConsented(cookieConsent)) {
				return;
			}
			cookies.set(name, value, options);
		},
		setEssentialCookie: (name: string, value: Cookie, options?: CookieSetOptions) => {
			cookies.set(name, value, options ?? {
				path: '/',
				maxAge: COOKIE_MAX_AGE,
			});
		},
		removeCookie: cookies.remove,
	}), [cookieConsent, cookies]);

	return (
		<cookieContext.Provider value={value}>
			{children}
		</cookieContext.Provider>
	);
};

export const useCookie = (): CookieContext => {
	const ctx = useContext(cookieContext);
	if(!ctx) {
		throw new Error('cookieContext was null. Missing <CookieProvider>?');
	}

	return ctx;
};
