import type { InquiryFormSlice } from '../../domain';
import type { EnquiryType } from '../enquiry/enquiry-controller';
import type { SliceProps } from './SliceProps';
import Stack from '@mui/material/Stack';
import { EnquiryView } from '../enquiry';
import { useMarkdown } from './use-markdown';


type EnquirySliceContentProps = SliceProps<InquiryFormSlice> & {
	type: EnquiryType;
};
const EnquirySliceContent = ({ type, position, slice: { id, markdown } }: EnquirySliceContentProps) => {
	const content = useMarkdown(markdown);

	return (
		<Stack component="section" gap={2}>
			{ content && (
				<div>
					{ content }
				</div>
			)}
			<EnquiryView id={id} wide={position !== 'side'} type={type}/>
		</Stack>
	);
};

export const Slice_InquiryForm = (props: SliceProps<InquiryFormSlice>) => (
	<EnquirySliceContent {...props} type="full"/>
);

export const Slice_InquiryFormLite = (props: SliceProps<InquiryFormSlice>) => (
	<EnquirySliceContent {...props} type="lite"/>
);
