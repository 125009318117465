import type { HeaderNavProps } from './HeaderNav';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useRef, useState } from 'react';
import { InternalLink, TextOverImage } from '../../../components';
import { NavMenuItem } from './NavMenuItem';
import { AGENT_LINKS } from './agent-links';


const HeaderNavInlineRoot = styled('nav')(({ theme }) => css`
	grid-auto-flow: column;
	gap: ${theme.spacing(4)};

	display: none;
	${theme.breakpoints.up('sm')} {
		display: grid;
	}

	* {
		color: white;
	}

	.MuiButtonBase-root {
		padding: ${theme.spacing(2, 0)};
	}
`);

export const HeaderNavInline = ({ className, links, overHero }: HeaderNavProps) => {
	const { t } = useTranslation();
	const [ agentOpen, setAgentOpen ] = useState(false);


	const agentButtonRef = useRef<HTMLButtonElement>(null);

	const agentMenuId = 'agentMenuLarge';

	const TextComponent = overHero ? TextOverImage : Typography;

	return (
		<>
			<HeaderNavInlineRoot className={className}>
				{links.map(({ title, uri }) => (
					<ButtonBase LinkComponent={InternalLink} href={uri} key={uri}>
						<TextComponent fontWeight="bold">
							{ title }
						</TextComponent>
					</ButtonBase>
				))}
				<ButtonBase
					ref={agentButtonRef}
					focusRipple
					onClick={() => setAgentOpen(!agentOpen)}
					aria-controls={agentMenuId}
					aria-haspopup={true}
					aria-expanded={agentOpen ? 'true' : undefined}
				>
					<TextComponent fontWeight="bold">
						{ t('common:agent') }
					</TextComponent>
				</ButtonBase>
			</HeaderNavInlineRoot>

			<Menu
				open={agentOpen}
				onClose={() => setAgentOpen(false)}
				anchorEl={agentButtonRef.current}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				{ AGENT_LINKS.map(({ href, title }) => (
					<NavMenuItem key={href}>
						<ButtonBase
							focusRipple
							href={ href }
						>
							{t(title)}
						</ButtonBase>
					</NavMenuItem>
				)) }
			</Menu>
		</>
	);
};
