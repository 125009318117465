export const jsonLdQuantitativeValue = (value: number | undefined, unitCode: string): Record<string, unknown> | undefined => {
	if(value === undefined) {
		return undefined;
	}

	return {
		'@type': 'QuantitativeValue',
		value,
		unitCode,
	};
};


export const jsonLdOrganization = (name: string | undefined): Record<string, unknown> | undefined => {
	if(!name) {
		return undefined;
	}

	return {
		'@type': 'Organization',
		name,
	};
};
