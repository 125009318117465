import type { FC } from 'react';
import type { Article } from '../../domain';
import type { StaticArticleName } from '../../util/static-article';
import { useEffect, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useApi } from '../api';
import { Slices } from '../slices';


const StaticArticleModalRoot = styled(Modal)(({ theme }) => css`
	display: flex;

	padding: ${theme.spacing(2)};
	
	.static-article-modal {
		width: 720px;
		height: 100%;
		width: 100%;

		max-width: 720px;
		max-height: 720px;

		display: grid;
		grid-template-areas: 'title' 'body' 'actions';
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;

		margin: auto;

		& > * {
			padding: ${theme.spacing(2)};
		}

		&__title {
			grid-area: title;
			margin: auto;
		}

		&__body {
			grid-area: body;
			border: 1px solid ${theme.palette.grey[300]};
			overflow-y: auto;
			max-height: 100%;
		}

		&__actions {
			grid-area: actions;

			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: space-between;
			
			padding: ${theme.spacing(1, 2)};

			> * + * {
				margin-top: ${theme.spacing(1)};
			}

			button {
				flex-grow: 1;
			}

			${theme.breakpoints.up('sm')} {
				flex-direction: row;
				justify-content: flex-end;
				
				> * + * {
					margin-top: 0;
					margin-left: ${theme.spacing(1)};
				}

				button {
					flex-grow: 0;
				}
			}
		}
	}
`);

export type StaticArticleModalProps = {
	articleName: StaticArticleName;
	open: boolean;
	onClose: () => void;
};
export const StaticArticleModal: FC<StaticArticleModalProps> = ({ articleName, open, onClose, children }) => {
	const api = useApi();
	const [ article, setArticle ] = useState<Article | null>(null);

	useEffect(() => {
		if(!open) {
			return;
		}

		api.staticArticle(articleName)
			.then(setArticle);
	}, [api, articleName, open]);

	if(!open) {
		return null;
	}

	return (
		<StaticArticleModalRoot open onClose={onClose}>
			<Paper className="static-article-modal">
				{ article && (
					<>
						<Typography
							className="static-article-modal__title"
							component="h1"
							variant="h2"
							textAlign="center"
						>
							{ article.title }
						</Typography>
						<div className="static-article-modal__body">
							<Slices slices={article.slices} noInitialLetter position="main"/>
						</div>
						<div className="static-article-modal__actions">
							{ children }
						</div>
					</>
				)}
			</Paper>
		</StaticArticleModalRoot>
	);
};
