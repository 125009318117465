import type { YachtSearchFilter } from '../../../../domain';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo } from 'react';
import { useYachtSearchFilter } from '../..';
import { Category, Hull } from '../../../../domain';
import { CIButtonGroup } from '../../../components';


type EnumerationFilterKeys = keyof Pick<YachtSearchFilter, 'category' | 'hull'>;
type EnumerationFilterProps = {
	filterKey: EnumerationFilterKeys;
	options: string[];
	labels: Record<string, string>;
};

const ALL_VALUE = 'ALL';

const EnumerationFilter = ({ filterKey, options, labels }: EnumerationFilterProps) => {
	const { t } = useTranslation();
	const { filter, updateFilter } = useYachtSearchFilter();

	const allOptions = useMemo(() => [ ALL_VALUE, ...options ], [ options ]);

	const getOptionsLabel = useCallback((key: string) => t(labels[key]), [labels, t]);

	const handleChange = useCallback((newValue: string) => {
		updateFilter({ [filterKey]: newValue === ALL_VALUE ? undefined : newValue });
	}, [filterKey, updateFilter]);

	const value = useMemo(() => filter[filterKey] ?? ALL_VALUE, [filter, filterKey]);

	return (
		<CIButtonGroup
			options={allOptions}
			value={value}
			getOptionLabel={getOptionsLabel}
			onChange={handleChange}
			fullWidth
			evenSpacing
		/>
	);
};

const CATEGORY_LABELS = {
	ALL: 'common:all-types',
	SAIL: 'common:sailing',
	POWER: 'common:motor',
};
export const CategoryFilter = () => <EnumerationFilter filterKey="category" options={Category.ids} labels={CATEGORY_LABELS}/>;

const HULL_LABELS = {
	ALL: 'common:all-hulls',
	MONOHULL: 'common:monohull',
	MULTIHULL: 'common:multihull',
};
export const HullFilter = () => <EnumerationFilter filterKey="hull" options={Hull.ids} labels={HULL_LABELS}/>;
