import type { AccordionProps } from '@mui/material/Accordion';
import type { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import type { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Analytics } from '../../../analytics';
import { YachtSearchTotalCount } from '../YachtSearchTotalCount';
import { CategoryFilter, HullFilter } from './EnumerationFilter';
import { GuestsFilter, LengthFilter, PriceFilter } from './NumberRangeFilter';
import { ResetFilterButton } from './ResetFilterButton';
import { StaticDateRangeFilter } from './StaticDateRangeFilter';


const FilterSwipeableDrawer = styled(Drawer)(({ theme }) => css`
	> .MuiPaper-root {
		width: 470px;
		max-width: 100vw;
	}

	.filter-drawer {
		display: grid;
		grid-template-areas: 'header' 'filters' 'footer';
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		height: 100%;

		& > * {
			padding: ${theme.spacing(2, 0)};
		}
		
		&__header {
			grid-area: header;
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			padding: ${theme.spacing(.5, 2)};
			box-shadow: ${theme.shadows[1]};
			z-index: 1;
		}
		
		&__filters {
			grid-area: filters;
			overflow: hidden auto;
			padding-top: 0;
		}

		&__footer {
			grid-area: footer;
			margin-bottom: auto;
			display: grid;
			box-shadow: inset ${theme.shadows[1]};
			padding: ${theme.spacing(2)};
		}

		.MuiAccordionSummary-root {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}

		.MuiAccordionDetails-root {
			padding-top: ${theme.spacing(1.5)} !important;

			display: flex;
			flex-direction: column;

			> * + * {
				margin-top: ${theme.spacing(3)};
			}
		}
	}
`);

const FilterAccordion = styled((props: AccordionProps) => <Accordion elevation={0} square {...props}/>)(({ theme }) => css`
	padding: ${theme.spacing(2, 0)};
	margin: 0 !important;

	& + * {
		border-top: 1px solid ${theme.palette.grey[300]};
	}
	
	.ci-slider {
		/*
			Account for slider handles overflowing the accordion's width, which causes
			ugly clipping during the expanding animation.
		*/
		margin: 0 ${theme.spacing(2)};
	}
	
	.ci-button-group, .ci-date-range-picker__input-wrapper {
		margin-top: ${theme.spacing(1)};
	}
`);

const FilterAccordionSummary = (props: AccordionSummaryProps) => <AccordionSummary expandIcon={<ExpandIcon/>} {...props}/>;


export const FILTER_DRAWER_HASH = 'filter';

type FilterSection = {
	id?: string;
	title: string;
	filter: ReactNode;
};

export type YachtSearchFilterDrawerProps = {
	open: boolean;
};
export const YachtSearchFilterDrawer = () => {
	const { t } = useTranslation();
	const [ open, setOpen ] = useState(false);

	const updateOpen = useCallback((newOpen: boolean) => {
		setOpen(prevOpen => {
			if(newOpen !== prevOpen) {
				Analytics.interaction({ target: 'Search Filters Drawer', action: 'close' });
			}
			return newOpen;
		});
	}, []);

	const handleClose = useCallback(() => {
		window.location.hash = '';
	}, []);

	// Open filter draw when appropriate hash appears in the URL.
	useEffect(() => {
		const handleHashChange = () => {
			updateOpen(window.location.hash.includes(FILTER_DRAWER_HASH));
		};
		handleHashChange();

		window.addEventListener('hashchange', handleHashChange);
		return () => { window.removeEventListener('hashchange', handleHashChange); };
	}, [updateOpen]);


	const filters = useMemo<FilterSection[]>(() => [
		{ title: 'common:dates', filter: <StaticDateRangeFilter/> },
		{ id: 'guestsFilter', title: 'common:guests', filter: <GuestsFilter labelId='guestsFilter'/> },
		{ title: 'common:type-and-hull', filter: (
			<>
				<CategoryFilter/>
				<HullFilter/>
			</>
		) },
		{ id: 'priceFilter', title: 'common:price', filter: <PriceFilter labelId='priceFilter'/> },
		{ id: 'lengthFilter', title: 'common:length', filter: <LengthFilter labelId="lengthFilter"/> },
	], []);

	return (
		<FilterSwipeableDrawer
			open={open}
			onClose={handleClose}
			anchor="right"
		>
			<div className="filter-drawer">
				<header className="filter-drawer__header">
					<ResetFilterButton/>
					<ButtonBase
						onClick={handleClose}
						title={t('common:close')}
					>
						<CloseIcon/>
					</ButtonBase>
				</header>
				<div className="filter-drawer__filters">
					{ filters.map(({ id, title, filter }) => (
						<FilterAccordion key={title} defaultExpanded>
							<FilterAccordionSummary>
								<Typography id={id} component="span">{ t(title) }</Typography>
							</FilterAccordionSummary>
							<AccordionDetails>
								{ filter }
							</AccordionDetails>
						</FilterAccordion>
					))}
				</div>

				<footer className="filter-drawer__footer">
					<Button
						variant="contained"
						color="primary"
						size="large"
						href="#"
					>
						<YachtSearchTotalCount/>
					</Button>
				</footer>
			</div>

		</FilterSwipeableDrawer>
	);
};
