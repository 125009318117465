import { Close as CloseIcon } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import StaticRouter from 'next/router';
import { useEffect, useState } from 'react';
import { EnquiryController } from '../enquiry-controller';
import { useEnquiryController } from '../enquiry-controller-context';
import { EnquiryView } from './EnquiryView';


const EnquiryDialogRoot = styled(Dialog)(({ theme }) =>  css`

	.MuiDialog-paper {
		max-width: 100%;
		max-height: 100%;
		margin: 0;
	}

	.title {
		display: flex;
		justify-content: space-between;
		gap: ${theme.spacing(2)};
		padding: ${theme.spacing(1, 1, 0, 2)};
		${theme.breakpoints.up('sm')} {
			padding-block-end: ${theme.spacing(1)};
		}
	}

	.content {
		padding: ${theme.spacing(2)};
		padding-block-start: ${theme.spacing(1)};
	}

	&.complete {
		.MuiDialog-paper {
			padding: 0;
			width: 50ch;
		}
	}
`);

export const EnquiryDialog = () => {
	const controller = useEnquiryController();
	useSubject(controller, 'result');
	const complete = controller.result?.ok;

	const [ open, setOpen ] = useState(false);

	const handleClose = () => StaticRouter.back();

	useEffect(() => {
		const handleHashChange = (path: string) => {
			const hash = path?.split('#')[1];
			if(hash !== EnquiryController.FORM_ID) {
				setOpen(false);
				return;
			}

			/*
				If there is already an enquiry form embedded in the page, we don't need
				to show the modal as the browser will scroll to it.
			*/
			const formEl = document.getElementById(EnquiryController.FORM_ID);
			if(formEl) {
				return;
			}

			setOpen(true);
		};

		StaticRouter.events.on('hashChangeComplete', handleHashChange);
		return () => StaticRouter.events.off('hashChangeComplete', handleHashChange);
	}, []);

	const { t } = useTranslation();

	return (
		<EnquiryDialogRoot
			className={complete ? 'complete' : undefined}
			open={open}
			onClose={handleClose}
		>
			<Stack
				className="title"
				component="header"
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				gap={1}
			>
				<Typography variant="h2" margin={0}>
					{ t(controller.labelKey) }
				</Typography>
				<IconButton onClick={handleClose}>
					<CloseIcon/>
				</IconButton>
			</Stack>
			<div className="content">
				<EnquiryView
					id="enquiryDialog"
					wide
					type="full"
					completeAction={(
						<Button
							startIcon={<DoneIcon/>}
							onClick={handleClose}
						>
							{ t('common:close') }
						</Button>
					)}
				/>
			</div>
		</EnquiryDialogRoot>
	);
};
